/*
* --------------------------------------------------
* This file contains all styles for checkbox component.
* --------------------------------------------------
*/
/*doc
  name: Checkbox
  description: Different checkboxes except for check element
  code: |
    <label class="checkbox">
      <input class="checkbox__control" type="checkbox">
      <span class="checkbox__text">Checkbox label</span>
    </label>
    <br />
    <label class="checkbox checkbox_type_switch">
      <input class="checkbox__control" type="checkbox">
      <span class="checkbox__text" data-unchecked="not checked" data-checked="checked">
        Checkbox switch
      </span>
      <span class="checkbox__switch"></span>
    </label>
*/

.checkbox {
  position: relative;
  display: inline-block;
  text-align: left;

  cursor: pointer;

  &__control {
    position: relative;

    display: inline-block;
  }

  &__text {
  }

  &__control:checked ~ &__text_hide-oncheck,
  &_checked &__text_hide-oncheck {
    visibility: hidden;
  }

  &__text_show-oncheck {
    visibility: hidden;
  }

  &__control:checked ~ &__text_show-oncheck,
  &_checked &__text_show-oncheck {
    visibility: visible;
  }

  &_type_switch {
    vertical-align: middle;

    & .checkbox__control {
      position: absolute;
      z-index: -1;
      visibility: hidden;
      opacity: 0;
    }

    & .checkbox__text:before {
      content: attr(data-unchecked);
    }

    & .checkbox__control:checked ~ .checkbox__text:before {
      content: attr(data-checked);
    }

    & .checkbox__switch {
      position: relative;

      display: block;
      width: 2.625rem;
      height: 1.125rem;

      border: 1px solid var(--color-disabled);
      border-radius: var(--border-radius-tag);

      user-select: none;

      transition: all 0.1s ease-in-out;

      &:after {
        position: absolute;
        top: 1px;
        left: 1px;

        display: block;
        width: 1.25rem;
        height: calc( 1.125rem - 4px );

        content: '';

        background: var(--color-disabled);
        border-radius: calc( var(--border-radius-tag) - 2px );

        transition: all 0.1s ease-in-out;
      }
    }

    & .checkbox__control:checked ~ .checkbox__switch {
      border-color: var(--brand-highlight);

      &:after {
        left: calc( 100% - 1.25rem - 1px );

        background: var(--brand-highlight);
      }
    }
  }

  &_type_button {
    & .checkbox__control {
      position: absolute;
      z-index: -1;
      visibility: hidden;
      margin: 0;
      opacity: 0;
    }

    & .checkbox__text {
      display: inline-block;
    }
  }
}

.rounded-checkbox {
  margin-left: 0.3125rem;
  position: relative;
  padding-left: 1.25rem;
  display: inline-block;

  & input[type=checkbox] {
    opacity: 0;
    position: absolute;
    width: 1.25rem;
    height: 1.25rem;
    padding: 0;
    border: 0;
    left: 0;
    overflow: hidden;
    clip: rect(0 0 0 0);
  }

  &__img {
    display: inline;
    cursor: pointer;
    position: absolute;
    left: 0;
    border: 0.125rem solid var(--color-disabled);
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    background: #fff;
  }

  & input[type=checkbox]:disabled + i {
    cursor: auto;
  }

  &__img:after {
    content: ' ';
    position: absolute;
    width: 0.5625rem;
    height: 0.3125rem;
    background: transparent;
    top: .32rem;
    left: .25rem;
    border: 0.125rem solid #fff;
    border-top: none;
    border-right: none;
    transform: rotate(-45deg);
    opacity: 0;
  }

  & input[type=checkbox]:checked + i {
    border: 1px solid var(--brand-highlight);
    background-color: var(--brand-highlight);
  }

  & input[type=checkbox]:checked + i:after {
    opacity: 1;
    border-color: var(--brand-highlight-sec);
  }

  &_sec {
    margin: 0;
    padding: 0;
    width: 22px;
    height: 22px;

    .rounded-checkbox__img {
      width: 22px;
      height: 22px;
      border-color: var(--brand-highlight);
      background-color: transparent;
    }

    .rounded-checkbox__img:after {
      border-color: var(--brand-highlight-sec);
      width: 13px;
      height: 6px;
      top: 5px;
      left: 3px;
    }

    input[type=checkbox] {
      position: static;
      width: 22px;
      height: 22px;
    }

    input[type=checkbox]:checked + i {
      background-color: transparent;
      border: 2px solid var(--brand-highlight-sec);
    }
  }

  &_disabled {
    cursor: default;
    color: #ccc;
    user-select: none;

    .rounded-checkbox__img {
      cursor: default;
      opacity: .2;
    }
  }
}

.ssr-active {
  & .rounded-checkbox {
    &__img {
      border: 0.125rem solid var(--brand-highlight);
      opacity: 0.5;
    }

    &__img:after {
      border: 0.125rem solid var(--brand-highlight);
      border-top: none;
      border-right: none;
      opacity: 1;
    }

    & input[type=checkbox]:checked + i:after {
      opacity: 0.5;
      border-color: #fff;
    }
  }
}
