.timepicker-inputs {
  display: flex;
  gap: 10px;
  align-items: center;
}

.timepicker-field {
  display: flex;
  align-items: center;
  gap: 5px;
}

.timepicker-input {
  width: 50px;
  font-size: 16px;
  text-align: center;
}

.button-group {
  display: flex;
  flex-direction: column;
}

.timepicker-button {
  border: none;
  width: 15px;
  height: 15px;
  font-size: 14px;
  cursor: pointer;
  padding: 0;
  line-height: 1;
  text-align: center;
  background-color: #fff;
}

.actions {
  margin-top: 10px;
  display: flex;
  justify-content: end;
}

.timepicker-container .submit-btn {
  padding-left: 1rem;
  padding-right: 1rem;
}
