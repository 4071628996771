.password__eye {
  position: absolute;
  z-index: var(--z-main-content);
  top: 1.65rem;
  right: 0.5rem;
  display: block;
  width: 1.50rem;
  height: 1.50rem;
  margin-top: -1.05rem;
  background: url(tais-styles/svg/schedchanges/eye.svg) 50% 50% / 24px 24px no-repeat;
  cursor: pointer;
  opacity: 0.3;
}

.password__eye:hover {
  opacity: 1;
}

.password__eye_active {
  background-image: url(tais-styles/svg/schedchanges/eye_red.svg);
  opacity: 0.6;
}

.password__eye_active:hover {
  opacity: 1;
}
