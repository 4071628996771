.tourists-page-filter {
  position: absolute;
  right: 5px;
  top: 35px;
  background: white;
  padding: 16px;
  border: 1px solid #666;
  border-radius: 4px;
  z-index: var(--z-popup);
  box-shadow: 1px 1px 1px #666;

  &__field {
    text-align: left;
    margin-bottom: 10px;
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  & select {
    cursor: pointer;
  }

  &__header {
    margin: 0 0 20px;
    font-size: 16px;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
  }

  &__definition {
    font-style: normal;
    text-transform: uppercase;
    white-space: nowrap;
    min-width: 220px;
    font-size: 14px;
    font-weight: bold;
    color: #666;
  }

  &__input {
    min-width: calc(50% - 10px);
    padding: 6px 4px;
    border: solid #666;
    border-width: 0 0 1px;
    font-size: 16px;

    &:focus {
      outline: none;
      background-color: rgba(76, 165, 255, .1);
    }
  }

  &__cls {
    text-decoration: none;
    position: absolute;
    top: 4px;
    right: 5px;
    cursor: pointer;
    font-size: 12px;
    line-height: 12px;
    font-weight: bold;
    text-transform: uppercase;
    width: 20px;
    height: 20px;
    border-radius:  50%;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-family: var(--font-family-monospace);
    transition: background-color .1s, color .1s;

    &:hover {
      background-color: #666;
      color: #fff;
    }
  }
}
