.section-rebookrules {
  > .screen-sect__details {
    padding-right: 0;
    padding-left: 0;
  }

  .select__box,
  .btn-link {
    margin-right: 10px;
    line-height: 18px;
    font-size: 11px;
  }

  .btn-link:last-of-type {
    margin-right: 0;
  }

  .rebookrules__select {
    margin-bottom: 12px;
    padding: 0 10px;
  }

  .prt-rule:first-child {
    margin-top: 0;
  }

  .prt-rule:last-child {
    .prt-rule__controls {
      padding-bottom: 16px;
    }
  }

  .prt-rule {
    margin: -12px 0 0;
  }

  .prt-rule__controls {
    padding: 0 16px 30px;
  }
}

.rebookrules__select .rebookrules_hidden {
  display: none;
}

.rebookrules {
  &__ro {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &_hidden {
    display: none;
  }

  &__customize {
    margin-left: 20px;
    align-self: center;

    .section-rebookrules & {
      margin-left: 0;
    }
  }

  &__need-both-points-warning {
    color: orange;
    font-weight: bold;
  }

  &__mandatory-comment-warning {
    color: red;
    font-weight: bold;
    text-transform: uppercase;
    position: absolute;
    top: 7px;
    left: 0;
    font-size: 10px;
  }
}

.reaccomodation-rules {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  &__head {
    margin: 10px 0;
    font-size: 16px;
  }
}

.screen-sect__title .reaccomodation-rule {
  width: 100%;
  margin: 0;
}

.reaccomodation-rule {
  padding: 0 6px 6px;
  display: flex;
  align-items: center;
  flex-grow: 1;

  &__moment {
    text-transform: uppercase;
    white-space: nowrap;
  }

  &__order {
    font-size: 16px;
    font-weight: bold;
    margin-right: 10px;
  }

  &__predicat {
    min-width: 30%;
    max-width: 30%;
    flex-shrink: 2;

    &-ro-head {
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  &__operation {
    white-space: normal;
    max-width: 78%;
  }

  &__operation-name {
    font-weight: bold;
    text-transform: uppercase;
  }

  &__with-docking {
    font-weight: bold;
    text-transform: uppercase;
  }

  &__for-agent-info {
    text-transform: uppercase;
    font-family: var(--font-family-sans-serif);
  }
}
