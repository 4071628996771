/*
* --------------------------------------------------
* This file contains all Custom Media Queries.
* W3C CSS Custom Media Queries
* https://drafts.csswg.org/mediaqueries/#custom-mq
* --------------------------------------------------
* Usage @media (--viewport-xs)
*/

@custom-media --vp-max-lg (max-width: 90rem); /* 1440px */
@custom-media --vp-max-xl (max-width: 100rem); /* 1600px */
@custom-media --vp-min-xxs (min-width: 48rem); /* 768px */
@custom-media --vp-min-xs (min-width: 60rem); /* 960px */
@custom-media --vp-min-m (min-width: 64rem); /* 1024px */
@custom-media --vp-min-md (min-width: 80rem); /* 1280px */
@custom-media --vp-minheight-xs (min-height: 22.5rem); /* 360px */
