/*
data-tooltip & data-tooltip-postion, are required attributes to adding tooltips
*/

a[data-tooltip] {
  color: #000;
}

[data-tooltip] {
  position: relative;
  /*z-index: 2;*/
  cursor: pointer;
}

/* Hide the tooltip content by default */
[data-tooltip]:before,
[data-tooltip]:after {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  z-index: 2;
}

/* Position tooltip above the element */
[data-tooltip]:before {
  content: attr(data-tooltip);
  visibility: hidden;
  background-color: #c0c0c0;
  color: #555;
  font-size: 11px;
  line-height: 18px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  border-radius: 4px;
  white-space: nowrap;
  min-width: 55%;
  padding: 4px 12px;
  position: absolute;
  top: -3px;
  left: 105%;
  transition: 0.3s;
}

[data-tooltip-style=light] {
  &::before {
    background-color: #999;
    font-size: 0.875rem;
    text-transform: uppercase;
    color: #fff;
    font-weight: normal;
  }

  &[data-tooltip-position=left]:after {
    border-color: transparent transparent transparent #999;
  }

  &[data-tooltip-position=right]:after {
    border-color: transparent #999 transparent transparent;
  }

  &[data-tooltip-position=top]:after,
  &[data-tooltip-position=top-left]:after,
  &[data-tooltip-position=top-right]:after {
    border-color: #999 transparent transparent transparent;
  }

  &[data-tooltip-position=bottom]:after,
  &[data-tooltip-position=bottom-left]:after,
  &[data-tooltip-position=bottom-right]:after {
    border-color: transparent transparent #999 transparent;
  }
}

[data-tooltip-text-align=left]:before {
  text-align: left;
}

[data-tooltip-wrap=wrap]:before {
  white-space: normal;
}

[data-tooltip-wrap=pre]:before {
  white-space: pre;
}

[data-tooltip-wrap=nowrap]:before {
  white-space: nowrap;
}

[data-tooltip-wrap=two-str]:before {
  content: attr(data-tooltip) "\00000a" attr(data-tooltip-2);
  white-space: pre;
}

[data-tooltip][disabled]:before,
[data-tooltip][disabled]:after {
  display: none;
}

[data-tooltip]:after {
  content: '';
  position: absolute;
  border-style: solid;
  transition: 0.3s;
}

/* Show tooltip content on hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  opacity: 1;
}

[data-tooltip-position=top]:before {
  top: auto;
  bottom: calc(100% + 6px);
  left: 50%;
  transform: translate(-50%, -0.5rem);
}

[data-tooltip-position=top]:after {
  top: auto;
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, -0.5rem);
  border-width: 6px 4px 0;
  border-color: #c0c0c0 transparent transparent;
}

[data-tooltip-position=bottom]:before {
  top: calc(100% + 5px);
  bottom: auto;
  left: 50%;
  transform: translate(-50%, 0.5rem);
}

[data-tooltip-position=bottom]:hover:before,
[data-tooltip-position=bottom]:hover:after,
[data-tooltip-position=top]:hover:before,
[data-tooltip-position=top]:hover:after {
  transform: translate(-50%, 0);
}

[data-tooltip-position=bottom]:after {
  top: 100%;
  bottom: auto;
  left: 50%;
  transform: translate(-50%, 0.5rem);
  border-width: 0 4px 6px;
  border-color: transparent transparent #c0c0c0;
}

[data-tooltip-position=right]:before {
  left: calc(100% + 6px);
}

[data-tooltip-position=right]:after {
  top: 7px;
  left: 100%;
  border-color: transparent #c0c0c0 transparent transparent;
  border-width: 3px 6px 3px 0;
}

[data-tooltip-position=left]:before {
  top: 50%;
  left: auto;
  right: calc(100% + 6px);
  transform: translateY(-50%);
}

[data-tooltip-word-break=break-word]:before {
  word-break: break-word;
}

[data-tooltip-position=left]:after {
  top: 50%;
  left: auto;
  right: 100%;
  border-color: transparent transparent transparent #c0c0c0;
  border-width: 3px 0 3px 6px;
  transform: translateY(-50%);
}

[data-tooltip-position=top-left]:before {
  left: 0;
  top: auto;
  bottom: calc(100% + 5px);
}

[data-tooltip-position=top-left]:after {
  top: auto;
  left: 50%;
  bottom: 100%;
  border-color: #c0c0c0 transparent transparent;
  border-width: 6px 4px 0;
  transform: translateX(-50%);
}

[data-tooltip-position=top-right]:before {
  left: auto;
  right: 0;
  top: auto;
  bottom: calc(100% + 5px);
}

[data-tooltip-position=top-right]:after {
  top: auto;
  right: 50%;
  left: auto;
  bottom: 100%;
  border-color: #c0c0c0 transparent transparent;
  border-width: 6px 4px 0;
  transform: translateX(50%);
}

[data-tooltip-position=bottom-left]:before {
  left: 0;
  top: calc(100% + 6px);
}

[data-tooltip-position=bottom-left]:after {
  left: 50%;
  top: 100%;
  border-color: transparent transparent #c0c0c0 transparent ;
  border-width: 0 3px 6px;
  transform: translateX(-50%);
}

[data-tooltip-position=bottom-right]:before {
  left: auto;
  right: 0;
  top: calc(100% + 6px);
}

[data-tooltip-position=bottom-right]:after {
  right: 50%;
  top: 100%;
  border-color: transparent transparent #c0c0c0 transparent;
  border-width: 0 3px 6px;
  transform: translateX(50%);
}

[data-tooltip-style=white] {
  &:before {
    background-color: #fff;
  }

  &[data-tooltip-position=left]:after {
    border-color: transparent transparent transparent #fff;
  }

  &[data-tooltip-position=right]:after {
    border-color: transparent #fff transparent transparent;
  }

  &[data-tooltip-position=top]:after,
  &[data-tooltip-position=top-left]:after,
  &[data-tooltip-position=top-right]:after {
    border-color: #fff transparent transparent transparent;
  }

  &[data-tooltip-position=bottom]:after,
  &[data-tooltip-position=bottom-left]:after,
  &[data-tooltip-position=bottom-right]:after {
    border-color: transparent transparent #fff transparent;
  }
}

[data-tooltip-style=dark] {
  &:before {
    background-color: #333;
    color: #fff;
  }

  &[data-tooltip-position=left]:after {
    border-color: transparent transparent transparent #333;
  }

  &[data-tooltip-position=right]:after {
    border-color: transparent #333 transparent transparent;
  }

  &[data-tooltip-position=top]:after,
  &[data-tooltip-position=top-left]:after,
  &[data-tooltip-position=top-right]:after {
    border-color: #333 transparent transparent transparent;
  }

  &[data-tooltip-position=bottom]:after,
  &[data-tooltip-position=bottom-left]:after,
  &[data-tooltip-position=bottom-right]:after {
    border-color: transparent transparent #333 transparent;
  }
}

[data-tooltip-style=twd] {
  &:before {
    padding: 0.5rem;
    border-radius: 0.3125rem;
    background-color: #666666;
    color: #fff;
    font-size: 0.75rem;
    font-weight: normal;
    text-transform: none;
  }

  &[data-tooltip-position=left]:after {
    border-top: 0.625rem solid transparent;
    border-bottom: 0.625rem solid transparent;
    border-left: 0.625rem solid #666666;
  }

  &[data-tooltip-position=right]:after {
    border-top: 0.625rem solid transparent;
    border-right: 0.625rem solid #666666;
    border-bottom: 0.625rem solid transparent;
  }

  &[data-tooltip-position=top]:after,
  &[data-tooltip-position=top-left]:after,
  &[data-tooltip-position=top-right]:after {
    border-top: 0.625rem solid #666666;
    border-right: 0.625rem solid transparent;
    border-left: 0.625rem solid transparent;
  }

  &[data-tooltip-position=bottom]:after,
  &[data-tooltip-position=bottom-left]:after,
  &[data-tooltip-position=bottom-right]:after {
    border-right: 0.625rem solid transparent;
    border-bottom: 0.625rem solid #666666;
    border-left: 0.625rem solid transparent;
  }
}

[data-tooltip-style=grey] {
  &:before {
    background-color: #666;
    color: #fff;
    text-transform: none;
    border-radius: 5px;
    min-height: 36px;
    text-align: center;
    padding: 0 20px;
    display: inline-flex;
    align-items: center;
    font: 14px/16px var(--font-family-sans-serif-arial);
  }

  &[data-tooltip-position=top]:before {
    bottom: calc(100% + 8px);
  }

  &[data-tooltip-position=top]:after {
    bottom: calc(100% + 2px);
    border-width: 7px 9px 0 9px;
    border-color: #666 transparent transparent transparent;
  }

  &[data-tooltip-position=bottom]:before {
    top: calc(100% + 8px);
  }

  &[data-tooltip-position=bottom]:after {
    top: calc(100% + 2px);
    border-width: 0 9px 7px 9px;
    border-color: transparent transparent #666 transparent;
  }
}

[data-tooltip-style=multiLine] {
  &:before {
    padding: 0.5rem;
    border-radius: 0.3125rem;
    background-color: #666666;
    color: #fff;
    font-size: 0.75rem;
    font-weight: normal;
    text-transform: none;
    width: 200px;
    white-space: normal;
    word-break: break-word;
  }

  &[data-tooltip-position=left]:after {
    border-top: 0.625rem solid transparent;
    border-bottom: 0.625rem solid transparent;
    border-left: 0.625rem solid #666666;
  }

  &[data-tooltip-position=right]:after {
    border-top: 0.625rem solid transparent;
    border-right: 0.625rem solid #666666;
    border-bottom: 0.625rem solid transparent;
  }

  &[data-tooltip-position=top]:after,
  &[data-tooltip-position=top-left]:after,
  &[data-tooltip-position=top-right]:after {
    border-top: 0.625rem solid #666666;
    border-right: 0.625rem solid transparent;
    border-left: 0.625rem solid transparent;
  }

  &[data-tooltip-position=bottom]:after,
  &[data-tooltip-position=bottom-left]:after,
  &[data-tooltip-position=bottom-right]:after {
    border-right: 0.625rem solid transparent;
    border-bottom: 0.625rem solid #666666;
    border-left: 0.625rem solid transparent;
  }
}
