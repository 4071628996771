/**
* MAIN MENU
* in the side panel
*/

.main-menu {
  position: relative;

  width: 100%;
  padding-top: 1rem;
  overflow-x: hidden;
  overflow-y: auto;
}

.main-menu__item {
  /* One menu item */
  display: block;
  margin-bottom: 1px;
  padding: 0.625rem 0.875rem;

  font-size: 0.875rem;
  text-align: center;
  color: #cccccc;

  transition: all 0.2s ease;
}

.app-mode-full .main-menu__item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0;
  padding: 0.625rem;
}

.main-menu__item:hover,
.main-menu__item:active,
.main-menu__item_selected {
  color: #ffffff;
  text-decoration: none;

  background: var(--brand-highlight);
}

.main-menu__item-dfn {
  font-style: normal;
  font-size: 0.8125rem;
  text-transform: uppercase;
}

.main-menu__icon {
  /* Menu item icon */
  display: block;
  width: 2.25rem;
  height: 2.25rem;
  margin: 0 auto 0.25em;

  color: inherit;

  &_ta_flights {
    background-color: currentColor;
    mask: url('tais-styles/svg/main-menu/ta_flights.svg') no-repeat center;
  }

  &_ta_tourists {
    background-color: currentColor;
    mask: url('tais-styles/svg/main-menu/ta_tourists.svg') no-repeat center;
  }

  &_mm_profile {
    background-color: currentColor;
    mask: url('tais-styles/svg/main-menu/profile.svg') no-repeat center;
  }

  &_mm_help {
    background-color: currentColor;
    mask: url('tais-styles/svg/main-menu/help.svg') no-repeat center;
  }

  &_mm_docs {
    background-color: currentColor;
    mask: url('tais-styles/svg/main-menu/docs.svg') no-repeat center;
  }
}

.app-mode-full .main-menu__icon {
  display: inline-block;
  width: 1.875rem;
  height: 1.875rem;
  margin: 0 1rem 0 0;
}

.main-menu__item_log,
.main-menu__item_favorites {
  display: none;
}

@media (max-height: 749px) {
  .main-menu__item {
    font-size: 0.75rem;
  }
}
