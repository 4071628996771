/*
* --------------------------------------------------
* This file contains all application-wide variables.
* W3C CSS Custom Properties:
* http://www.w3.org/TR/css-variables/
* ------------------------------------------------
*/
:root {
/*
* Colors
*/

  /* See /src/index.ejs global vars  */

  --brand-primary: #333333;
  --brand-secondary: #666666;

  --brand-warning: #ff0000;
  --brand-error: #d80000;
  --brand-success: #16a803;
  --brand-changed: #007fff;
  --brand-dark: #000000;

  --color-logo: #609cd1;
  --color-lighter: #dddddd;
  --color-disabled: #cccccc;
  --color-light: #999999;
  --color-light-extra: #bbbbbb;
  --color-medium: #545454;
  --color-title-gray: #525252;
  --color-dark: #444444;
  --color-inactive: #f9f9f9;

  --color-orange: #ff9326;
  --color-green: #00b200;
  --color-dblue: #0f56f5;
  --color-lblue: #0082ff;
  --color-violet: #5A009D;

  --bg-dark: #aaaaaa;
  --bg-error: #eed2d2;
  --bg-orange: #ffe9d3;
  --bg-green: #dceeda;
  --bg-tag-business: #d9a300;

  /* Focus-visible style */
  --focus-visible-outline-color: #7fb3f9;

  /* Overlay bg */
  --bg-overlay: rgba(51, 51, 51, 0.4);

  /* Notifications */
  --notify-success-bg: #00d900;
  --notify-warn-bg: #d97300;
  --notify-error-bg: #d90000;

  --color-sm-business-class: #ff9226;
  --color-seat-letter: #ffff00;

  --border-sm-nav: #cdd4d5;
  --border-sm-nav-disabled: #ebf2f3;
  --border-sm-window: #73dcff;

  --bg-seat-price-0: #0080ff;
  --bg-seat-price-1: #7a4dff;
  --bg-seat-price-2: #26c9ff;
  --bg-seat-price-3: #f04dff;
  --bg-seat-price-4: #00dbc9;
  --bg-seat-price-5: #ff3d97;
  --bg-seat-price-6: #00e096;
  --bg-seat-price-7: #ff4d3b;
  --bg-seat-price-8: #99cc00;

  --bg-seat-price-st5: #191970;
  --bg-seat-price-st3: #191970;
  --bg-seat-price-bas: #006400;
  --bg-seat-price-sta: #000080;
  --bg-seat-price-0l8: #000080;
  --bg-seat-price-cmf: #228b22;
  --bg-seat-price-0l9: #228b22;
  --bg-seat-price-stp: #00008b;
  --bg-seat-price-spf: #32cd32;
  --bg-seat-price-psa: #0000cd;
  --bg-seat-price-prs: #0000ff;
  --bg-seat-price-pfs: #4169e1;
  --bg-seat-price-0nk: #1e90ff;
  --bg-seat-price-st7: #7b68ee;
  --bg-seat-price-st6: #9370db;
  --bg-seat-price-pss: #b0c4de;
  --bg-seat-price-st4: #e6e6fa;
  --bg-seat-price-pps: #f0e68c;
  --bg-seat-price-st2: #eee8aa;
  --bg-seat-price-ob5: #fffacd;
  --bg-seat-price-st1: #ffffe0;
  --bg-seat-price-frf: #f0ffff;
  --clr-seat-price-frf: #b6fbfb;

  /* Font, line-height, and color for body text, headings, and more. */
  --font-family-sans-serif: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  --font-family-sans-serif-arial: 'Arial', 'Helvetica', sans-serif;
  --font-family-monospace: 'Consolas', 'Menlo', 'Monaco', 'Courier New', monospace;
  --font-family-only-monospace: monospace, monospace;

  --font-size-root-s: 8px;
  --font-size-root-m: 12px;
  --font-size-root-l: 16px;

  --font-size-root: 1rem;

  --font-size-base: 0.75rem; /* 12px */

  --font-size-xlg: 1.5rem; /* 24px */
  --font-size-ll: 1.375rem; /* 22px */
  --font-size-lg: 1.25rem; /* 20px */
  --font-size-lg-extra: 1.188rem; /* 19px */
  --font-size-md: 1.125rem; /* 18px */
  --font-size-md-extra: 1.063rem; /* 17px */
  --font-size-m-extra: 0.9375rem; /* 15px */
  --font-size-m: 0.875rem; /* 14px */
  --font-size-s: 0.8125rem; /* 13px */
  --font-size-xs: 0.6875rem; /* 11px */
  --font-size-xxs: 0.625rem; /* 10px */
  --font-size-xxs-extra: 0.5625rem; /* 9px */

  --page-min-width: 80rem;
  --screen-min-width: calc(var(--page-min-width) - var(--side-panel-width));

  --grid-gutter: 0.75rem;

  --border-radius: 5px;
  --border-radius-tag: 6px;
  --border-radius-lg: 9px;
  --border-radius-xl: 12px;

  --z-top: 10000;
  --z-main-loader: 9000;
  --z-tooltip: 9000;
  --z-popup: 8000;
  --z-loader: 7000;
  --z-navigation: 6000;
  --z-overlay: 5000;
  --z-search-history: 4000;
  --z-terminal-header: 500;
  --z-terminal-footer: 500;
  --z-above-content: 10;
  --z-main-content: 0;

  --body-bg-transparent: rgba(221,221,221,0);

  --side-panel-width: 9.375rem;
  --stack-panel-width: calc(1.5 * var(--side-panel-width));

  --stack-panel-toggle-width: 1.125rem;

  /* Buttons */
  --btn-border-width: 0.125rem;
  --btn-border-radius: 0.8375rem;

  /* Flight statuses */
  --status-warning: #ff0000;
  --status-success: #16a803;
  --status-note: #007fff;
  --status-attention: #e59700;

  /* Footer box shadow */
  --footer-box-shadow: 0 -0.125rem 0.625rem 0.625rem var(--color-disabled)
}
