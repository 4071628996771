.ssr {
  &__group {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;

    &-name {
      margin-right: .5em;
    }

    &--failed {
      color: var(--brand-error);
    }
  }

  &__item {
    display: inline-flex;
    align-items: center;
    margin: 0 .25em 0 .125rem;
    border: 1px solid transparent;

    &:hover {
      color: inherit;
    }

    &-name {
      text-transform: uppercase;
      min-width: 54px;
    }

    &--failed {
      color: var(--brand-error);
    }

    .tourist-ssr-ico {
      width: 1.75em;
      height: 1.75em;
      margin-right: .25em;
    }
  }

  &-groups {
    &-more {
      &__switcher {
        cursor: pointer;
        font-size: var(--font-size-xs);
        color: var(--brand-highlight);
      }

      &__details {
        max-height: 0;
        overflow: hidden;
        transition: all .12s ease;
        margin-bottom: .25em;
      }

      &__details_active &__details {
        max-height: 10000px;
        overflow: visible;
      }
    }
  }
}
