/**/
:root {
  --pnr-panel-font-size: 0.875rem;
  --pnr-panel-font-size-small: 0.75rem;

  --pnr-panel-color: #ffffff;
  --pnr-panel-bg-color: #ffbf00;
  --pnr-panel-active-bg-color: #00d900;

  --pnr-panel-height: 2.625rem;
}

/* -------------------------------------------------------------------------- */

.pnr-panel {
  position: absolute;
  display: none;
  flex-direction: row;
  left: 0;
  top: 0;
  right: 0;
  height: var(--pnr-panel-height);

  font-size: var(--pnr-panel-font-size);
  line-height: 1;

  color: var(--pnr-panel-color);
  background-color: var(--pnr-panel-bg-color);

  &__header {
    display: block;
    padding: 0.8125rem 1rem 0 1rem;
  }

  &__toolbar {
    display: block;
    padding: 0.5rem 1rem 0 1rem;
    white-space: nowrap;
  }

}

.pnr-panel--grouped {
  background: var(--pnr-panel-active-bg-color);
}

.pnr-panel__button {
  display: inline-block;
  margin-left: 1rem;
  padding: 0.25rem 1rem;
  border: 2px solid var(--pnr-panel-color);
  border-radius: 1rem;
  font-size: var(--pnr-panel-font-size-small);
  text-transform: uppercase;
  cursor: pointer;
}

/* TODO: flex */
.pnr-panel-open {
  padding-top: var(--pnr-panel-height);

  .pnr-panel {
    display: flex;
   }

  .pnr-panel--grouped {
    display: none;
  }
}

.pnr-panel-grouped-open {
  padding-top: var(--pnr-panel-height);

  .pnr-panel--grouped {
    display: flex;
  }
}

.pnr-panel--active {
  .pnr-panel {
    background: var(--pnr-panel-active-bg-color);
  }
}

.pnr-show-badge,
.pnr-show-grouped-badge {
  .terminal__menu__toggle:after {
    content: ' ';
    position: absolute;
    border: 1px solid white;
    border-radius: 0.4rem;
    height: 0.8rem;
    width: 0.8rem;
    right: 0;
    top: 64%;
    display: block;
  }

  &_edited .terminal__menu__toggle:after {
    background: #ffbf00;
  }

  &_active .terminal__menu__toggle:after {
    background: #2db000;
  }

  .terminal__menu_open .terminal__menu__toggle:after {
    display: none;
  }
}
