.form {
  background: #fff;
  color: #000;
  border: 3px solid var(--brand-highlight);
  border-radius: var(--border-radius-lg);
  text-align: center;
  padding: 1rem;
  display: inline-block;
  margin: 0 auto;

  &__header {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--brand-highlight);
  }

  &__fieldset {
    margin: 1rem 0;
    border: none;
  }

  &__fieldset_cols {
    display: flex;
  }

  &__field {
    padding: 2px 2px 4px 4px;
    border: solid var(--color-disabled);
    border-width: 0 0 1px;
    border-radius: 3px 3px 0 0;
    font-size: 1.125rem;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0 .375rem;
    width: 200px;
    transition: border-color .2s;
  }

  &__field[disabled] {
    color: #ccc;
  }

  &__field:focus {
    outline: none;
    border-color: #525252;
  }

  &__field[disabled] {
    background-color: #eee;
  }

  &__field:focus + &__field-hint {
    color: #525252;
    transition: color .1s
  }


  &__field-hint {
    color: var(--color-light-extra);
    display: block;
    font-size: var(--font-size-xs);
    margin: 0 .375rem;
    text-align: left;
  }

  &__submit-wrap {
    margin: 1rem 0;
  }

  &__submit-wrap .btn {
    margin: 0 5px;
  }

  &__submit {
    transition: all .3s;

    &:hover {
      background: #fff;
      color: var(--brand-highlight);
    }
  }

}
