/*
* --------------------------------------------------
* This file contains all styles for a drop-down menu.
* --------------------------------------------------
*/

/**
* DROP-DOWN menu with sandwich control
* ex: in pnr2 screen header - under the main query field */
.dropdown {
  position: relative;

  &__switcher {
    position: relative;

    .dropdown_active & {
      z-index: calc(var(--z-popup) + 10);
    }

    .dropdown_active.dropdown_up &:after {
      top: -.64rem;
      transform: rotate(180deg);
    }

    .dropdown_active &:after {
      position: absolute;
      bottom: -.64rem;
      left: 50%;

      display: block;
      width: 0;
      height: 0;
      margin-left: -0.5rem;

      content: '';
      border-right: 0.5rem solid transparent;
      border-bottom: 0.5rem solid #fff;
      border-left: 0.5rem solid transparent;
    }
  }

  &__body {
    position: absolute;
    top: 100%;
    right: -0.9rem;

    display: none;
    padding: 0.5rem 1rem;
    margin-top: 0.6rem;

    text-align: left;

    border-radius: var(--border-radius-lg);
    background: #fff;

    .dropdown_active & {
      display: flex;

      z-index: var(--z-popup);
    }

    .dropdown_center & {
      flex-flow: column wrap;
      align-items: center;
      left: auto;
      right: auto;
      text-align: center;
    }

    .dropdown_down & {
      flex-direction: column;
    }
  }

  &__body_col-1_btn {
    position: absolute;
    top: 100%;
    right: calc(50% - 6.5rem);

    .dropdown_left & {
      left: 0;
      right: auto;
    }

    display: none;
    padding: 0.5rem 1rem;
    margin-top: 0.4rem;

    text-align: left;

    border-radius: var(--border-radius-lg);
    background: #fff;

    .dropdown_active & {
      display: flex;

      z-index: var(--z-popup);
    }
  }

  &_block_container {
    margin-top: -1.7rem;
    margin-left: .9rem;
    margin-right: .9rem;
  }

  &_block_content {
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
  }

  &_block {
    border-radius: 0;
    margin-left: 0;
    position: relative;
    left: 0;
  }

  &_block_switcher {
    left: calc(100% - 3rem);

    .dropdown_active &:after {
      left: 0.8rem;
    }
  }

  &_width_max {
    width: 100%;
  }

  &_left &__body {
    left: 0;
    right: auto;
  }

  &_right &__body {
    left: auto;
    right: 0;
  }

  &_up &__body {
    top: auto;
    bottom: calc(100% + .6rem);
  }

  &_center {
    justify-content: center;

    &__body {
      left: auto;
      right: auto;
    }
  }
}
