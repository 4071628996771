.arrangement-seats {
  font-size: 12px;

  &__cabin {
    display: inline-block;
  }

  &__cabin + &__cabin {
    margin-left: 0;
    display: block;
  }
}
