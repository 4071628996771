/*
* --------------------------------------------------
* This file contains all styles related to the button component.
* --------------------------------------------------
*/
button {
  outline: none;
}

[role="button"] {
  cursor: pointer;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.25rem;

  vertical-align: middle;

  border-top: 0.3125rem dashed;
  border-right: 0.3125rem solid rgba(0, 0, 0, 0);
  border-left: 0.3125rem solid rgba(0, 0, 0, 0);

  .btn_dd & {
    margin-right: -0.25em;
  }
}

.sandwich {
  position: relative;

  width: 1rem;
  height: 1rem;

  cursor: pointer;

  & > div,
  & > div::before,
  & > div::after {
    position: absolute;

    box-sizing: border-box;
    width: 1rem;
    height: 0.1875rem;

    background: var(--color-dark);
    border-radius: 1px;
    box-shadow: 0 0 1px rgba(255, 255, 255, 1);
    transform-origin: 50% 50%;

    transition-timing-function: cubic-bezier(0.175, 0.0885, 0.32, 1.275);
    transition-duration: 0.2s;
    transition-property: all;
  }

  & > div {
    top: 50%;
    left: 50%;

    margin-top: -0.09rem;
    margin-left: -0.5rem;
  }

  & > div::before,
  & > div::after {
    content: "";
    top: 0;
    left: 0;

    display: block;
  }

  & > div::before {
    transform: rotate(0deg) translate(0, -0.3125rem);
  }
  & > div {
    transition-duration: 0.1s;
  }
  & > div::after {
    transform: rotate(0deg) translate(0, 0.3125rem);
  }
}

@keyframes loading {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}

.btn {
  display: inline-block;
  height: 1.75rem;
  padding: 0 0.5rem;

  font-weight: bold;
  font-size: var(--font-size-base);
  line-height: calc(1.75rem - (2 * var(--btn-border-width)));
  font-family: var(--font-family-sans-serif);
  text-align: center;
  color: var(--brand-secondary);
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;

  background-color: transparent;
  border: var(--btn-border-width) solid var(--brand-secondary);
  border-radius: var(--btn-border-radius);
  cursor: pointer;

  transition: background-color 0.1s, color 0.1s, border-color 0.1s;
}

.btn_remove {
  height: auto;
  padding: 0;

  font-size: 28px;
  line-height: 13px;
  color: #666666;

  border: none;
}

.btn_width_available {
  width: 100%;
}

.btn.badge {
  padding: 0;
}

/* Buttons sizes */
.btn_xs {
  min-width: 4em;
  height: 1.125rem;
  padding: 0 0.5em;

  font-size: var(--font-size-xs);
  line-height: 1rem;
  text-transform: none;

  border-width: 0.0625rem;
  border-radius: 0.55125rem;
}

.btn_sm {
  min-width: 4em;
  height: 1.125rem;
  padding: 0 0.5em;

  font-size: var(--font-size-base);
  line-height: 1rem;
  text-transform: none;

  border-width: 0.0625rem;
  border-radius: 0.55125rem;
}

.btn_md {
  height: 1.6875rem;

  font-size: var(--font-size-md);
  line-height: 1.4375rem;

  border-width: 0.125rem;
  border-radius: 5px;
}

.btn_lg {
  height: 2.5rem;

  font-size: var(--font-size-base);
  line-height: 2.25rem;

  border-width: 0.125rem;
  border-radius: 1.25rem;
}

.btn_xl {
  height: 3.875rem;
  padding: 0.5rem;

  font-size: var(--font-size-base);
  line-height: 1;

  border-width: 0.1875rem;
  border-radius: var(--border-radius-lg);

  &_low {
    height: 3.63rem;
  }

  &_tall {
    height: 4rem;
  }
}

/* Border radius */
.btn_border-rad-sm {
  border-radius: 0.5rem;
}

/* Buttons colors / roles */
.btn_primary {
  padding-right: 3.125rem;
  padding-left: 3.125rem;

  color: #ffffff;

  background: var(--brand-highlight);
  border-color: var(--brand-highlight);
}

.btn_primary-inv {
  padding-right: 3.125rem;
  padding-left: 3.125rem;

  color: var(--brand-highlight);

  background: #ffffff;
  border-color: #ffffff;
}

.btn_secondary {
  padding-right: 3.125rem;
  padding-left: 3.125rem;

  color: var(--brand-highlight);

  background: #ffffff;
  border-color: var(--brand-highlight);
}

.btn_secondary-nobg {
  padding-right: 3.125rem;
  padding-left: 3.125rem;

  color: var(--brand-highlight);

  background: transparent;
  border-color: var(--brand-highlight);
}

.btn_secondary-nobg.badge {
  color: var(--brand-highlight);

  background: transparent;
  border-color: var(--brand-highlight);
}

.btn_secondary-inv {
  padding-right: 3.125rem;
  padding-left: 3.125rem;

  color: #ffffff;

  background: var(--brand-highlight);
  border-color: #ffffff;
}

.btn:hover,
.btn:active,
.btn_selected {
  color: #ffffff;

  background-color: var(--brand-highlight);
  border-color: var(--brand-highlight);
}

.btn_remove:hover,
.btn_remove:active {
  color: var(--brand-warning);

  background-color: transparent;
}

.btn-history:hover {
  background: transparent url(tais-styles/images/history-icon-white.svg);
}

.btn_primary-inv:hover,
.btn_primary-inv:active {
  /*border-color: #fff;*/
  color: var(--brand-highlight);

  background: #ffffff;
  border-color: #ffffff;
}

.btn_primary:hover {
  background-color: var(--brand-highlight-sec);
  border-color: var(--brand-highlight-sec);
}

.btn_secondary-nobg:hover {
  color: var(--brand-highlight);
  background-color: #ffffff;
}
.btn_secondary-nobg:active {
  background-color: var(--brand-highlight);
  border-color: var(--brand-highlight);
  color: #ffffff;
}

.btn_secondary-inv:hover,
.btn_secondary-inv:active {
  color: var(--brand-highlight);

  background-color: #ffffff;
}

.btn_light {
  color: var(--color-light-extra);

  border-color: currentColor;

  .btn__text {
    color: var(--color-light);
  }

  &:hover .btn__text,
  &:active .btn__text {
    color: inherit;
  }
}

.btn_highlight {
  color: var(--brand-highlight);

  .btn__text {
    color: var(--brand-highlight);
  }
}

.btn_loading,
.btn_loading:hover,
.btn_loading:focus,
.btn_loading:active {
  position: relative;

  overflow: hidden;

  cursor: default;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: inline-block;
    overflow: hidden;

    background-image: linear-gradient(-63deg, rgba(255, 255, 255, 0.15) 25%, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255,255,255, 0.15) 75%, rgba(255, 255, 255, 0) 75%, rgba(255, 255, 255, 0));
    background-size: 25px 50px;

    animation: loading 2s linear infinite;
  }
}

.btn_disabled,
.btn_disabled:active,
.btn_disabled:hover,
.btn:disabled {
  color: #ffffff;

  background-color: var(--color-disabled);
  border-color: var(--color-disabled);
  cursor: auto;

  pointer-events: none;
}

.btn__icon {
  position: relative;

  display: inline-block;

  vertical-align: middle;

  &_plus {
    width: 1.4375rem;
    height: 1.4375rem;
    margin-right: 0.5rem;
    overflow: hidden;

    text-indent: -9999px;

    &::before,
    &::after {
      content: "";
      position: absolute;

      display: block;

      background-color: currentColor;
    }

    &::before {
      top: 0.625rem;
      left: 0;

      width: 100%;
      height: 0.1875rem;
    }

    &::after {
      top: 0;
      left: 0.625rem;

      width: 0.1875rem;
      height: 100%;
    }
  }
}

.btn__text {
  position: relative;

  vertical-align: middle;
}

.btn_add {
  &.btn_primary {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 0L10 20M0 10L20 10' stroke-width='2' stroke='%23ffffff'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: 0.5rem 50%;
  }

  .btn.btn_lg {
    padding-right: 1rem;
    padding-left: 2.5rem;

    background-size: 1.25rem auto;
  }
}

.btn_add-with-txt {
  border: 3px solid currentColor;
}

.btn_cancel {
  position: relative;

  & .btn__icon {
    position: absolute;
    top: 50%;
    right: 1rem;

    display: inline-block;

    vertical-align: middle;
  }

  & .btn__icon.btn-close {
    margin-top: -0.625rem;
  }
}

.btn_add-panel {
  padding: 0;

  & .btn__icon {
    display: inline-block;
    width: 3.5rem;
    padding: 0.5rem;

    vertical-align: middle;
  }

  & .btn__text {
    display: inline-block;
    width: calc( 100% - 3.5rem );
    padding: 0.5rem 0.5rem 0.5rem 0;

    vertical-align: middle;
    text-align: center;
  }
}

.btn_pnr_big {
  .btn__icon {
    padding: 0;
  }

  .badge_plus {
    margin-left: 2rem;
  }

  .btn__text {
    margin-left: 2rem;

    text-align: left;
  }
}

.btn_pnr_small {
  min-width: 7rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;

  .btn__icon {
    padding: 0;
  }

  .btn__text {
    padding-right: 1rem;

    text-align: left;
  }
}

.btn_plus {
  align-self: center;
  width: 3.5rem;

  border-style: none;
}

.btn_extra {
  color: #999999;

  border-color: #666666;
}

.btn_extra:hover {
  color: #ffffff;

  background-color: #878787;
  border-color: #878787;
}

.btn_extra[disabled],
.btn_extra[disabled]:hover {
  color: #999999;

  background-color: #cccccc;
  border-color: #cccccc;
}

.btn_submit {
  padding: 0 24px;

  color: #ffffff;

  background-color: var(--brand-highlight);
  border-color: var(--brand-highlight);
}

.btn_submit:hover,
.btn_submit:focus {
  background-color: var(--brand-highlight-sec);
  border-color: var(--brand-highlight-sec);
}

.btn_cancel-sec {
  padding: 0 24px;

  color: #ffffff;

  background-color: #999999;
  border-color: #999999;
}

.btn_cancel-sec:hover,
.btn_cancel-sec:focus {
  background-color: #666666;
  border-color: #666666;
}

.btn_cancel-sec-small {
  padding: 0 10px;

  font-size: 11px;
  text-transform: uppercase;
}

.btn-notify {
  margin-right: 1em;
  margin-left: 1em;

  &_warn:hover,
  &_warn:active,
  &_warn_selected {
    color: #ffffff;

    background-color: var(--notify-warn-bg);
    border-color: #ffffff;;
  }
}

.btn-excel {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  padding-right: 2rem;
  padding-left: 2rem;

  line-height: normal;
  white-space: normal;

  border-style: dashed;

  &__icon {
    flex: 0 0 auto;
    width: 1.5625rem;
    height: 1.5625rem;
    margin-right: 0.75rem;

    color: inherit;
  }
}

.btn_excel {
  border: none;
  width: 1.5625rem;
  height: 1.5625rem;
  background-color: #666;
  mask-image: url('tais-styles/icons/excel-icon.svg');
}

.btn_excel:hover {
  background-color: var(--brand-highlight);
}

.btn-back,
.btn-back-renew {
  display: inline-block;
  flex-shrink: 0;
  width: 1.625rem;
  height: 1.625rem;
  margin-right: 0.5rem;
  padding: 0;

  vertical-align: text-bottom;

  background: url(tais-styles/svg/btn-back.svg) 50% 50% no-repeat;
  background-size: contain;
  border: none;
  cursor: pointer;
}

.screen__header .btn-back {
  flex: 0 0 auto;
  width: 2.25rem;
  height: 2.25rem;
  position: relative;
  display: inline-block;
  vertical-align: top;
  color: transparent;
  transition: 0.3s;
  padding: 0;
  margin: -0.3125rem 0.5rem 0 0;
  background: none;
  border: 0;
  cursor: pointer;
  text-align: left;
  border-radius: 50%;
}

.screen__header .btn-back:hover,
.screen__header .btn-back:active,
.screen__header .btn-back:focus {
  background: var(--brand-highlight);
}

.screen__header .btn-back::before {
  content: '';
  font-size: 0;
  display: block;
  width: 2.25rem;
  height: 2.25rem;
  background: var(--brand-highlight);
  mask-image: url(tais-styles/svg/back-arrow.svg);
  mask-position: 0 50%;
  mask-repeat: no-repeat;
  mask-size: 100% 100%;
}

.screen__header .btn-back:hover::before,
.screen__header .btn-back:active::before,
.screen__header .btn-back:focus::before {
  background: #ffffff;
}

.btn-settings {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;

  background: url(tais-styles/svg/btn-settings.svg) 50% 50% no-repeat;
  background-size: contain;
  border: none;
  cursor: pointer;
}

.btn-history {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
  background-image: url(tais-styles/images/history-icon.svg);
  border: none;
  cursor: pointer;
}
.btn-comment_black {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
  background-image: url(tais-styles/icons/add-comment.svg);
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  cursor: pointer;
}
.btn-list-dots {
  display: block;
  width: 25px;
  height: 22px;
  padding: 0;
  background-image: url(tais-styles/svg/list-dots.svg);
  border: none;
  cursor: pointer;
}

.btn-list-dots_no-bg {
  background-color: transparent;
}

.btn-filters {
  position: relative;

  width: 1.5rem;
  height: 1.5rem;
  padding: 0;

  vertical-align: top;

  background: url(tais-styles/svg/btn-filters.svg) 50% 50% no-repeat;
  background-size: auto 1.25rem;
  border: none;
  cursor: pointer;

  &_applied {
    border: 1px solid;
  }
}

.btn-rules-header-filter {
  position: relative;
  background: url(tais-styles/svg/btn-rules-header-filter.svg) no-repeat;
  border: none;
  cursor: pointer;

  &__expanded {
    width: 22px;
    height: 20px;

    background: url(tais-styles/svg/btn-rules-header-filter__expanded.svg) no-repeat;
  }

  &__applied {
    height: 24px;
    background: url(tais-styles/svg/btn-rules-header-filter__applied-blue-act.svg) no-repeat;
  }
}

.btn-sync {
  display: block;
  width: 1rem;
  height: 1rem;
  padding: 0;

  background: url(tais-styles/svg/sync.svg) center/contain no-repeat;
  border: none;
  cursor: pointer;
}

.btn-sync_large {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;

  background: url(tais-styles/svg/sync.svg) center/contain no-repeat;
  border: none;
  cursor: pointer;
}

.btn_primary-sync {
  position: relative;
  color: #ffffff;
  background: var(--brand-highlight);
  border-color: var(--brand-highlight);
  padding-right: 2rem;
  padding-left: 2rem;
}

.btn_primary-sync:hover::before {
  transform: translateY(-50%) rotate(90deg);
}

.btn_primary-sync::before {
  content: '';
  display: block;
  width: 1rem;
  height: 1.25rem;
  position: absolute;
  top: 50%;
  left: 0.625rem;
  transform: translateY(-50%);
  transform-origin: center center;
  border: none;
  cursor: pointer;
  mask: url(tais-styles/svg/sync.svg) no-repeat;
  mask-size: 1rem auto;
  background-color: white;
  transition: transform .2s;
}

/* Terminal query submit button */
.btn-go {
  z-index: 1;

  padding: 0.55rem 0.3125rem;

  background: transparent;
  border: none;
  box-shadow: none;

  &::after {
    content: "";

    display: block;
    width: 0.6875rem;
    height: 0.75rem;

    background: url(tais-styles/svg/btn-go.svg) 50% 50% no-repeat;
    background-size: contain;
  }
}

/* Terminal reload button */
.btn-reload {
  display: inline-block;
  width: 1.625rem;
  height: 1.625rem;
  padding: 0;
  overflow: hidden;

  text-align: left;
  text-indent: -9999px;

  background: transparent url(tais-styles/svg/btn-reload.svg) 50% 50% no-repeat;
  background-size: auto 0.75rem;
  border: none;
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;
}

.btn-refresh {
  display: inline-block;
  width: 1.625rem;
  height: 1.625rem;
  overflow: hidden;

  text-align: left;
  text-indent: -9999px;

  background: transparent url("data:image/svg+xml,%3Csvg width='22' height='24' viewBox='0 0 22 24' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 24C6.46 24.01 2.38 21.21 0.74 16.97 -0.9 12.73 0.24 7.92 3.61 4.87L4.95 6.35C1.76 9.26 1.09 14.05 3.37 17.73 5.65 21.4 10.23 22.94 14.26 21.37 18.3 19.8 20.64 15.58 19.85 11.33 19.05 7.07 15.33 3.99 11 4L11 6 7 3 11 0 11 2C17.08 2 22 6.92 22 13 22 19.08 17.08 24 11 24M8.04 6.64C8.28 6.53 8.55 6.52 8.8 6.61 9.05 6.7 9.26 6.89 9.37 7.13L11.9 12.56C12.08 12.96 11.98 13.43 11.65 13.72 11.65 13.73 11.64 13.74 11.64 13.74L8.58 16.8C8.33 17.06 7.96 17.16 7.61 17.07 7.26 16.98 6.98 16.71 6.89 16.36 6.8 16.01 6.91 15.64 7.17 15.39L9.79 12.77 7.56 7.97C7.44 7.73 7.43 7.46 7.52 7.21 7.61 6.96 7.8 6.76 8.04 6.64' fill='%23333333'/%3E%3C/svg%3E") 50% 50% no-repeat;
  background-size: auto 1.5rem;
  border: none;
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;
}

.btn-close {
  position: relative;

  display: block;
  flex: 0 0 auto;
  width: 1.25rem;
  height: 1.25rem;
  padding: 0;
  overflow: hidden;

  text-indent: -9999px;

  background: transparent;
  border: none;
  transform: rotate(45deg);
  transform-origin: 50% 50% 0;
  cursor: pointer;

  appearance: none;

  &::before,
  &::after {
    content: "";
    position: absolute;

    display: block;

    background: var(--brand-primary);
  }

  &::before {
    top: 0;
    right: auto;
    bottom: 0;
    left: 50%;

    width: 0.125rem;
    margin-left: -0.0625rem;
  }

  &::after {
    top: 50%;
    right: 0;
    bottom: auto;
    left: 0;

    height: 0.125rem;
    margin-top: -0.0625rem;
  }

  &_light::before,
  &_light::after {
    background: #ffffff;
  }

  &_lg {
    width: 1.875rem;
    height: 1.875rem;
  }

  &_grey {
    position: absolute;
    top: 1rem;
    right: 1rem;

    width: 2rem !important;
    min-width: auto !important;
    height: 2rem !important;
    margin: 0 !important;

    background-color: #ffffff !important;
    border-color: #cccccc !important;
  }

  &_grey:hover {
    opacity: 0.7;
  }

  &_grey::before,
  &_grey::after {
    background-color: #cccccc;
  }

  &_grey::before {
    height: 1.25rem;
    margin-top: 0.25rem;
  }

  &_grey::after {
    width: 1.25rem;
    margin-left: 0.25rem;
  }
}

.btn-link {
  display: inline-block;
  height: auto;
  padding: 0;

  font-weight: normal;
  font-size: var(--font-size-base);
  line-height: 1.3;
  font-family: var(--font-family-sans-serif);
  color: var(--brand-highlight);
  text-decoration: none;

  background: none;
  border: none;
  border-bottom: 1px solid;
  border-radius: 0;
  cursor: pointer;

  transition: all 0.1s;

  &_sec {
    color: #525252;

    border-bottom-style: dashed;
  }

  /* Sizes */
  &_m {
    font-size: var(--font-size-m);
  }

  &:hover {
    text-decoration: none;

    border-bottom: 1px solid transparent;
  }
}

.btn-link:hover {
  color: var(--brand-highlight-sec);
}

.btn-comment {
  display: inline-block;
  height: 1.375rem;
  padding-left: 1.75rem;

  font-weight: bold;
  font-size: var(--font-size-base);
  text-align: left;
  color: var(--brand-highlight);

  background-color: transparent;
  background-image: url("data:image/svg+xml,%3Csvg width='21' height='21' viewBox='0 0 21 21' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 16.83C9.1 16.83 8.22 16.72 7.39 16.52L7.14 16.47 6.97 16.68C6.97 16.68 5.67 18.15 3.59 18.5 3.96 17.83 4.25 17.2 4.38 16.72 4.56 16.05 4.56 15.59 4.56 15.59L4.56 15.36 4.36 15.24C2 13.74 0.5 11.35 0.5 8.67 0.5 4.19 4.72 0.5 10 0.5 15.28 0.5 19.5 4.19 19.5 8.67 19.5 13.15 15.28 16.83 10 16.83ZM10 4.5L10 13.5M5.5 9L14.5 9' fill='rgba%280,0,0,0%29' stroke='%234ca5ff' stroke-width='1'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 0 50%;
  background-size: auto 1.375rem;
  border: none;
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;
}

.btn-edit {
  display: inline-block;
  width: 1.25rem;
  height: 1.25rem;
  margin: 0;
  padding: 0;

  vertical-align: middle;

  background-color: transparent;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' version='1.1' width='70' height='70' viewBox='0 0 70 70'%3E%3Cpath d='M60.2 3C58.5 3 56.8 3.7 55.5 5L54.3 6.1 63.9 15.7C63.9 15.7 65 14.6 65 14.6 67.7 11.9 67.7 7.6 65 5 63.7 3.7 62 3 60.2 3L60.2 3ZM52.2 8.6C51.9 8.7 51.6 8.8 51.4 9L6.2 54.2C6 54.4 5.9 54.6 5.8 54.8L3 65.3C2.9 65.7 3.1 66.2 3.4 66.6 3.8 66.9 4.3 67.1 4.7 67L15.2 64.2C15.4 64.1 15.6 64 15.8 63.8L61 18.7C61.3 18.3 61.5 17.8 61.4 17.3 61.2 16.8 60.9 16.4 60.4 16.3 59.9 16.2 59.4 16.3 59 16.7L14.1 61.6 8.4 55.9 53.4 11C53.8 10.6 53.9 10 53.7 9.4 53.4 8.9 52.9 8.6 52.3 8.6 52.3 8.6 52.2 8.6 52.2 8.6L52.2 8.6Z' fill='%23333333'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: auto 1.125rem;
  border: none;
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;
}

.btn-edit-sec {
  padding: 0 0 0 14px;

  font-weight: bold;
  font-size: 11px;
  line-height: 13px;
  text-transform: uppercase;
  text-transform: uppercase;

  background-color: transparent;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' version='1.1' width='70' height='70' viewBox='0 0 70 70'%3E%3Cpath d='M60.2 3C58.5 3 56.8 3.7 55.5 5L54.3 6.1 63.9 15.7C63.9 15.7 65 14.6 65 14.6 67.7 11.9 67.7 7.6 65 5 63.7 3.7 62 3 60.2 3L60.2 3ZM52.2 8.6C51.9 8.7 51.6 8.8 51.4 9L6.2 54.2C6 54.4 5.9 54.6 5.8 54.8L3 65.3C2.9 65.7 3.1 66.2 3.4 66.6 3.8 66.9 4.3 67.1 4.7 67L15.2 64.2C15.4 64.1 15.6 64 15.8 63.8L61 18.7C61.3 18.3 61.5 17.8 61.4 17.3 61.2 16.8 60.9 16.4 60.4 16.3 59.9 16.2 59.4 16.3 59 16.7L14.1 61.6 8.4 55.9 53.4 11C53.8 10.6 53.9 10 53.7 9.4 53.4 8.9 52.9 8.6 52.3 8.6 52.3 8.6 52.2 8.6 52.2 8.6L52.2 8.6Z' fill='%23333333'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 1px 1px;
  background-size: 11px;
  border: dashed #333333;
  border-width: 0 0 1px;

  transition: border-color 0.1s, color 0.1s;
}

.btn-edit-sec:hover {
  color: #666666;

  border-bottom-color: transparent;
}

.btn_add {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 0L10 20M0 10L20 10' stroke-width='2' stroke='%23ffffff'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: 0.5rem 50%;

  &.btn_lg {
    padding-right: 1rem;
    padding-left: 2.5rem;

    background-size: 1.25rem auto;
  }
}

.ui-dialog .ui-dialog-buttonpane .ui-dialog-buttonset .ui-button:disabled {
  color: #ffffff;

  background-color: var(--color-disabled);
  border-color: var(--color-disabled);
  cursor: auto;

  pointer-events: none;
}

.btn-link-go {
  padding: 0 1px;

  font-weight: bold;
  font-size: 14px;
  color: #000000;
  text-transform: uppercase;
  text-decoration: none;

  background-color: transparent;
  border: dashed #000000;
  border-width: 0 0 1px;
  cursor: pointer;

  transition: border-color 0.1s, color 0.1s;

  &:hover {
    color: #666666;

    border-color: transparent;
  }
}

.btn-add {
  display: inline-flex;
  align-items: center;
  padding: 10px;

  font-weight: bold;
  font-size: 14px;
  color: #666666;
  text-transform: uppercase;

  background-color: transparent;
  border: none;
  border-radius: 6px;
  cursor: pointer;

  &::before {
    content: "+";

    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    margin-right: 5px;

    font-weight: normal;
    font-size: 24px;

    border: 2px solid currentColor;
    border-radius: 50%;
  }

  &:hover {
    color: #ffffff;

    background-color: var(--brand-highlight);
  }

  &:hover::before {
    color: #ffffff;
  }
}

.dialog_tripple-button {
  .ui-dialog-buttonpane {
    .ui-dialog-buttonset {
      display: flex;
      justify-content: space-around;

      button {
        min-width: 10.5rem;
      }
    }
  }
}

.btn_toggle {
  padding: 0 10px 0 16px;

  background-image: url(tais-templates/svg-icons/toggle-icon.svg);
  background-repeat: no-repeat;
  background-position: 6px 5px;
  background-size: 6px;
  border: none;
}

.btn_toggle-filled {
  font-size: 14px;

  background-image: url(tais-templates/svg-icons/toggle-icon_filled.svg);

  &:hover {
    background-image: url(tais-templates/svg-icons/toggle-icon_filled-white.svg);
  }
}

.btn-out-closer {
  position: absolute;
  top: -20px;
  right: -30px;

  padding: 10px;

  font-size: 30px;
  line-height: 20px;
  color: #ffffff;

  background-color: transparent;
  border: none;
  cursor: pointer;
}

.btn-out-closer:hover {
  color: #333333;
}
