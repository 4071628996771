.carrier-logo {
  min-width: 142px;
  max-width: 142px;

  &_RUSLINE {
    min-height: 54px;
    max-height: 54px;
  }
}

.branding-vru {
  display: none;
}

.branding-host-вру {
  .branding-vru {
    display: inline-block;
  }

  .carrier-logo {
    &_RUSLINE {
      display: none;
    }
  }
}
