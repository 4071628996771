/*
* --------------------------------------------------
* This file contains basic typography styles.
* --------------------------------------------------
*/

body {
  color: #000000;
  font-weight: normal;
  font-family: var(--font-family-sans-serif);
  font-size: var(--font-size-base);
  line-height: 1.42857143;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}

h1,
h2,
h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}
h4,
h5,
h6 {
  margin-top: 10px;
  margin-bottom: 10px;
}

h1,
.h1 {
  font-size: 1.875rem; /* 30px */
}
h2,
.h2 {
  font-size: var(--font-size-xlg); /* 24px */
}
h3,
.h3 {
  font-size: var(--font-size-lg); /* 20px */
}
h4,
.h4 {
  font-size: var(--font-size-md); /* 18px */
}
h5,
.h5 {
  font-size: var(--font-size-m); /* 14px */
}
h6,
.h6 {
  font-size: var(--font-size-base); /* 12px */
}
p {
  margin: 0 0 1em;
}

.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-justify {
  text-align: justify;
}
.text-nowrap {
  white-space: nowrap;
}
.text-lowercase {
  text-transform: lowercase;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-capitalize {
  text-transform: capitalize;
}

ul,
ol {
  margin-top: 0;
  margin-bottom: 10px;
}
ul ul,
ol ul,
ul ol,
ol ol {
  margin-bottom: 0;
}

.link {
  display: inline-block;
  cursor: pointer;
  text-decoration: underline;
  color: var(--brand-highlight);

  &_warn {
    color: var(--brand-warning);
  }

  &_no-underline {
    text-decoration: none;
  }
}
