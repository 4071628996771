.prt-rule {
  margin: -12px 0 0;

  &:first-child {
    margin-top: 0;
  }

  .prt-rule__btn-duplicate {
    background: url(tais-styles/svg/duplicate-grey.svg) 50% 50% no-repeat;
    background-size: contain;
    border: none;
  }

  .prt-rule__btn-insert {
    background: url(tais-styles/svg/plus-grey.svg) 50% 50% no-repeat;
    background-size: contain;
    border: none;
  }

  .prt-rule__btn-delete {
    background: url(tais-styles/svg/minus-grey.svg) 50% 50% no-repeat;
    background-size: contain;
    border: none;
  }

  .prt-rule__btn-up {
    background: url(tais-styles/svg/up-arrow-grey.svg) 50% 50% no-repeat;
    background-size: contain;
    border: none;
  }

  .prt-rule__btn-down {
    background: url(tais-styles/svg/down-arrow-grey.svg) 50% 50% no-repeat;
    background-size: contain;
    border: none;
    margin-left: 0;
  }

  &.screen-sect_active {
    .prt-rule__btn-duplicate {
      background-image: url(tais-styles/svg/duplicate-white.svg);
    }

    .prt-rule__btn-insert {
      background-image: url(tais-styles/svg/plus-white.svg);
    }

    .prt-rule__btn-delete {
      background-image: url(tais-styles/svg/minus-white.svg);
    }

    .prt-rule__btn-up {
      background-image: url(tais-styles/svg/up-arrow-white.svg);
    }

    .prt-rule__btn-down {
      background-image: url(tais-styles/svg/down-arrow-white.svg);
    }
  }

  .btn {
    min-width: 2em;
  }

  .btn:disabled {
    opacity: .2;
    pointer-events: none;
  }

  .screen-sect__header {
    display: flex;
    align-items: center;
    background-color: transparent;
    padding: 0 10px;

    .screen-sect__title {
      color: #000;
      font-weight: normal;
      font-size: 12px;
      flex-grow: 1;
    }
  }

  .button-with-text {
    background-color: transparent;
  }

  .badge_xs {
    width: 30px;
    height: 30px;
    line-height: 30px;
    color: #fff;
    left: 4px;

    .prt-rule__index {
      font-size: 1.125rem;
    }

    & + span {
      font-size: 12px;
      text-transform: uppercase;
      font-weight: bold;
      color: #fff;
    }
  }

  .screen-sect__title {
    position: relative;
    display: flex;
    padding: 10px 0px 16px 16px;
    font-size: 0.875rem;
    text-transform: none;
  }

  .reaccomodation-rule__predicat {
    margin-left: 14px;
    margin-right: 41px;
  }

  .reaccomodation-rule__operation {
    margin-left: 41px;
  }

  .screen-sect__switcher:before {
    position: absolute;
    top: 50%;
    left: 0;
  }

  .screen-sect__visible-wn-active.screen-sect__switcher:before {
    border-color: #fff transparent transparent transparent;
  }

  iframe {
    width: 100%;
    border: 0;
  }

  &__controls {
    padding: 0 16px 30px;
    margin-top: -10px;

    &:after {
      content: "";
      display: table;
      clear: both;
    }

    &__cancel,
    &__rollup {
      height: 34px;
      width: 34px;
      border-radius: 50%;
      background-position: center center;
      background-repeat: no-repeat;
      transition: all .1s;

      &:hover {
        background-color: #fff;
        color: var(--brand-highlight);
      }
    }

    &__cancel {
      border-color: var(--brand-highlight);
      background-image: url(tais-styles/svg/cross-brand.svg);

      &:hover {
        background-color: var(--brand-highlight);
        background-image: url(tais-styles/svg/cross-white.svg);
      }
    }

    &__rollup.btn {
      padding: 0;
      padding-right: 0;
      padding-left: 0;
      background-color: var(--brand-highlight);
      border-color: var(--brand-highlight);
      background-image: url(tais-styles/svg/check-white.svg);
      margin-right: 8px;

      &:hover {
        background-color: #fff;
        background-image: url(tais-styles/svg/check-brand.svg);
      }
    }
  }

  .screen-sect__header-buttons {
    white-space: nowrap;
  }
}
