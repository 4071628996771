/*
* --------------------------------------------------
* This file contains all styles for menus in dropdowns and popovers.
* --------------------------------------------------
*/

.menu_block {
  display: block;
  position: relative;
  z-index: 1;

  &:before {
    content: "";
    position: absolute;
    z-index: -1;

    left: -6px;
    top: -4px;
    right: -6px;
    bottom: -4px;

    border: 2px solid var(--brand-highlight);
    border-radius: var(--border-radius);

    display: none;
  }

  &:hover:before {
    display: block;
  }
}

.menu_item {
  color: var(--color-dark);
  font-size:14px;

  &:hover {
    color: var(--brand-highlight);
    cursor: pointer;
  }
}

.menu-box {
  display: flex;

  .menu__item {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    padding: 1px 6px;
    white-space: nowrap;
  }

  .menu__item:hover {
    background-color: var(--brand-highlight);
    color: #fff;
    border-radius: 4px;
  }
}

.menu {
  min-width: 8rem;
  padding: 0;
  margin-bottom: 0;

  list-style: none;

  line-height: 1.7;

  & &__item {

    &_disabled {
      opacity: 0.6;

      &:hover {
        color: var(--color-dark);
        background: none;
      }
    }
  }

  &:nth-child(n+2) {
    margin-left: 0.6rem;
  }

  &__title {
    padding-left: 0.5rem;
    margin-bottom: 0.2rem;
    color: var(--color-dark);
    font-size: var(--font-size-root);
    font-weight: bold;
  }

  & a,
  & span {
    display: block;
    padding: 0 0.375rem;

    font-size: var(--font-size-m);
    white-space: nowrap;
  }
  & a {
  /* Links in a menu (active elements) */
    color: var(--color-dark);
    text-align: left;
  }
  & a:hover {
    color: #fff;
    background: var(--brand-highlight);
  }
  & span {
  /* Inactive elements of a menu */
    color: var(--color-lighter);
  }

  &-wrap {
    display: grid;
    grid-row-gap: 0.3125rem;
    align-content: start;
    flex-basis: 20%;

    .menu {
      margin: 0 0 0 -6px;
      padding-left: 0;
    }

    .menu-header {
      margin: 0;
      font-weight: bold;
      font-size: 0.875rem;
      color: #000;
      text-transform: capitalize;
      white-space: nowrap;
    }

    .menu-action {
      text-transform: uppercase;
      white-space: normal;
      line-height: 16px;
    }

    .menu-action:last-child {
      margin-bottom: 0;
    }

    .menu-action:hover {
      background-color: var(--brand-highlight);

      .menu-action__txt {
        color: #fff;
      }
    }

    .menu-action__txt {
      text-decoration: none;
      font-size: 0.875rem;
      color: var(--brand-highlight-sec);
      text-transform: none;
    }
  }

  &-wrap-container {
    min-width: 100%;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 0.3125rem;
  }

  &-header {
    margin: 0 0 3px;
  }
}

.popover__body--old {
  .menu {
    margin: 0;
    padding-left: 10px;
    text-transform: uppercase;
  }

  .menu-header {
    text-transform: uppercase;
  }

  .menu-wrap {
    padding-right: 10px;
  }

  .menu-action__txt {
    font-size: inherit;
    color: inherit;
    text-transform: uppercase;
    text-decoration: none;
  }

  .menu-wrap[data-key=group-01] + .menu-wrap {
    padding-left: 20px;
  }

  .menu-wrap:last-of-type {
    padding-right: 0;
  }

  .menu-wrap-container {
    display: grid;
    grid-template-columns: repeat(3, 230px);
    grid-column-gap: 10px;
    margin-left: 10px;
  }
}

/* Скрываем пункты меню, если указана настройка flt-interface-mode = 2 */
.flt-interface-mode {
  .menu-wrap {
    &__passengers,
    &__info,
    &__telegrams {
      display: none;
    }
  }
}
