/**
* FLIGHTS
*/

.flight {

  .panel__row_indent_badge {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 0;

    .tag-group {
      width: calc(50% - 20px);
    }
  }

  &__layover {
    position: relative;

    margin-left: 0.375rem;
    padding-left: 4.6875rem;

    color: #fff;

    line-height: 1.7;

    &::before,
    &::after {
      position: absolute;
      left: 2.1rem;

      display: block;
      width: 0;
      height: 0;

      content: '';

      border-right: 0.9rem solid transparent;
      border-left: 0.9rem solid transparent;
    }

    &::before {
      border-top: 0.9rem solid #fff;
      bottom: 50%;
    }

    &::after {
      top: 50%;
      border-bottom: 0.9rem solid #fff;
    }

    &_fixed_height {
      height: 1.25rem;
    }

    &_text {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      color: #000;
    }
  }

  &__duration {
    margin-right: 0.375rem;
    color: #fff;
    font-weight: bold;
    line-height: 1.7;
    text-align: right;
  }

  &__footer {
    display: none;
    padding: 0.5rem 2rem;

    .btn-sitemap {
      background: none;
      border: none;
      color: #fff;
      font-size: 16px;
      font-weight: bold;
      text-transform: uppercase;
      cursor: pointer;
      padding: 0;
      transition: text-shadow .1s;

      &:hover {
        text-shadow: 0 0 1px #000;
      }
    }
  }

  &_selectable {
    cursor: pointer;
    box-shadow: inset 0 0 0 0 transparent;
  }

  &_unselectable {
    opacity: .5;
  }

  &_unselectable .bav-seats_na .tag {
    color: var(--brand-warning);
  }

  /* Hovered and selected flight */
  &_selectable:hover,
  &_selected {
    box-shadow: inset 0 0 0 0.1875rem var(--brand-highlight);

    & .panel__control .badge,
    &.panel > .badge {
      background-color: var(--brand-highlight);
    }
  }

  &_selected {
    cursor: default;
  }

  &_select-complete {
    & .flight__footer {
      display: block;
      background: var(--brand-highlight);
    }
  }

  /* Flight can not be selected */
  &_disabled {
    &.panels-group {
      background: var(--color-disabled);
    }

    & .badge {
      background-color: #eee;
    }

    & [class^="flt-"] {
      color: var(--color-disabled);
    }

    & .flt-seg-arr {
      opacity: 0.33;
    }

    & .flt-contract-num,
    & .bav-seats {
      opacity: 0.5;
    }
  }
}

@media (--vp-max-lg) {
  .grid_lg .grid__col-lg-6 .selected-line {
    left: calc( var(--grid-gutter) / 2 );

    &::before,
    &::after {
      left: 0;
      right: calc( -1 * var(--grid-gutter) );
    }
  }

  .screen__body-cont .grid__col-lg-6 .selected-line {
    left: calc( var(--grid-gutter) / 2 ) + .675rem;
  }
}

.segment {
  min-width: 50em;
  margin-bottom: 12px;

  &__marginless {
    margin-bottom: 0;
  }

  &__flight-info {
    width: fit-content;
    padding: 0.1rem 0.1rem 0.1rem 0;

    .franchize-chevron {
      height: 1.125rem;
      padding: 0.25rem 0.5rem;
      border-radius: 0.5rem;
      line-height: 12px;
      background: var(--color-light);
      color: #ffffff;
      font-size: 0.5rem;
      font-weight: 700;
      width: fit-content;
    }
  }

  &__arrival,
  &__departure,
  &__duration,
  &__sclass,
  &__status {
    flex: 0 1 auto;
    text-align: center;
  }

  &__date {
    width: fit-content;
    min-width: 4rem;
    text-align: center;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  &__arrival {
    width: fit-content;
    min-width: 4.5rem;
    padding-left: 0;
    padding-right: 1rem;
  }

  &__departure {
    width: fit-content;
    min-width: 4.5rem;
    padding-left: 1rem;
    padding-right: 0;
  }

  &__duration {
    position: relative;
    width: auto;
    padding: 1rem 0.5rem 0;
  }

  &__duration_with-stops {
    padding-top: 0;
  }

  &__sclass {
    width: fit-content;
    min-width: 4em;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  &__status {
    width: fit-content;
    min-width: 4em;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  &__badges {
    flex: 1 1 auto;
    min-width: min-content;
    width: fit-content;
    padding-left: 1rem;

    .availability & {
      width: 50%;
    }
  }

  &__notes {
    flex: 1 1 auto;
    align-self: flex-start;
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    padding-left: 0.6rem;

    .panel__row {
      padding: 0;
    }

    .btn-sitemap {
      display: inline-block;
      background: none;
      border: none;
      padding: 0;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      color: var(--brand-highlight);
      white-space: nowrap;
      cursor: pointer;
      border-bottom: 1px solid transparent;
      transition: border-color .1s;

      &:hover {
        border-color: var(--brand-highlight);
      }
    }
  }

  &__additional-info {
    color: var(--brand-secondary);
    font-size: var(--font-size-m);
  }

  &_canceled {
    position: absolute;
    left: 46%;
    top: 70%;
    color: var(--brand-warning)
  }
}

.flt-flight-num,
.flt-sclass-code {
  position: relative;

  font-size: var(--font-size-md);
  font-weight: bold;

  white-space: nowrap;
  &__info {
    margin-left: 5px;
    line-height: 18px;
  }
}

.flt-flight-num__superscript {
  display: inline-block;
  position: relative;
  top: -0.5em;
  font-size: 0.8em;
  font-weight: bold;
  white-space: nowrap;
}

.flt-flight__carier-info {
  top: -0.5em;
  height: 10px;
  font-size: 0.6em;
  font-weight: bold;
  white-space: nowrap;
  position: relative;
}

.flt-time {
  position: relative;
  font-size: var(--font-size-lg);
  font-weight: bold;
  line-height: 0.8;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  align-items: center;
}

/**
* 1. In order not to break center alignment of a parent element's text
*/
.flt-point > span,
.flt-time > span {
  position: relative;
}

.flt-flight-num sup,
.flt-point sup,
.flt-time sup {
  position: absolute;
  top: 0;
  left: 100%;
  margin-top: 0.55em;
  font-size: 9px;
}

/*
.flt-flight-num sup::before,
.flt-point sup::before,
.flt-time sup::before {
  content: '\00A0';
}
*/

.flt-plane,
.flt-oak {
  color: var(--brand-secondary);
  line-height: 1.1;
}

.flt-oak > span {
  color: #000;
  white-space: nowrap;
}

.flt-day {
  margin-top: 0.2rem;
  font-size: var(--font-size-lg);
  font-weight: bold;
  line-height: 1;
}
.flt-month {
  font-weight: bold;
  line-height: 1.3;
  text-transform: uppercase;
}
.flt-dow {
  color: var(--brand-secondary);
  font-size: var(--font-size-xxs);
  line-height: 1;
  text-transform: uppercase;
}

.flt-seg-arr {
  position: relative;

  width: 4.5rem;
  height: 2px;
  margin: 0.25rem auto;

  background: var(--brand-secondary);
}

.flt-seg-arr::before {
  position: absolute;
  top: 50%;
  right: 0;

  display: block;
  box-sizing: border-box;
  width: 0.625rem;
  height: 0.625rem;
  margin-top: -0.3125rem;

  content: '';

  transform: rotate(45deg);

  border: 0.125rem solid;
  border-color: var(--brand-secondary) var(--brand-secondary) transparent transparent;
}

.flt-seg-arr_stopover::after {
  position: absolute;
  top: 50%;
  left: 50%;

  display: block;
  box-sizing: content-box;
  width: 0.5rem;
  height: 0.5rem;
  margin-top: -0.4375rem;
  margin-left: -0.4375rem;

  content: '';

  border-radius: 50%;
  border: 0.1875rem solid #fff;
  background: var(--brand-secondary);
  background-clip: padding-box;
}

.flt-seg-stops {
  white-space: nowrap;
}

.flt-flt-status {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;

  min-height: 1rem;
  text-align: center;
  text-transform: uppercase;
  color: var(--brand-secondary);
  font-size: var(--font-size-xs);
}

.flt-seg-stops,
.flt-seg-duration,
.flt-time-orig {
  color: var(--brand-secondary);
  font-size: var(--font-size-xs);
}

.flt-time-orig {
  font-weight: normal;
  margin-right: 5px;
}

.flt-sclass-code {
  line-height: 1.3;
}

.flt-sclass-name,
.flt-seg-status {
  text-transform: uppercase;
  color: var(--brand-secondary);
  font-size: var(--font-size-xxs-extra);
  line-height: 1.7;
}

.flt-seg-status {
  .flt-seg-timelimit {
    line-height: initial;
  }
}

.flt-seg-status__time-limit {
  margin-left: -4px;
}

.flt-aas {
  margin-top: 0.3rem;
}

.flt-arnk {
  font-size: var(--font-size-md);
  font-weight: bold;
  line-height: 1.2;
}
