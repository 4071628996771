.ss__suggestions {
  position: relative;
  overflow: visible;
  font-size: var(--ss-font-size-base);
  line-height: var(--ss-font-size-base);
}

.ss__suggestions__main {
  z-index: 10;
  position: relative;
  overflow: auto;
  min-height: 4.75rem;
  max-height: 50vh;
  border-top: 2px solid #ccc;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #fff;
}

/*
 toggle
*/

.ss__suggestions__toggle {
  position: absolute;

  left: 50%;
  top: 100%;
  height: 1.125rem;
  margin-left: -1.125rem;
  padding-left: calc(var(--ss-indent-base) + 2px);
  padding-right: calc(var(--ss-indent-base) + 2px);

  background-color: #fff;
  border: 2px solid #ccc;
  border-top: 0 none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  text-align: center;
  cursor: pointer;

  /* shadow effect fix */
  &:before,
  &:after {
    position: absolute;
    content: "";
  }

  &:before {
    left: -2px;
    top: -10px;
    bottom: 100%;
    width: 1rem;
    border-left: 2px solid #ccc;
    background-color: #fff;
  }

  &:after {
    top: 0;
    right: -10px;
    left: 100%;
    height: 2px;
    background-color: #ccc;
  }

  /* text */
  .ss__suggestions__toggle__text {
    display: inline-block;
    vertical-align: top;
    color: #ccc;
    transform: scaleX(2);

    &:before {
      content: '\2227';/* ∧ */
    }
  }
}

.ss__container_suggestions-pre-display {
  .ss__suggestions__node-pointer,
  .ss__suggestions__toggle {
    visibility: hidden;
  }
}

.ss__container_suggestions-closed {
  .ss__suggestions__node-pointer {
    visibility: hidden;
  }

  .ss__suggestions__main {
    display: none;
  }

  .ss__suggestions__toggle {
    &:before {
      background-color: #fff;
    }

    .ss__suggestions__toggle__text {
      &:before {
        content: '\2228';/* ∨ */
      }
    }
  }
}

/*
 head
*/

.ss__suggestions__main-head {
  position: relative;
  z-index: 10;
  padding: var(--ss-indent-base);
  color: #fff;
  background-color: var(--brand-highlight);
  font-size: 1.125rem;
  line-height: 1.625rem;

  &__command-description {
    display: inline-block;
    float: left;
    width: calc(100% - 17rem);
    padding-top: 0.375rem;

    line-height: 1.25;
    font-size: var(--ss-font-size-base);
    color: #e5e5e5;
  }

  .ss__button {
    float: right;
    margin-left: var(--ss-indent-base-x2);
  }

  .ss__button:first-child {
    float: left;
    margin-left: 0;
    margin-right: var(--ss-indent-base-x2);
  }

  .ss__button-back {
    margin-top: -2px;
    opacity: 1;
    &:hover {
      opacity: 0.75;
    }
  }

  &:before,
  &:after {
    display: table;
    content: "";
    box-sizing: border-box;
  }

  &:after {
    clear: both;
  }
}

/*
  node pointer
*/
.ss__suggestions__node-pointer {
  position: absolute;
  z-index: 20;

  top: 0;
  left: 0;
  right: 0;
  height: 1px;

  border-top: 2px solid #ccc;

  .ss__suggestions__node-pointer__arrow {
    position: absolute;

    top: 0;
    left: 1rem;
    width: 1px;
    height: 1px;
    margin-left: 0.5rem;

    &:before {
      position: absolute;
      content: "";
      left: 0;
      bottom: 100%;
      height: 0;
      width: 0;
      border: solid transparent;
      border-bottom-color: #ccc;
      border-width: 0.8125rem;
      margin-left: -0.8125rem;
    }

    &:after {
      position: absolute;
      content: "";
      left: 0;
      bottom: 100%;
      height: 0;
      width: 0;
      border: solid transparent;
      border-bottom-color: #fff;
      border-width: 0.625rem;
      margin-left: -0.625rem;
    }
  }

  &.ss__suggestions__node-pointer_inter {
    .ss__suggestions__node-pointer__arrow {
      &:after {
        border-bottom-color: #fff;
      }
    }
  }
}

/*
 list map
*/

.ss__suggestions__list-map {
  display: table;
  width: 100%;
}

/*
 list
*/

.ss__suggestions__list {
  position: relative;
  z-index: 10;
  display: table-cell;
  vertical-align: top;
  width: calc(31%);
  min-width: 270px;
  padding: var(--ss-indent-base-x2) var(--ss-indent-base-x2) var(--ss-indent-base) var(--ss-indent-base);
  background-color: #fff;

  &:after {
    content: "";
    position: absolute;
    z-index: 1;
    top: var(--ss-indent-base-x2);
    bottom: var(--ss-indent-base);
    right: 0;
    width: 1px;
    border-right: 1px solid #eee;
  }
}
.ss__suggestions__list:empty {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
}

/* @Deprecated */
.ss__suggestions__list__head-panel {
  position: relative;
  padding-bottom: 2.5rem;
}

.ss__suggestions__list__actions {
  padding-top: var(--ss-indent-base-x2);

  .ss__button {
    margin-right: var(--ss-indent-base);
    &:last-child {
      float: right;
      margin-right: 0;
    }
  }
}

.ss__suggestions__list__items {
  position: relative;
}

.ss__suggestions__list__item {
  text-transform: uppercase;
  &:empty {
    visibility: hidden;
  }
}

.suggestions-item-help-text {
  line-height: 1.25;
}

/* TODO: remove :not(.suggestions-item-help-text) */
.ss__suggestions__list__item:not(.suggestions-item-help-text),
.ss__suggestions__list__item:not(.ss__suggestions__list__map__groups),
.ss__suggestions__list__item:not(.datepicker)
{
  margin-bottom: var(--ss-indent-base);

  display: table;
  position: relative;
  z-index: 1;

  &:before {
    display: none;
    content: "";
    position: absolute;
    z-index: -1;

    left: -14px;
    top: -7px;
    right: -14px;
    bottom: -7px;

    border: 1px solid transparent;
    border-radius: 9px;
  }

  &.active,
  &:hover {
    &:before {
      display: block;
      border-color: var(--brand-highlight);
    }
  }

  &:hover {
    cursor: pointer;
    color: var(--brand-highlight);
  }

  &.active,
  &.active:before {
    color: #fff;
    background-color: var(--brand-highlight);
  }

  &.suggestions-item-help-text,
  &.ss__suggestions__list__map__groups,
  &.datepicker,
  &.timepicker {
    &.active, &.active:before {
      background-color: #fff;
    }
  }

  &.timepicker {
    color: #000;
    margin-left: 15px;
  }

  &:hover .ss__suggestions__list__item__hotkey {
    color: var(--brand-highlight);
  }
  &.active .ss__suggestions__list__item__hotkey {
    color: #e5e5e5;
  }
}

.ss__suggestions__list__item__code {
  display: table-cell !important; /* TODO: remove !important when clear parent bad CSS */
  vertical-align: top;
  min-width: 3.5rem !important; /* TODO: remove !important when clear parent bad CSS */
  width: 1%;
  padding-right: var(--ss-indent-base-half);
}

.ss__suggestions__list__item__separator {
  display: block;
  vertical-align: top;
  width: 100%;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

.ss__suggestions__list__item__text {
  display: table-cell !important; /* TODO: remove !important when clear parent bad CSS */
  vertical-align: top;
  width: 99%;
}

.ss__suggestions__list__item__hotkey {
  display: block;
  padding-top: var(--ss-indent-base-half);
  font-size: var(--ss-font-size-small);
  color: #333333;
}

.ss__suggestions__list__row_opt-suggestion {
  &:before,
  &:after {
    display: table;
    content: "";
    box-sizing: border-box;
  }

  &:after {
    clear: both;
  }

  .ss__suggestions__list__item {
    float: right;
    max-width: 40%;
    white-space: nowrap;
  }

  .ss__suggestions__list__help {
    &:before,
    &:after {
      display: table;
      content: "";
      box-sizing: border-box;
    }

    &:after {
      clear: both;
    }
    clear: both;
  }
}

.ss__suggestions__list__row_command-verb {
  display: flex;
  align-items: flex-start;


  .ss__suggestions__list__header {
    min-width: 316px;
    max-width: 316px;
    padding-right: 20px;
  }

  .ss__suggestions__list__item {
    white-space: nowrap;
  }
}

.ss__suggestions__list__header {
  padding-bottom: var(--ss-indent-base-x2);
  font-size: 1rem;
  font-weight: bold;
}

.ss__suggestions__list__help {
  margin: -0.625rem 0 var(--ss-indent-base-x2) 0;
  color: #636363;
  margin-top: -0.325rem;

  .ss__color-primary {
    color: var(--brand-primary);
  }
  .ss__color-danger {
    color: var(--ss-color-danger);
  }
}

.ss__suggestions__list.no-sug-map:not(:empty),
.ss__suggestions__list.no-sug-map:not(.ss__suggestions__list_optional-params-placeholder) {

  .ss__suggestions__list__rows {
    min-width: 270px;
    max-width: 500px;
  }

  .ss__suggestions__list__items {
    vertical-align: top;

    .ss__suggestions__list__item {
      width: 135px;
      margin-bottom: var(--ss-indent-base-x2);
    }
  }
}

.ss__suggestions__map {
  position: relative;
  z-index: 10;
  display: table-cell;
  vertical-align: top;
  padding: var(--ss-indent-base-x2) var(--ss-indent-base) var(--ss-indent-base) 0;
  background-color: #fff;
  overflow: hidden;
}

.ss__suggestions__map__content {
  margin-left: calc(var(--ss-indent-base) + var(--ss-indent-base-half));
  margin-right: -0.625rem;
}

.ss__suggestions__map__group {
  position: relative;
  flex: 1;
  padding-left: var(--ss-indent-base);
  padding-right: var(--ss-indent-base-x2);
}

.ss__suggestions__map__groups {
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  .ss__suggestions__map__group {
    &:before {
      content: "";
      position: absolute;
      left: -10px;
      top: -10px;
      right: 4px;
      bottom: -7px;
      background-color: #eee;
    }

    &:nth-child(2):before {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    &:last-child:before {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  .ss__suggestions__map__group_secondary,
  .ss__suggestions__map__group_mandatory {
    &:before {
      background-color: transparent;
    }
  }

}

.ss__suggestions__map__group__header {
  position: relative;
  padding-bottom: var(--ss-indent-base-x2);
  font-size: 1rem;
  font-weight: bold;

  text-transform: none;
  white-space: nowrap;

  .ss__suggestions__map__toggle {
    position: absolute;
    top: -0.25rem;
    right: 0;
  }

}

.ss__suggestions__map__group:first-child >.ss__suggestions__map__group__header {
  text-transform: uppercase;
}

.ss__suggestions__map__item {
  display: table;
  position: relative;
  z-index: 1;
  margin-bottom: var(--ss-indent-base);
  text-transform: none;

  &:before {
    display: none;
    content: "";
    position: absolute;
    z-index: -1;

    left: -6px;
    top: -4px;
    right: -6px;
    bottom: -4px;

    border: 1px solid transparent;
    border-radius: 3px;
  }

  &.active,
  &.current,
  &.mandatory,
  &:hover {
    &:before {
      display: block;
    }
  }

  &:hover {
    cursor: pointer;
    color: var(--brand-highlight);
  }

  &.active {
    font-weight: bold;
  }

  &.current,
  &.current:before {
    color: #f2f2f2;
    background-color: var(--brand-highlight);
  }

  &.current:not(.mandatory):not(.right-remover):before {
    left: -1.25rem;
  }

  &.two-opts-in-line {

    &_start-single {
      display: inline-block;
      margin-right: 7px;
    }

    &_start-both {
      display: inline-block;
    }

    &_end-existing {
      &.disabled {
        cursor: default;
        color: #ccc;
        border-bottom: 1px dashed #ccc;
      }
      left: 10px;
      display: inline-block;
    }

    &_end-non-existing {
      &.disabled {
        cursor: default;
        color: #ccc;
        border-bottom: 1px dashed #ccc;
        pointer-events: none;
      }
      display: inline-block;
      font-style: italic;
      border-bottom: 1px dashed var(--brand-highlight);
    }
  }

  &.inline-splitter {
    content:"\A";
    white-space:pre;
  }

  &.optional, &.mandatory{
    &.disabled {
      cursor: default;
      color: #ccc;
    }
  }

  &.opt-flag-item-bidirectional {
    padding-left: var(--ss-indent-base-x2);
  }

  /* TODO: FIXME: more accurately place tables text aligned horizontally left */
  &.opt-flag-item > table > tbody > tr > td {
    vertical-align: top;
  }

  &_child {
    padding-left: 10px;
    display: none;

    &.displayed {
      display: block;
    }
  }

  &_group {
    .toggle-child-items {
      margin-left: 10px;

      &:after {
        content: '>';
        display: inline-block;
      }

      &.rotate {
        &:after {
          transform: rotate(90deg);
        }
      }
    }
  }
}

.ss__suggestions__map__item__caption {
  display: table;
  position: relative;
  z-index: 1;

  &:before {
    display: none;
    content: "";
    position: absolute;
    z-index: -1;

    left: -6px;
    top: -4px;
    right: -6px;
    bottom: -4px;

    border: 1px solid transparent;
    border-radius: 3px;
  }

  cursor: default;
  margin-bottom: var(--ss-indent-base);
  text-transform: uppercase;
  font-weight: bold;
}

/* TODO: refactoring HTML<->CSS */
.ss__suggestions__list__items .ss__suggestions__map__item {
    &.active,
    &.active:before {
      color: #f2f2f2;
      background-color: var(--brand-highlight);
    }
}

/*
  sorting
*/
.ss__suggestions__item__sorting {
  display: block !important;       /* TODO: remove !important when clear parent bad CSS */
  position: absolute !important;  /* TODO: remove !important when clear parent bad CSS */

  top: -0.25rem;
  left: -0.25rem;
  padding: 0.25rem;

  font-size: 0.875rem !important;  /* TODO: remove !important when clear parent bad CSS */
  line-height: 1 !important;      /* TODO: remove !important when clear parent bad CSS */

  &:hover {
      color: #fff;
      text-shadow: 0 1px 0 #e5e5e5;
  }

  &.ss__suggestions__item__sorting_asc:before {
    content: "▲";
  }

  &.ss__suggestions__item__sorting_desc:before {
    content: "▼";
  }
}

/*
  remover
*/
.ss__suggestions__map__item:not(.right-remover) > .ss__suggestions__item__remover {
    display: none !important;       /* TODO: remove !important when clear parent bad CSS */

    position: absolute !important;  /* TODO: remove !important when clear parent bad CSS */

    top: -0.25rem;
    left: -1.25rem;
    padding: 0 0.25rem;

    font-size: 1.25rem !important;  /* TODO: remove !important when clear parent bad CSS */
    line-height: 1 !important;      /* TODO: remove !important when clear parent bad CSS */
    opacity: 0.75;

    &.active {
      display: block !important;    /* TODO: remove !important when clear parent bad CSS */
    }

    &:hover {
      color: var(--ss-color-danger);
      opacity: 1;
    }

    .active & {
        font-weight: normal;
    }
}

.ss__suggestions__map__item.right-remover {
  padding-left: 0;
  padding-right: 12px;

  > .ss__suggestions__item__remover {
    display: none !important;       /* TODO: remove !important when clear parent bad CSS */

    position: absolute;
    top:  -0.15rem;
    left: calc(100% - 7px);

    font-size: 1.25rem !important;  /* TODO: remove !important when clear parent bad CSS */
    line-height: 1 !important;      /* TODO: remove !important when clear parent bad CSS */
    opacity: 0.75;

    &.active {
      display: inline !important;    /* TODO: remove !important when clear parent bad CSS */
    }

    &:hover {
      color: var(--ss-color-danger);
      opacity: 1;
    }

    .active & {
        font-weight: normal;
    }
  }
}

.ss__suggestions__item__value-exchanger:hover {
  color: var(--ss-color-danger);
  opacity: 1;
}

/*
  map groups in list
*/
.ss__suggestions__list__item.ss__suggestions__list__map__groups {
  margin-left: 0;
  padding-top: 0.4375rem;

  .ss__suggestions__map__group__header {
    padding-bottom: var(--ss-indent-base);
    font-size: var(--ss-font-size-base);
  }

  .ss__suggestions__list__map__group:not(:first-child) {
    .ss__suggestions__map__group__header {
      padding-top: var(--ss-indent-base);
    }
  }
}


/*
  mandatory map group
*/
.ss__suggestions__map__groups.ss__suggestions__map__groups_single-group {
  .ss__suggestions__map__group__header {
    display: none;
  }
}

.ss__suggestions__map__group_mandatory {
  .active.ss__suggestions__map__item {
    font-weight: bold;
  }
}

/*
  suggestion values
*/

.ss__suggestions__item-inline {
  margin-left: 7px;
  margin-right: 7px;
  display: inline !important;
}

.ss__suggestions__item-dropdown {
  margin-left: 7px;
}

.ss__suggestions__item-capitalize {
  text-transform: none;

  span {
    text-transform: uppercase;
  }
}

.ss__suggestions__item-capitalize:first-letter {
  text-transform: uppercase;
}

/*
  datepicker
*/
.ss__suggestions__list__item.datepicker {
  text-transform: none;

  .ui-datepicker-prev,
  .ui-datepicker-next {
    text-align: left;
    top: 4px;
  }

  /* override "nav big button" */
  .ui-datepicker-header .ui-state-hover {
    border-color: transparent;
    background: transparent none;
    font-weight: normal;
    color: var(--brand-highlight);
    cursor: pointer;
  }
}

.synonym-lowercase {
  text-transform: lowercase;
}
.synonym-with-dash:before {
  content: "-";
  margin-right: 5px;
}
