@media print {
  .page__body,
  .screens,
  .screens .screen,
  .screen__body {
    overflow: visible;
  }

  html,
  .page,
  .report-daily,
  .page__body,
  .screens,
  .screens .screen,
  .screen__body,
  .screen__header {
    background-color: #fff;
    -webkit-print-color-adjust: exact !important;
  }


  .main-loader,
  .terminal__header,
  .j-page-overlay,
  .versions_container,
  .btn-back,
  .screen__footer,
  .popover__switcher {
    display: none;
  }

  .stack-panel {
    display: none !important;
  }

  .screen-sect_active>.screen-sect__details {
    max-height: none;
  }

  .screen {
    &__header {
      border-bottom: none;
    }
  }

  .panel {
    pre {
      font-size: 16px;
      color: #000;
    }
  }
}
