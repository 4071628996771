@charset "UTF-8";
@import 'tais-styles/styles/base/jquery-ui.css';
@import 'tais-styles/styles/base/jquery-ui.theme.cssnext';

/* 1. Configuration and helpers */
@import 'tais-styles/styles/utils/custom_media.cssnext';

/* 2. Base stuff */
@import 'tais-styles/styles/base/base.cssnext';
@import 'tais-styles/styles/base/fonts.cssnext';
@import 'tais-styles/styles/base/typography.cssnext';
@import 'tais-styles/styles/base/helpers.cssnext';
@import 'tais-styles/styles/base/translate.cssnext';

/* 3. Layout-related sections */
@import 'tais-styles/styles/layout/grid.cssnext';
@import 'tais-styles/styles/layout/layout.cssnext';
@import 'tais-styles/styles/layout/forms.cssnext';
@import 'tais-styles/styles/layout/side-panel.cssnext';
@import 'tais-styles/styles/layout/mainmenu.cssnext';
@import 'tais-styles/styles/layout/screensstack.cssnext';
@import 'tais-styles/styles/layout/query__field.cssnext';
@import 'tais-styles/styles/layout/query__search-history.cssnext';
@import 'tais-styles/styles/layout/sticky-nav.cssnext';

/* 4. Components */
@import 'tais-styles/styles/components/buttons.cssnext';
@import 'tais-styles/styles/components/input.cssnext';
@import 'tais-styles/styles/components/select.cssnext';
@import 'tais-styles/styles/components/checkbox.cssnext';
@import 'tais-styles/styles/components/check.cssnext';
@import 'tais-styles/styles/components/radio.cssnext';
@import 'tais-styles/styles/components/dow.cssnext';
@import 'tais-styles/styles/components/tags.cssnext';
@import 'tais-styles/styles/components/badges.cssnext';
@import 'tais-styles/styles/components/arrows.cssnext';
@import 'tais-styles/styles/components/wave.cssnext';
@import 'tais-styles/styles/components/tabs.cssnext';
@import 'tais-styles/styles/components/sections.cssnext';
@import 'tais-styles/styles/components/blocks.cssnext';
@import 'tais-styles/styles/components/tgl-content.cssnext';
@import 'tais-styles/styles/components/carousel.cssnext';
@import 'tais-styles/styles/components/dropdown.cssnext';
@import 'tais-styles/styles/components/dropdown_select.cssnext';
@import 'tais-styles/styles/components/dropdown_fused.cssnext';
@import 'tais-styles/styles/components/popover.cssnext';
@import 'tais-styles/styles/components/menu.cssnext';
@import 'tais-styles/styles/components/popup.cssnext';
@import 'tais-styles/styles/components/find_flight.cssnext';
@import 'tais-styles/styles/components/flights.cssnext';
@import 'tais-styles/styles/components/selectedLine.cssnext';
@import 'tais-styles/styles/components/seat_map.cssnext';
@import 'tais-styles/styles/components/passengers.cssnext';
@import 'tais-styles/styles/components/tourists.cssnext';
@import 'tais-styles/styles/components/spinner.cssnext';
@import 'tais-styles/styles/components/versions.cssnext';
@import 'tais-styles/styles/components/todo.cssnext';
@import 'tais-styles/styles/components/terminal-result.cssnext';
@import 'tais-styles/styles/components/terminal-generation.cssnext';
@import 'tais-styles/styles/components/json.cssnext';
@import 'tais-styles/styles/components/notify-zone.cssnext';
@import 'tais-styles/styles/components/upload-page-filter.cssnext';
@import 'tais-styles/styles/components/tourists-page-filter.cssnext';
@import 'tais-styles/styles/components/ssr-flights.cssnext';
@import 'tais-styles/styles/components/ssr-list.cssnext';
@import 'tais-styles/styles/components/editable-table.cssnext';
@import 'tais-styles/styles/components/eqp-editor.cssnext';
@import 'tais-styles/styles/components/reaccomodation-rule.cssnext';
@import 'tais-styles/styles/components/arrangement-seats.cssnext';
@import 'tais-styles/styles/components/prf/prf-rbd-editor.cssnext';
@import 'tais-styles/styles/components/prt/prt-rules-selection.cssnext';
@import 'tais-styles/styles/components/prt/prt-rule.cssnext';
@import 'tais-styles/styles/components/prt/screen_prt_rule.cssnext';
@import 'tais-styles/styles/components/undoredo.cssnext';
@import 'tais-styles/styles/components/badge-empty.cssnext';
@import 'tais-styles/styles/components/form.cssnext';
@import 'tais-styles/styles/components/ssr/ssr-item.cssnext';
@import 'tais-styles/styles/components/hint.cssnext';
@import 'tais-styles/styles/components/lists.cssnext';

/* 5. Page-specific styles */
/* 5.1 TWT Pages */
@import 'tais-styles/styles/pages/list.cssnext';

/* 6 Branding */
@import 'tais-styles/styles/branding/kk.cssnext';

@import 'tais-styles/styles/pages/wbase.cssnext';
@import 'tais-styles/styles/base/global-print.cssnext';

.nav.leftmenu {
  display: none;
}
