.commands-help {
  max-height: 400px;
  padding: 0;
  margin: 0;
  list-style-type: none;

  &__item,
  & li {
    padding: 0.5rem 0.625rem;
    color: var(--brand-primary);
    font-size: var(--font-size-md);
    line-height: 1.2;

    &:hover,
    &:active {
      color: #fff;
      background: var(--brand-highlight);
    }
  }
}

.twtt-session-server {
  display: flex;
  text-align: center;
  font-size: 0.75rem;
}
