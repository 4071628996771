.loading-done .main-loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: visible;
}

.loading-done .main-loader__blind {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 7000;
  background: var(--bg-overlay);
}

.loading-done .main-loader__header {
  position: relative;
  z-index: 6998;
  border-bottom: 1px solid #858585;
  width: 100%;
}

.loading-done .main-loader__text {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--font-size-m);
  color: #ffffff;
}

.loading-done .main-loader__close {
  position: absolute;
  top: calc(50% - 0.625rem);
  right: 0.75rem;
}

.loading-done .app-mode-full .main-loader {
  left: 0;
}

.loading-done .app-mode-twd {
  max-width: 1920px;
}

.loading-done .app-mode-twd .main-loader,
.loading-done .app-mode-twd.page_side-bars:not(.app-mode-full) .main-loader {
  max-width: none;
}

.loading-done .app-mode-twd .main-loader__header,
.loading-done .app-mode-twd.page_side-bars:not(.app-mode-full) .main-loader__header {
  max-width: 1920px;
  margin-right: auto;
  margin-left: auto;
}

.loading-done .app-mode-user {
  max-width: 1600px;
}

.loading-done .progress-bar {
  /* Styles for progress bar <div> */
  position: relative;
  width: 100%;
  height: 4.4rem;
  background: var(--color-light-extra);
  /* Styles for <progress> element */
}

.loading-done .progress-bar .loading-spinner_brand {
  display: none;
}

.loading-done .progress-bar__loader {
  display: block;
  height: 4.4rem;
  background: var(--brand-highlight);
  animation-name: loader;
  animation-duration: 60s;
  animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.loading-done .progress-bar__loader-percent {
  animation: none;
}

.loading-done .progress-bar__text {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--font-size-m);
  color: #ffffff;
}

.loading-done .progress-bar[value] {
  position: relative;
  display: block;
  width: 100%;
  height: 3.125rem;
  color: var(--brand-highlight);
  background: var(--color-light-extra);
  border: none;
  appearance: none;
}

.loading-done .progress-bar[value]::-webkit-progress-bar {
  background: var(--color-light-extra);
}

.loading-done .progress-bar[value]::-webkit-progress-value {
  background: var(--brand-highlight);
}

.loading-done .progress-bar[value]::-moz-progress-bar {
  background: var(--brand-highlight);
}

.loading-done .progress-bar_fallback {
  width: 100%;
  height: 3.125rem;
  background: var(--color-light-extra);
}

.loading-done .progress-bar_fallback > span {
  display: block;
  height: 3.125rem;
  background: var(--brand-highlight);
}

@keyframes loader {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.loading-done .main-loader__spinner {
  position: absolute;
  top: calc(50% - 2.5rem);
  left: calc(50% - 2.5rem);
  z-index: 7001;
  display: inline-block;
  width: 5rem;
  height: 5rem;
  animation: loader-spin 1s infinite steps(8);
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

.loading-done .spinner {
  position: relative;
  width: 3.125rem;
  height: 3.125rem;
  margin: 0.3125rem auto;
}

.loading-done .spinner__preview-result-panel {
  max-width: 18.75rem;
  margin-top: 6.25rem;
  padding: 1.25rem 2.5rem;
  background: #ffffff;
  border-radius: 0.5625rem;
}
