/* Smartsearch Styles */

@import './config.cssnext';
@import './buttons.cssnext';
@import './smart-input.cssnext';
@import './suggestions.cssnext';

/* reset bad CSS */
.ss__suggestions {
  span.twt {
    font-size: inherit;
    line-height: inherit;
    min-width: initial;
    min-height: initial;
    display: inline;
    position: static;
  }
}

.ss__container .ss__suggestions {
    text-transform: none;
}


/* FIX: point choice */
/* FIX: tourist airport choice */
.xxx-simple-smartinput,
.flt-point,
.sf_main-flights,
.sf_main-tourists {
  .ss__suggestions__command-switcher,
  .ss__smart-input__btn-favorites {
    display: none !important;
  }

  .ss__suggestions {
    position: absolute;
    z-index: 10;
    top: calc(100% + 0.25rem);

    .ss__suggestions__node-pointer {
      height: 10px;
      border-radius: 5px;

      .ss__suggestions__node-pointer__arrow {
          left: 3em !important;
      }
    }

    .ss__suggestions__main {
      overflow-x: hidden;
      margin-bottom: var(--ss-indent-base);
      border: 2px solid #ccc;
      border-top: 2px solid transparent;
      border-radius: 5px;

      .ss__suggestions__list {
        padding: var(--ss-indent-base) 0 0 0;

        .ss__suggestions__list__items {
          padding: 0 var(--ss-indent-base);
        }

        .ss__suggestions__list__item {
          width: 100% !important;

          &:last-child {
            margin-bottom: var(--ss-indent-base);
          }
        }
      }
    }

    .ss__suggestions__toggle {
      display: none !important;
    }
  }

  .input__control:not(:focus) ~ .ss__suggestions {
    display: none !important;
  }
}
