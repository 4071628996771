@import '../../tais-styles/styles/utils/variables.cssnext';

:root {
  /* fonts */
  --ss-font-size-base: 0.875rem; /* 14px */
  --ss-font-size-small: 0.75rem; /* 12px */

  /* typography */
  --ss-indent-base: 0.625rem;
  --ss-indent-base-half: calc(var(--ss-indent-base) * 0.5);
  --ss-indent-base-x2: calc(var(--ss-indent-base) * 2);

  /* colors */
  --ss-color-danger: #c9302c;
  --ss-tab-color: #2a94fe;
  --selection-bg: #3297fd;

  /* smart-input */
  --ss__smart-input_node-indent: 0.3125rem;
}
