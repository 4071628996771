.badge__empty {
  font-size: 18px;
  text-transform: uppercase;
  color: #666;
  font-weight: bold;
  padding: 40px 100px;
  border-radius: 9px;
  background: #fff;
  margin: auto;

  .page__body_history & {
    color: rgb(246, 183, 60);
  }

  .page__body_history .pnr-snaphots-comparator_comp-col & {
    color: #666;
  }
}
