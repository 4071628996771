/*
* --------------------------------------------------
* This file contains all styles for a block with content that
* changes on click
* --------------------------------------------------
*/

.tgl-content {

  &__active {
    display: none;
  }

  &__incative {
    display: initial;
  }

  &_active &__active {
    display: initial;
  }

  &_active &__inactive {
    display: none;
  }
}
