/**
* MAIN QUERY FIELD
*/

.form-field {
  display: block;
  width: 100%;
  height: 1.8125rem;
  padding: 0.2rem 0.625rem;

  outline: none;
  outline-offset: 0;

  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;

  color: var(--brand-primary);
  border: 2px solid var(--color-disabled);
  border-radius: var(--border-radius);
  background-color: #fff;
  background-image: none;

  font-family: inherit;
  font-size: var(--font-size-md);
  line-height: 1.2;

  &.focus,
  &:focus {
    border-width: 2px;
  }

  &::-webkit-input-placeholder {
    color: var(--brand-secondary);
    font-size: var(--font-size-s);
    line-height: 1.66;
  }
  &:-moz-placeholder {
    opacity: 1;
    color: var(--brand-secondary);
    font-size: var(--font-size-s);
    line-height: 1.66;
  }
  &::-moz-placeholder {
    opacity: 1;
    color: var(--brand-secondary);
    font-size: var(--font-size-s);
    line-height: 1.66;
  }
  &:-ms-input-placeholder {
    color: var(--brand-secondary);
    font-size: var(--font-size-s);
    line-height: 1.66;
  }

  &_active {
    border-color: var(--brand-highlight);
    box-shadow: 0 0 8px 2px var(--brand-highlight);
  }
}

div.form-field,
textarea.form-field {
  height: auto;
  min-height: 1.8125rem;
  line-height: 1.4;
  text-transform: uppercase;
}

textarea.form-field-sql {
  text-transform: none;
}

.terminal-query {
  margin-top: 0.5rem;
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 0.85rem - var(--stack-panel-toggle-width));
  padding: 0 0 0.625rem 0;

  &__field {
  /* Terminal query input (textarea) */
    min-width: 100%;
    padding: 0.75rem 8.5rem 0.6875rem 0.625rem;
    resize: vertical;
  }

  &__area {
    overflow: hidden;
  }

  &__btn {
  /* Terminal query submit */
    position: absolute;
    top: 0;
    right: 0.3125rem;
    padding: 0;
    display: block;
    cursor: pointer;
  }
}

textarea.terminal-query__area {
  max-height: 12rem;
  line-height: 2;
}

div.form-field.ss__container {
  padding: 0;
}
