/*
* --------------------------------------------------
* This file contains all styles for a carousel.
* --------------------------------------------------
*/

.carousel__items {
  display: block;
}

.carousel__item {
  display: inline-block;
  width: auto;
  padding: 0;
  margin: 0;
}

.carousel__paddles {
  display: none;
}

.carousel_active {
  height: 3.75rem;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 100%;

  & .carousel__wrapper {
    width: 100%;
    position: relative;
  }

  & .carousel__items {
    height: 5rem;
    list-style: none;
    padding: 0 2rem 1.25rem;
    overflow-x: auto;
    overflow-y: hidden;
    flex-wrap: nowrap;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
  }

  & .carousel__item:first-child {
    margin-left: 0;
  }

  & .carousel__item:last-child {
    margin-right: 2rem;
  }

  & .carousel__paddles {
    display: block;
  }

  & .carousel__paddle {
    background: none;
    border: none;
    border-radius: 0;
    padding-bottom: 1.25rem;
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    text-align: center;
    -webkit-transition: opacity 150ms ease-out;
    -moz-transition: opacity 150ms ease-out;
    transition: opacity 150ms ease-out 0s;
    width: 2rem;
  }

  & .carousel__paddle:disabled {
    opacity: 0;
    cursor: default;
  }

  & .carousel__paddle::after {
    border-top: 2px solid #fff;
    display: inline-block;
    width: 1.25rem;
    height: 1.25rem;
    content: '';

    transform-origin: 50% 50% 0;
  }

  & .carousel__paddle_left {
    background-image: linear-gradient(to right, var(--color-lighter) 70%, var(--body-bg-transparent) 100%);
    left: 0;
  }

  .screen-sect & .carousel__paddle_left {
    background-image: linear-gradient(to right, #eee 70%, var(--body-bg-transparent) 100%);
  }

  & .carousel__paddle_left::after {
    border-left: 2px solid #fff;
    transform: rotate(-45deg);
  }

  & .carousel__paddle_right {
    background-image: linear-gradient(to left, var(--color-lighter) 70%, var(--body-bg-transparent) 100%);
    right: 0;
  }

  .screen-sect & .carousel__paddle_right {
    background-image: linear-gradient(to left, #eee 70%, var(--body-bg-transparent) 100%);
  }

  & .carousel__paddle_right::after {
    border-right: 2px solid #fff;
    transform: rotate(45deg);
  }
}
