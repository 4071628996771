.versions-box {
  --box-bg: #dcdcdc;
  --box-text-color: var(--color-dark);
}

.server-color-scheme_test .versions-box {
  --box-bg: #4ece47;
  --box-text-color: #ffffff;
}

.server-color-scheme_archive .versions-box {
  --box-bg: #666666;
  --box-text-color: #ffffff;
}

.server-color-scheme_test.branding-host-s7u .versions-box {
  --box-bg: #7a85d3;
  --box-text-color: #ffffff;
}

.versions-box {
  background-color: var(--box-bg);
  padding: 0.063rem 0.75rem;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: var(--z-top);
  min-width: 40rem;
  width: 100%;
  display: flex;
  justify-content: center;
}

.versions-box .rpc-connection-type {
  margin-right: 0.313rem;
  text-transform: uppercase;
}

.versions-box .ls-rpc {
  color: orangered;
}

.versions-box__logo {
  padding-left: 2.5rem;
  margin: 0 0.313rem 0 0.25rem;
  background-image: url('tais-styles/svg/ors-logo-new.svg');
  background-repeat: no-repeat;
  background-position: 0 -5px;
  background-size: 1.938rem 0.938rem;

  &:before {
    content: 'Powered by ORS';
    display: inline-block;
  }
}

.versions-box__data {
  position: relative;
  font-size: var(--font-size-xxs-extra);
  color: var(--box-text-color);
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-family: Verdana, sans-serif;
  line-height: 1;
}

.versions-box__data-client,
.versions-box__data-rpc,
.versions-box__data-mkv {
  white-space: nowrap;
}

.versions-box__data-client {
  position: relative;
  padding: 0 2px;
}

.versions-box .feature-change-notification {
  position: absolute;
  font-size: 0.5rem;
  top: -8px;
  right: -8px;
  padding: 0.5px 3px;
  border-radius: 50%;
  background: orange;
}

.versions-box__data-rpc,
.versions-box__data-mkv,
.versions-box__data-memory,
.versions-box__data-beta,
.versions-box__data-language,
.versions-box__data-current-time {
  display: inline-flex;
  align-items: center;
}

.versions-box__data-rpc:before,
.versions-box__data-mkv:before,
.versions-box__data-memory:before,
.versions-box__data-beta:before,
.versions-box__data-language:before,
.versions-box__data-current-time:before {
  content: '\00b7'; /*·*/
  display: inline-block;
  margin: 0 0.313rem 0.125rem;
  font-size: 1.063rem;
  line-height: 1.063rem;
}

.versions-box__data-rpc:empty:before,
.versions-box__data-mkv:empty:before,
.versions-box__data-memory:empty:before,
.versions-box__data-beta:empty:before,
.versions-box__data-language:empty:before,
.versions-box__data-current-time:empty:before {
  display: none;
}

.versions-box_side_right {
  position: fixed;
  left: auto;
  right: 0;
  transform: none;
  padding: 0 0.188rem 0.188rem 0;
}

.versions-box_side_right .versions-box__data {
  display: block;
  text-transform: none;
  font-family: inherit;
  font-size: var(--font-size-xxs);
  line-height: var(--font-size-xxs);
}

.versions-box_side_right .versions-box__data-rpc {
  min-width: 0;
  display: block;
}

.versions-box_side_right .versions-box__data-rpc:before {
  display: none;
}

.versions-box__data-server:empty {
  display: none;
}

.versions-box__data-server {
  border-radius: 3px;
  overflow: hidden;
  margin-left: 1.25rem;
  order: 2;
}

.versions-box__data-server span {
  padding: 0.1875rem 0.3125rem 0.0625rem;
  background: var(--box-text-color);
  color: var(--box-bg);
  position: relative;
  text-transform: uppercase;
  font-weight: 700;
}

.versions-box__data-server span:not(:last-child)::after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 0.0625rem;
  background: var(--box-bg);
}

.rpc-debug-dialog .ui-dialog-title {
  margin: -4px 0 14px 0 !important;
}

.rpc-debug-dialog__content {
  padding: 40px 0 36px 0;
  background-color: #ddd;
}

.rpc-debug-dialog .ui-dialog-buttonset {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.rpc-debug-dialog__input {
  width: 328px;
  padding: 5px;
  font-size: 14px !important;
  font-family: var(--font-family-monospace) !important;
}

.rpc-debug-dialog__btn {
  text-transform: none !important;
  float: none;
}

.rpc-debug-dialog__help {
  padding-top: 5px;
  color: #999;
}

.ui-widget.rpc-commands-dialog {
  z-index: var(--z-top);
  max-height: 80vh;
  padding: 1.25rem;
  border: none;
  flex-direction: column;
}

.ui-widget.rpc-commands-dialog .ui-dialog-titlebar {
  text-transform: uppercase;
  margin-top: 0;
  padding: 0;
  margin-bottom: 0.625rem;
  font-size: 1rem;
}

.ui-widget.rpc-commands-dialog .ui-dialog-content {
  padding: 0;
  overflow: hidden;
  flex-direction: column;
}

.ui-widget.rpc-commands-dialog .ui-dialog-buttonpane {
  padding: 1.875rem 0 0;
}

.ui-widget.rpc-commands-dialog .ui-dialog-buttonpane .ui-button {
  float: none;
  height: auto;
  background: var(--brand-highlight);
  color: #fff;
  font-size: 0.813rem;
  font-weight: bold;
  margin: 0;
}

.ui-widget.rpc-commands-dialog .ui-dialog-buttonpane .ui-button:hover {
  background: var(--brand-highlight-sec);
  border-color: var(--brand-highlight-sec);
}

@media print {
  .versions-box {
    display: none;
  }
}

@media (max-height: 700px) {
  .ui-widget.rpc-commands-dialog {
    max-height: 96vh;
  }
}
