.documentation-view {
  padding-right: 3rem;
}

.documentation-view {
  .btn-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    margin: 0 !important;
    height: 2rem !important;
    width: 2rem !important;
    min-width: auto !important;
    background: transparent !important;
    border: none !important;
  }
}

.documentation {
  text-align: initial;
  white-space: normal;

  &__item:not(:last-child) {
    margin: 0 0 1rem 0;
  }

  &__item-link {
    display: flex;
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-decoration: none;
    color: #000;
    border: 3px solid var(--brand-highlight);
    border-radius: var(--border-radius-lg);
    background-color: #fff;
  }

  &__item-icon {
    flex-shrink: 0;
    margin-left: 1rem;
    margin-right: 1rem;
    z-index: 2;
    width: 3.125rem;
    height: 3.125rem;
    margin-top: 0;
    top: 1.875rem;
  }

  &__item-content {
    font-weight:bold;
    font-family:'Arial', 'Helvetica', sans-serif;
    font-size:1.5rem;
  }

  &__not-found {
    font-weight: bold;
    font-family: 'Arial', 'Helvetica', sans-serif;
    font-size: 1.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-align: center;
    text-decoration: none;
    color: var(--brand-secondary);
    background-color: #fff;
  }
}
