.dropdown.dropdown_fused {
  display: inline-block;

  .dropdown__switcher {
    min-width: 10rem;
    margin-top: 0;
    user-select: none;

    &:after {
      content: '\203A';
      position: absolute;
      transform: rotate(90deg);
      top: 50%;
      right: .5em;
      left: auto;
      margin-top: -.55em;
      width: .75em;
      height: 1em;
      line-height: 1.125em;
      border: 0;
      font-size: 2em;
      font-weight: normal;
      font-family: monospace;
    }
  }

  .dropdown__body {
    flex-flow: row wrap;
    padding: 0;
    overflow: hidden;
    right: 0;
    left: 0;
    bottom: 100%;

    & > .btn,
    & > * {
      width: 100%;
      margin: 0;
      border: 0;
      padding-left: 0;
      padding-right: 0;
      border-radius: 0;
    }
  }

  &.dropdown_active {
    .dropdown__switcher {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-top-color: transparent;
      background: #fff;

      &:hover {
        background: var(--brand-highlight);
      }

      &:after {
        transform: rotate(-90deg);
      }
    }

    .dropdown__body {
      margin-bottom: calc(var(--btn-border-width) * -1);
      border: var(--btn-border-width) solid var(--brand-secondary);
      border-radius: var(--btn-border-radius);
      border-color: var(--brand-highlight);
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      border-bottom: 0;
      z-index: calc(var(--z-popup) + 50);
    }
  }

  &.dropdown--reversed .dropdown__body {
    flex-wrap: wrap-reverse;
  }
}
