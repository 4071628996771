.list {
  &-header {
    display: flex;
    justify-content: space-between;
  }

  &__body {

    .list-list {
      padding: 10px;

      .fdei-enum {
        text-transform: uppercase;
        font-size: 14px;
      }
    }

    .list-list:only-child {
      margin-bottom: 0;
    }

    .list-item {
      margin: 0 0 10px;

      .screen_dei-list & {
        padding: 10px;

        .panel__control {
          width: auto;
        }

        .popover__switcher {
          margin-right: 10px;
          padding: 0;
        }

        .popover__body {
          top: -10px;
          left: -10px;
          padding: 7px 10px 8px 75px;
          text-transform: uppercase;
        }

        .popover__icon {
          margin: 0 16px 0 0;
          font-size: 14px;
        }
      }

      &__name {
        text-transform: uppercase;
        font-size: 15px;

      }
    }
  }

  &__empty {
    justify-content: center;

      & .panel {
        padding: 2.5rem 6.25rem;
        font-size: 1.1rem;
      }
  }
}
