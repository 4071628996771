/*
* --------------------------------------------------
* This file contains all styles for a pop-up windows.
* --------------------------------------------------
*/

/**
* POP-up menu with sandwich control
* ex: choose seats */
.popup {
  position: absolute;
  left: 0;

  display: none;
  max-width: 100%;
  padding: 0.5rem 1.0625rem 0;

  &_active {
    z-index: var(--z-popup);
    display: block;
  }

  &__pointer {
    position: absolute;
    top: 0;
    left: calc(3rem + 1.0625rem);

    display: block;
    width: 0;
    height: 0;
    margin-left: -0.5rem;

    border-right: 0.5rem solid transparent;
    border-bottom: 0.5rem solid #fff;
    border-left: 0.5rem solid transparent;
  }

  &__inner {
    min-height: 3.75rem;
    padding: 1.25rem;
    background: #fff;
    border-radius: var(--border-radius-lg);
  }

  &__scroller {
    overflow-x: hidden;
    overflow-y: auto;

    min-height: 3.75rem;
    margin-right: 2rem;
  }

  &__title {
    align-self: flex-start;
    padding-right: 1.5rem;
    margin: 0;

    font-size: var(--font-size-lg);
    font-weight: bold;
    white-space: nowrap;

    color: var(--brand-primary);
  }

  &__close {
    position: absolute;
    top: calc(0.5rem + 1.25rem);
    right: calc(1.0625rem + 1.25rem);
  }
}
