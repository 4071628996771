/**
* SEARCH HISTORY
* drop-down and control
*/

.search-history {
  position: relative;
  z-index: var(--z-search-history);

  &__wrap {
    position: relative;
  }
  &__switcher {
  /* Search history open/close control */
    position: absolute;
    top: 0;
    right: 1.65rem;

    overflow-x: hidden;
    overflow-y: auto;

    display: block;
    padding: 0.6875rem 0.2375rem 0.6rem;
    max-height: 80vh;

    cursor: pointer;
  }
  &__list {
  /* Search history drop-down */
    position: absolute;
    top: 2px;
    left: 0;

    display: block;
    width: 100%;
    padding: 0.25rem 0;
    margin: 0;

    list-style: none;

    border: 1px solid var(--color-disabled);
    border-radius: var(--border-radius);
    background: #fff;

    transition: transform 0.2s ease;
    transform-origin: 50% 0 0;
    transform: scaleY(0);

    &_active,
    .search-history_active & {
    /* Opened search history drop-down */
      transform: scaleY(1);
    }
  }
  &__item {
  /* One element of search history */
    padding: 0.5rem 0.625rem;

    color: var(--brand-primary);
    font-size: var(--font-size-md);
    line-height: 1.2;
    text-transform: uppercase;

    &:hover,
    &:active,
    &_active {
    /* Element of search history on hover */
      color: #fff;
      background: var(--brand-highlight);
    }

    & > a,
    & > span {
      display: block;
      color: inherit;
    }
  }
}

/* Search history open/close control icon */
.i-search-history-caret {
  &__triangle {
    position: relative;
    display: block;
    width: 0.75rem;
    height: 0.5rem;

    overflow: hidden;

    &:before {
    /* Triangle */
      position: absolute;
      top: -0.25rem;
      left: 0.0625rem;

      display: block;
      width: 0.625rem;
      height: 0.625rem;

      transform: rotate(45deg);

      content: '';
      box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.5);
      background: #e8e8e8;
    }
    &:after {
    /* Top shadow for the triangle */
      position: absolute;
      top: -2px;
      right: 0;
      left: 0;

      display: block;
      height: 1px;

      content: '';

      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
    }
  }

  /* Glow arround the triangle in active state */
  &:hover:before,
  &:active:before,
  &_active:before,
  .search-history_active  &:before {
    position: absolute;
    top: 50%;
    left: 50%;

    display: block;
    height: 2px;
    width: 2px;
    margin-top: -2px;
    margin-left: -1px;

    content: '';

    border-radius: 50%;
    box-shadow: 0 0 5px 5px rgba(29, 145, 230, 0.2);
    background: rgba(29, 145, 230, 0.2);
  }

  /* Triangle on hover */
  &:hover &__triangle:before,
  &:active &__triangle:before,
  &_active &__triangle:before,
  .search-history_active  &__triangle:before {
    background-color: #1e96e8;
  }
}
