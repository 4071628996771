.find_flight_dropdown {
  .dropdown__body {
    background-color: #ddd;
    flex-direction: column;
    min-width: 740px;
    min-height: 90vh;
    max-height: 100vh;
    bottom: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
    position: fixed;

    &.row {
      flex-direction: row;
      justify-content: space-between;
    }

    .dropdown__side_column {
      width: 300px;
      height: 100%;
      padding: 25px 15px;
      font-size: var(--font-size-m);
      flex-shrink: 0;
      overflow: auto;
    }

    .dropdown__filters_column {
      border-top-left-radius: 9px;
      border-bottom-left-radius: 9px;
      background-color: #fff;

      .filter-header {
        font-weight: bold;
        margin: 0 0 10px;
        text-transform: uppercase;
        color: #666;
        font-size: 14px;
        width: 100%;
        text-align: center;
      }

      .filters_datepicker {
        margin-right: 30px;
        text-align: end;
      }

      .filters_datepicker > * {
        margin-bottom: 20px;
      }

      .days-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      & .days-container > * {
        margin-bottom: 20px;
      }
    }

    .dropdown__filters_form {
      text-align: center;
    }

    .dropdown__filters_form > * {
      margin-bottom: 20px;
    }

    .dropdown__filters_form input {
      font-size: 14px;
    }

    .dropdown__filters_form .days-picker {
      text-align: left;
    }

    .dropdown__filters_form .days-picker .check {
      margin-right: 0.4rem;
    }

    .dropdown__filters_form .days-picker .check__box {
      margin-right: 0.1rem;
    }

    .dropdown__filters_form .route-condition__wrap {
      margin-bottom: 0;
    }

    .dropdown__filters_form .route-condition__wrap .desc-title {
      margin-right: 10px;
    }

    .dropdown__filters_form .route-condition__wrap .input {
      width: 60px;
    }

    .dropdown__filters_form .title__block_last {
      width: 100%;
    }

    .dropdown__filters_form .route_filter > * {
      margin-bottom: 10px;
    }

    .dropdown__filters_form .route_filter_section {
      position: relative;
      margin-left: 70px;
    }

    .dropdown__filters_form .route_filter_section__inputs {
      text-align: left;
    }

    .dropdown__filters_form .route_filter_section .desc-title {
      position: absolute;
      left: -10px;
      top: 50%;
      transform: translate(-100%, -50%);
      text-align: right;
    }

    .dropdown__filters_form .filters_form_parameters_changed {
      color: var(--brand-warning);
      font-size: 10px;
      font-weight: bold;
      text-transform: uppercase;
    }

    .dropdown__calendar_column {
      border-top-right-radius: 9px;
      border-bottom-right-radius: 9px;
      padding-bottom: 140px;
    }

    .dropdown__calendar_column .legend__item {
      margin: 5px 0px;
    }

    .dropdown__calendar_column .legend__item-icon.other-rule {
      background-color: #6aa36a;
    }

    .dropdown__calendar_column .legend__item-icon.allow {
      background-color: var(--brand-highlight-sec);
    }

    .dropdown__calendar_column .legend__item-icon.has-flight {
      background-color: #f3f3a7;
    }

    .dropdown__calendar_column .legend__item-icon.bad-status {
      background-color: var(--brand-warning);
    }

    .dropdown__calendar_column .inline-calendar__dates {
      text-align: center;
    }

    .dropdown__calendar_column
    .inline-calendar__dates
    .ui-datepicker.ui-datepicker-multi {
      flex-direction: column;
    }

    .dropdown__calendar_column
    .ui-datepicker-header {
      border-radius: 0px;
    }

    .dropdown__calendar_column
    .ui-datepicker-group {
      margin-bottom: 10px;
    }

    .dropdown__calendar_column
    .ui-datepicker-group.ui-datepicker-group-last {
      margin-bottom: 0;
    }

    .dropdown__calendar_column
    td:not(.ui-state-disabled)
    .ui-state-default:not(.ui-state-hover) {
      color: #777;
    }

    .dropdown__calendar_column
    .other-rule:not(.ui-state-disabled)
    .ui-state-default:not(.ui-state-hover) {
      background-color: #86e5a5;
      color: var(--color-green);
    }

    .dropdown__calendar_column
    .has-flight:not(.ui-state-disabled)
    .ui-state-default:not(.ui-state-hover) {
      border-color: #f3f3a7;
      background-color: #f3f3a7;
      color: #888;
    }

    .dropdown__calendar_column
    .allow:not(.ui-state-disabled)
    .ui-state-default:not(.ui-state-hover) {
      background-color: #cce6fc;
      color: var(--brand-highlight-sec);
    }

    .dropdown__calendar_column
    .bad-status:not(.ui-state-disabled)
    .ui-state-default:not(.ui-state-hover) {
      background-color: #fb9c9c;
      color: var(--brand-warning);
    }

    .dropdown__calendar_column
    .active-date-edge:not(.ui-state-disabled)
    .ui-state-default:not(.ui-state-hover) {
      border-color: var(--color-dblue);
    }

    .dropdown__calendar_column
    .active-date:not(.ui-state-disabled)
    .ui-state-default:not(.ui-state-hover) {
      border-color: var(--brand-highlight);
    }

    .dropdown__calendar_column
    .ui-datepicker-calendar
    .ui-state-active {
      background: #eeeeee;
    }

    .dropdown__calendar_column .inline-calendar_controls {
      position: absolute;
      bottom: 0;
      right: 20px;
      width: 270px;
      height: 140px;
      background-color: #ddd;
    }

    .dropdown__calendar_column .inline-calendar_controls__select_options {
      display: flex;
      justify-content: space-between;
      padding: 20px 10px;
    }

    .dropdown__calendar_column .select-option {
      font-size: 0.875rem;
    }

    .dropdown__calendar_column .inline-calendar__submit {
      width: 100%;
      font-size: 0.875rem;
    }

    .inline-calendar_controls__warning {
      margin-top: 10px;
      color: var(--brand-warning);
      text-align: center;
      text-transform: uppercase;
      font-size: 0.75rem;
      font-weight: bold;
    }

    .dropdown__flights_list {
      width: 100%;
    }

    .dropdown__flights_list .flight_list_header {
      height: 90px;
    }

    .dropdown__flights_list .flights_exist {
      height: 100%;
      width: calc(100% - 5px);
      margin: auto;
    }

    .dropdown__flights_list .flight_list_title {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: var(--font-size-root);
      font-weight: bold;
      text-transform: uppercase;
      color: var(--brand-secondary);
    }

    .dropdown__flights_list .no_flights {
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }

    .flight_list_title__arrow {
      font-size: 2rem;
      font-weight: bold;
    }

    .flights_list__day_select_arrow {
      display: inline-block;
      position: relative;
      width: 42px;
      height: 22px;
      padding-left: 10px;
      padding-right: 10px;
      color: var(--brand-highlight);
      cursor: pointer;
      user-select: none;
    }

    .flights_list__day_select_arrow:hover {
      color: var(--brand-highlight-sec);
    }

    .flights_list__day_select_arrow.disabled {
      opacity: 0.5;
      cursor: unset;
    }

    .flights_list__day_select_icon {
      display: block;
      width: 22px;
      height: 21px;
      background-color: currentColor;
    }

    .flights_list__day_select_icon_prev {
      mask: url('tais-styles/svg/arrow-pagination-prev.svg') center no-repeat;
    }

    .flights_list__day_select_icon_next {
      mask: url('tais-styles/svg/arrow-pagination-next.svg') center no-repeat;
    }

    .flight_list__tooltip-count,
    .flight_list__tooltip-date {
      text-align: center;
    }

    .flight_list__tooltip-count {
      font-weight: 400;
    }

    .flight_list__tooltip-date {
      font-size: 0.875rem;
    }

    .flights_list_items {
      padding: 20px;
      list-style: none;
      height: calc(100% - 90px);
      overflow: auto;
    }

    .flights_list_item {
      position: relative;
      border-radius: 12px;
      margin-bottom: 12px;
      padding: 5px 0px;
      background-color: #fff;
      cursor: pointer;
    }

    .flights_list_item:hover {
      background-color: #f5f5f5;
    }

    .flights_list_item.selected {
      box-shadow: inset 0 0 0 0.1875rem var(--brand-highlight);
    }

    .flights_list_item.unselectable {
      box-shadow: inset 0 0 0 0.1rem red;
      opacity: 0.5;
      cursor: unset;
    }

    .flights_list_item__original_flight {
      position: absolute;
      left: 50%;
      bottom: 4px;
      transform: translateX(-50%);
      color: var(--brand-warning);
      text-transform: uppercase;
    }

    .flights_list_item_part {
      display: flex;
      height: 76px;
      justify-content: space-between;
      padding: 5px 10px;
    }

    .flights_list_item_part > * {
      margin: auto 0;
      text-align: center;
    }

    .flights_list_item_part .flights_list_item_part__flight_number {
      font-size: var(--font-size-md);
      font-weight: bold;
    }

    .flights_list_item_part__flight_number {
      display: flex;
    }

    .flights_list_item_part__flight_inner {
      margin-left: 3px;
      width: 40px;
      position: relative;
    }

    .flights_list_item_part__flight_codeshare {
      font-size: 0.5rem;
      left: 0;
      position: absolute;
      top: 0;
    }

    .flights_list_item_part__flight_franchise {
      background-color: #9A9A9A;
      color: white;
      font-size: 0.5rem;
      border-radius: 5px;
      padding: 2px 4px 1px 4px;
      left: 0;
      position: absolute;
      bottom: 0;
    }

    .flights_list_item_part__date_day {
      font-size: var(--font-size-lg);
      font-weight: bold;
      line-height: 1;
    }

    .flights_list_item_part__date_month {
      font-weight: bold;
      line-height: 1.3;
      text-transform: uppercase;
    }

    .flights_list_item_part__date_weekday {
      color: var(--brand-secondary);
      font-size: var(--font-size-xxs);
      line-height: 1;
      text-transform: uppercase;
    }

    .flights_list_item_part__time {
      font-size: var(--font-size-lg);
      font-weight: bold;
      line-height: 0.8;
    }

    .flights_list_item_part__duration {
      min-width: 64px;
      color: var(--brand-secondary);
    }

    .flights_list_item_part__duration_time {
      margin-top: 7px;
    }

    .flights_list_item_part__badge {
      display: flex;
      margin-bottom: 5px;
      border: 1px solid var(--color-light);
      border-radius: var(--border-radius-tag);
      font-size: 14px;
      line-height: 20px;
      text-transform: uppercase;
      font-weight: bold;
    }

    .flights_list_item_part__badge_name {
      padding: 0 0.3rem;
      width: 55%;
      background-color: var(--color-light);
      color: #fff;
    }

    .flights_list_item_part__badge_value {
      padding: 0 0.3rem;
      color: var(--color-light);
    }

    .flights_list_item_separator {
      display: flex;
      width: 100%;
      height: 16px;
    }

    .flights_list_item_separator__line {
      display: block;
      height: 1px;
      border: 0;
      border-top: 1px solid var(--color-light);
      margin: 12px 0;
      padding: 0;
    }

    .flights_list_item_separator__line.left {
      width: 10%;
      flex-shrink: 0;
      flex-grow: 0;
    }

    .flights_list_item_separator__time_between,
    .flights_list_item_separator__time_total {
      margin: 3px 8px;
      flex-shrink: 0;
      color: var(--color-light);
    }

    .flights_list_item_separator__line.center {
      width: 50%;
      flex-shrink: 1;
      flex-grow: 1;
    }

    .flights_list_item_separator__line.right {
      width: 10%;
      flex-shrink: 0;
      flex-grow: 0;
    }

    .dropdown__close {
      position: absolute;
      align-self: center;
      margin-right: 5px;
      right: 10px;
      top: 6px;
      width: 20px;
      height: 20px;
    }

    .dropdown_wide& {
      width: 1420px;
      max-width: 100vw;

      .choose_flight {
        padding: 0 25px;
        transition: border-color .2s, color .2s, background-color .2s;
      }

      .title {
        min-height: 73px;

        .route-condition {
          display: inline-flex;
          position: relative;
          align-items: center;
          line-height: 20px;

          .input {
            min-width: 0;
          }

          .input__control {
            max-width: 42px;
          }

          .input__control::-webkit-input-placeholder {
            font-size: 10px;
            text-transform: uppercase;
          }

          .input__control::-moz-placeholder {
            font-size: 10px;
            text-transform: uppercase;
          }

          .desc-title {
            margin-left: 7px;
          }
        }

        .route-condition__wrap {
          min-width: 168px;
        }

        .route-condition__wrap .desc-title {
          margin-right: 10px;
        }

        .route-condition__wrap .input_inline {
          width: 60px;
        }

        .delete-route {
          display: inline-block;
          background-image: url(tais-styles/svg/minus-in-circle.svg);
          background-size: contain;
          background-repeat: no-repeat;
          width: 17px;
          height: 17px;
          cursor: pointer;
        }

        .add-route {
          display: inline-block;
          background-image: url(tais-styles/svg/plus-in-circle-highlight.svg);
          background-position: 0 50%;
          background-repeat: no-repeat;
          background-size: 17px;
          background-color: transparent;
          vertical-align: super;
          padding: 0 0 0 24px;
          cursor: pointer;
          text-transform: uppercase;
          color: var(--brand-highlight);
          font-size: 11px;
          line-height: 20px;

          &:hover {
            color: var(--brand-highlight-sec);
            background-image: url(tais-styles/svg/plus-in-circle.svg);
          }
        }
      }
    }

    .dropdown_route& {
      .title {
        padding-top: 10px;
        padding-bottom: 12px;
      }

      .av-route {
        padding: 0;
        margin-bottom: 10px;
      }

      .flights {
        display: flex;
        position: relative;


        .panel {
          height: 57px;

          .av-tag-group_wrapper {
            margin-left: 10px;
          }
        }

        .selected-line {
          position: relative;
          left: inherit;
          margin: 0 30px;
          flex-shrink: 0;

          &:before {
            left: calc(100% - 1px);
            right: auto;
            width: 33px;
          }

          &:after {
            left: auto;
            right: calc(100% - 1px);
            width: 33px;
          }
        }

        .selected-line_reversed {
          &:before {
            left: auto;
            right: calc(100% - 1px);
            width: 33px;
          }

          &:after {
            left: calc(100% - 1px);
            width: 33px;
          }
        }

        .msg-box {
          padding: 4px 7px;
          font-weight: bold;
          font-size: 13px;
        }

        .choose_flight {
          display: none;
          position: relative;
          margin-left: 5px;

          &:before {
            position: absolute;
            content: '';
            background-color: var(--brand-highlight);
            height: 3px;
            right: 100%;
            top: calc(50% - 1px);
            left: -10px;
            pointer-events: none;
          }
        }
      }
    }

    .ss__container_suggestions-closed {
      .ss__suggestions__toggle {
        display: none;
      }
    }

    .ss__suggestions__list__item {
      width: auto !important;
    }

    .ss__suggestions__node-pointer__arrow {
      left: 10px !important;
    }

    .ss__suggestions__node-pointer__arrow:after {
      bottom: calc(100% - 1px)
    }

    .ss__suggestions__toggle {
      top: calc(100% - 3px);
      z-index: 10;
    }

    .ss__suggestions__toggle:before {
      display: none;
    }

    .ss__suggestions__toggle:after {
      display: none;
    }

    .ss__suggestions__list {
      padding: 0 16px
    }

    .ss__suggestions__main {
      border: 2px solid #ccc;
    }

    .warning {
      position: absolute;
      white-space: nowrap;
      color: var(--brand-warning);
      font-style: normal;
      font-size: 10px;
      font-weight: bold;
      text-transform: uppercase;
    }

    .find_warning {
      font-size: 14px;
      bottom: -16px;
      right: 0;
    }

    .scroll {
      &_down,
      &_up {
        box-shadow: 2px 2px 0 #fff;
      }

      &_up {
        top: calc(100px + 5%);
      }

      &_active {
        border-color: var(--brand-highlight);
      }
    }

    .title {
      background-color: #fff;
      margin-bottom: 20px;
      padding: 14px;
      border-top-left-radius: 9px;
      border-top-right-radius: 9px;
      font-size: var(--font-size-m);
      min-height: 60px;
      flex-shrink: 0;

      &__block {
        white-space: nowrap;

        .filter-header {
          font-weight: bold;
          margin: 0 0 10px;
          text-transform: uppercase;
          color: #666;
          font-size: 14px;
          width: 100%;
        }

        &_last {
          background-color: #eee;
          margin-right: 6px;
          padding: 8px 10px 8px 40px;
          border-radius: 5px;
          display: inline-flex;
          flex-direction: column;
          justify-content: center;
          min-height: 78px;

          @media (min-width: 1598px) {
            margin-right: 16px;
          }
        }
      }

      &__block-dates-wrap {
        display: flex;
        align-items: center;
        margin-right: 6px;

        @media (min-width: 1610px) {
          margin-right: 16px;
        }
      }

      .panel {
        position: relative;
        align-items: center;
        padding: 0;
      }

      .ak {
        display: inline-block;
        margin: 0 8px 0 0;
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;

        @media (min-width: 1640px) {
          margin: 0 16px 0 0;
        }
      }

      .input {
        min-width: 72px;
        font-size: 14px;

        .warning {
          position: static;
        }
      }

      .desc-title {
        display: inline-block;
        font-size: 11px;
        font-weight: normal;
        margin: 0 0 0;
        text-transform: uppercase;
        color: var(--brand-highlight);
      }

      .datepicker {
        margin-right: 10px;
        width: auto;
      }

      .days-picker {
        display: flex;
        margin-top: 10px;
        margin-right: 10px;
        text-align: left;

        .check {
          margin-right: 8px;
        }

        .check__num {
          font-size: 11px;
        }

        .check:last-child {
          margin-right: 0;
        }

        .check__box {
          width: 22px;
          height: 22px;
          background-size: 13px;
          margin-right: 6px;
        }

        .check__text {
          font-size: 11px;
        }
      }

      .dropdown__close {
        position: absolute;
        align-self: center;
        margin-right: 5px;
        right: 10px;
        top: 6px;
        width: 20px;
        height: 20px;
      }

      .find_flight {
        &:disabled {
          background-color: #eee;
        }
      }
    }

    .days-container {
      margin-right: 0;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }

    .av-route {
      display: none;
      margin-top: 0;
    }

    .av-route_h {
      color: #666;
    }

    .flights {
      overflow: auto;
      flex-grow: 1;
      justify-content: flex-start;
      padding: 0 14px;

      &::-webkit-scrollbar {
        height: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--brand-highlight);
      }

      &&_calendar {
        position: relative;
        margin-bottom: 15px;
      }

      .panel {
        position: relative;
        align-items: center;
        cursor: pointer;
        padding: 0 8px;

        .flt_date {
          position: absolute;
          top: -30px;
        }

        &.flight_base .sale_status {
          color: var(--brand-highlight);
          text-transform: uppercase;
          font-size: 10px;
        }

        &.flight_unselectable {
          border: 1px solid rgba(255, 0, 0, .9);

          &.flight_bad {
            .sale_status {
              color: var(--brand-warning);
            }
          }

          &, & .badge {
            cursor: inherit;
          }

          .av-seats {
            cursor: inherit;
            color: inherit;

            &:before {
              display: none;
            }
          }

          .warning {
            display: block;
            top: calc(100% + 2px);
            left: 50%;
            transform: translateX(-50%);
            font-size: 10px;
            line-height: 11px;
          }
        }

        .badge {
          margin: 0 5px 0 0;
          width: 30px;
          height: 30px;
          background-color: #ccc;
          display: inline-flex;
          align-items: center;
          justify-content: center;
        }

        .badge:before {
          background-size: 20px;
          background-position: center center;
        }

        .badge span {
          font-size: 14px;
        }

        .segment__flight-info {
          padding: 0;
          flex-basis: auto;
        }

        .flt-flight-num {
          font-size: 14px;
          padding-right: 12px;
        }

        & .panel__control .badge {
          background-color: var(--brand-highlight);
        }

        .flt-flight-num > .flt-dow {
          left: auto;
          top: auto;
        }

        .segment__duration {
          width: 2.75rem;
        }

        .segment__departure, .segment__arrival {
          .flt-time {
            justify-content: center;
            white-space: nowrap;
            font-size: 17px;

            sup {
              position: absolute;
            }
          }

          .flt-point {
            font-weight: inherit;
          }
        }

        .av-tag_group {
          margin: 0;

          .av_sclass {
            background-color: #b0b0b0;
            color: #fff;
          }

          .tag {
            font-size: var(--font-size-m);
            line-height: 19px;

            &.av-tag:last-child {
              padding-left: 10px;
            }
          }
        }

        .sale_status {
          color: #666;
          font-weight: bold;
          font-size: 12px;
          width: auto;
          text-align: center;
          padding: 0 4px 0 0;
        }

        .warning {
          display: none;
        }

        .rule-selected {
          color: var(--brand-highlight);
          max-width: 100px;
          font-weight: bold;
          font-size: 10px;
          text-transform: uppercase;
          padding-left: 4px;
        }

        .av-tag-group_wrapper {
          margin-left: 0;
        }

        .av-tag-group_wrapper:first-child {
          margin-bottom: 3px;
        }
      }

      .panel__info-flex {
        padding: 6px 0;
        justify-content: space-between;
      }

      .empty {
        text-align: center;
        font-size: var(--font-size-root);
      }

      .av-seats {
        display: inline-flex;
        align-items: center;
      }

      .av-rbd-code__txt {
        white-space: nowrap;
      }

      .av-seats:not(.av-seats_disabled):not(.av-seats_selected):hover {
        color: #666;

        &:before {
          display: none;
        }
      }

      .av-seats:before {
        border-color: transparent;
      }

      .av-tag-group_wrapper {
        align-items: initial;
      }

      .segment__flight-info {
        flex-basis: 8%;
      }

      .segment__date {
        width: auto;

        .flt-day {
          font-size: 17px;
        }
      }

      .segment__departure {
        width: auto;
        padding: 0;
      }

      .segment__arrival {
        width: auto;
        padding: 0;
      }

      .segment__badges {
        width: auto;
        flex-grow: 0;
        padding: 0;
      }

      .segment__submit-wrap {
        text-align: right;
      }

      .select-flight {
        min-width: 112px;
        padding: 0 20px;
      }

      .flights_column:first-child {
        margin-right: 63px;
      }

      .flights_column {
        min-width: 600px;
      }
    }

    .flights_selected {
      .flights_column:first-child {
        margin-right: 0;
      }
    }

    .flights_calendar {
      min-width: 800px;
      max-width: 80%;
      margin: 0 auto;
    }

    .choose-block {
      position: relative;
      display: flex;
      flex-shrink: 0;
      justify-content: space-between;
      align-items: center;
      padding: 10px 16px;
      box-shadow: 0 -10px 10px 0 #ccc;
      min-height: 51px;

      &_empty {
        .choose-block__header {
          display: none;
        }

        .choose-block__buttons {
          display: none;
        }
      }

      &__header {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: bold;
        color: #666;
        display: block;
        margin: 0 0 10px;
      }

      .flt-flight-num {
        margin-right: 25px;
        display: inline-block;
      }

      &__wait {
        position: absolute;
        margin-left: calc(50% + 15px);
        margin-top: 3px;
        background-color: #fff;
        border-radius: var(--border-radius-lg);
        padding: 0 4px;
        z-index: calc(var(--z-popup) + var(--z-above-content));
      }

      &__buttons {
        display: flex;
        flex-direction: column;

        .reset_dates {
          margin-bottom: 80px;
        }

        .choose_dates, .reset_dates {
          margin-top: 3px;
          padding: 0 25px;
        }

        .choose_warning {
          position: inherit;
          margin: -17px auto 0;
        }
      }

      .inline-calendar {
        overflow: auto;
        margin-right: 25px;
        margin-bottom: 0;

        &__legend {
          margin: 0;

          .allow {
            background-color: var(--brand-highlight-sec);
          }

          .bad-status {
            background-color: #f79999;
          }

          .has-flight {
            background-color: #f3f3a7;
            border: 1px solid #9fa013;
          }
        }

        .ui-datepicker {
          &-calendar {
            .ui-state-default {
              color: var(--brand-highlight-sec);
              height: 27px;
              line-height: 22px;
            }

            .ui-state-hover {
              color: #fff;
            }

            .ui-state-active {
              background-color: #eee;

              &.ui-state-hover {
                background-color: #4ca5ff;
              }
            }

            .bad-status:not(.ui-state-disabled) .ui-state-default {
              border-color: #f79999;
            }

            .other-rule:not(.ui-state-disabled) .ui-state-default {
              border-color: var(--color-green);

              &:not(.ui-state-hover) {
                background-color: #eee;
                color: var(--color-green);
              }
            }

            .has-flight:not(.ui-state-disabled) .ui-state-default {
              border-color: #9fa013;

              &:not(.ui-state-hover) {
                background-color: #f3f3a7;
              }

              &.ui-state-hover {
                background-color: #fbff4c;
                color: var(--brand-highlight-sec);
              }
            }

            .bad-status:not(.ui-state-disabled) .ui-state-default:not(.ui-state-hover) {
              color: #f79999;
            }

            .active-date:not(.ui-state-disabled) .ui-state-default:not(.ui-state-hover) {
              background-color: var(--color-lblue);
              color: #fff;
            }

            .beyond-period .ui-state-default {
              color: var(--color-light);
            }

            .ui-datepicker-other-month .ui-state-default {
              color: #eee;
              background-color: inherit;
            }
          }
        }
      }
    }

    .choose-block__left-col {
      max-width: 86%;
    }

    .ss__suggestions {
      position: absolute;
      z-index: 10;
      top: calc(100% + 0.25rem);

      & &__list.no-sug-map {
        display: block;

        .ss__suggestions__list__rows {
          display: none;
        }

        .ss__suggestions__list__items {
          display: block;

          .ss__suggestions__list__item {
            width: auto;
          }
        }

      }

      & &__node-pointer {
        border-top: 0;

        &__arrow {
          top: 2px;
          left: 10px !important;
        }
      }

      & &__main {
        max-height: 60vh;
        border-radius: 5px;
        border: 2px solid var(--color-disabled);
      }

      & &__toggle {
        display: none;
      }
    }

    .ss__suggestions__main {
      min-height: initial;
    }

    .find_flight {
      min-width: 150px;
    }
  }

  .flt-seg-arr {
    width: auto;
  }
}

.find-flight-list-item-part__time {
  display: flex;
  align-items: flex-start;
}

.find-flight-list-item-part__time-value {
  display: inline-block;
  vertical-align: top;
  font-size: var(--font-size-lg);
  font-weight: bold;
  line-height: 0.8;
}

.find-flight-list-item-part__time-shift {
  display: inline-block;
  vertical-align: top;
  margin-top: -1px;
  margin-left: 2px;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
}
