.ssrs-list {
  padding: 0.625rem 0 0 0;
  max-height: 21.875rem;
  overflow-y: auto;
  overflow-x: hidden;

  & li:not(:last-child) {
    margin-bottom: 0.625rem;
  }
}

.ssr {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
}

.ssr-code {
  margin-left: 0.3125rem;
  margin-right: 0.3125rem;
  width: 2.5rem;
  text-align: center;
  position: relative;
  color: var(--brand-secondary);

  & .ssr-subcode {
    display: inline-block;
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    color: #eee;
    font-size: 0.7em;
    text-align: center;
  }
}

.ssr-full-name {
  flex-basis: 14rem;

  &__rqst {
     flex-basis: 7rem;
  }
}

.tourist-ssr-ico {
  width: 1.25rem;
  height: 1.25rem;
  background-image: url(tais-styles/svg/ssr/default.svg);
  background-size: contain;
}

.tourist-ssr-ico.ssr-wait {
  background-image: url(tais-styles/svg/ssr/default-wait.svg);
}

.tourist-ssr-ico.ssr-reject {
  background-image: url(tais-styles/svg/ssr/default-reject.svg);
}

.ssr-ico-AVIH {
  background-image: url(tais-styles/svg/ssr/AVIH.svg);
}

.ssr-ico-RQST {
  background-image: url(tais-styles/svg/ssr/RQST.svg);
}

.ssr-ico-PETC {
  background-image: url(tais-styles/svg/ssr/PETC.svg);
}

.ssr-ico-SPML {
  background-image: url(tais-styles/svg/ssr/SPML.svg);
}

.ssr-ico-KSML {
  background-image: url(tais-styles/svg/ssr/KSML.svg);
}

.ssr-ico-SPEQ {
  background-image: url(tais-styles/svg/ssr/SPEQ.svg);
}

.ssr-ico-SPEQ-0EC {
  background-image: url(tais-styles/svg/ssr/SPEQ-0EC.svg);
}

.ssr-ico-SPEQ-0FT {
  background-image: url(tais-styles/svg/ssr/SPEQ-0FT.svg);
}

.ssr-ico-XBAG {
  background-image: url(tais-styles/svg/ssr/XBAG.svg);
}

.ssr-ico-XBAG-0CY {
  background-image: url(tais-styles/svg/ssr/XBAG-0CY.svg);
}

.ssr-ico-XBAG-0CV {
  background-image: url(tais-styles/svg/ssr/XBAG-0CV.svg);
}

.ssr-ico-WCHS {
  background-image: url(tais-styles/svg/ssr/WCHC.svg);
}

.ssr-ico-WCHC {
  background-image: url(tais-styles/svg/ssr/WCHC.svg);
}

.ssr-ico-WCHR {
  background-image: url(tais-styles/svg/ssr/WCHR.svg);
}

.ssr-ico-AVIH-wait {
  background-image: url(tais-styles/svg/ssr/AVIH-wait.svg);
}

.ssr-ico-RQST-wait {
  background-image: url(tais-styles/svg/ssr/RQST-wait.svg);
}

.ssr-ico-PETC-wait {
  background-image: url(tais-styles/svg/ssr/PETC-wait.svg);
}

.ssr-ico-SPML-wait {
  background-image: url(tais-styles/svg/ssr/SPML-wait.svg);
}

.ssr-ico-KSML-wait {
  background-image: url(tais-styles/svg/ssr/KSML-wait.svg);
}

.ssr-ico-SPEQ-wait {
  background-image: url(tais-styles/svg/ssr/SPEQ-wait.svg);
}

.ssr-ico-SPEQ-0EC-wait {
  background-image: url(tais-styles/svg/ssr/SPEQ-0EC-wait.svg);
}

.ssr-ico-SPEQ-0FT-wait {
  background-image: url(tais-styles/svg/ssr/SPEQ-0FT-wait.svg);
}

.ssr-ico-XBAG-wait {
  background-image: url(tais-styles/svg/ssr/XBAG-wait.svg);
}

.ssr-ico-XBAG-0CY-wait {
  background-image: url(tais-styles/svg/ssr/XBAG-0CY-wait.svg);
}

.ssr-ico-XBAG-0CV-wait {
  background-image: url(tais-styles/svg/ssr/XBAG-0CV-wait.svg);
}

.ssr-ico-WCHS-wait {
  background-image: url(tais-styles/svg/ssr/WCHC-wait.svg);
}

.ssr-ico-WCHC-wait {
  background-image: url(tais-styles/svg/ssr/WCHC-wait.svg);
}

.ssr-ico-WCHR-wait {
  background-image: url(tais-styles/svg/ssr/WCHR-wait.svg);
}

.ssr-ico-AVIH-reject {
  background-image: url(tais-styles/svg/ssr/AVIH-reject.svg);
}

.ssr-ico-RQST-reject {
  background-image: url(tais-styles/svg/ssr/RQST-reject.svg);
}

.ssr-ico-PETC-reject {
  background-image: url(tais-styles/svg/ssr/PETC-reject.svg);
}

.ssr-ico-SPML-reject {
  background-image: url(tais-styles/svg/ssr/SPML-reject.svg);
}

.ssr-ico-KSML-reject {
  background-image: url(tais-styles/svg/ssr/KSML-reject.svg);
}

.ssr-ico-SPEQ-reject {
  background-image: url(tais-styles/svg/ssr/SPEQ-reject.svg);
}

.ssr-ico-SPEQ-0EC-reject {
  background-image: url(tais-styles/svg/ssr/SPEQ-0EC-reject.svg);
}

.ssr-ico-SPEQ-0FT-reject {
  background-image: url(tais-styles/svg/ssr/SPEQ-0FT-reject.svg);
}

.ssr-ico-XBAG-reject {
  background-image: url(tais-styles/svg/ssr/XBAG-reject.svg);
}

.ssr-ico-XBAG-0CY-reject {
  background-image: url(tais-styles/svg/ssr/XBAG-0CY-reject.svg);
}

.ssr-ico-XBAG-0CV-reject {
  background-image: url(tais-styles/svg/ssr/XBAG-0CV-reject.svg);
}

.ssr-ico-XBAG-no-baggage{
  background-image: url(tais-styles/svg/ssr/XBAG-no-baggage.svg);
}

.ssr-ico-WCHS-reject {
  background-image: url(tais-styles/svg/ssr/WCHC-reject.svg);
}

.ssr-ico-WCHC-reject {
  background-image: url(tais-styles/svg/ssr/WCHC-reject.svg);
}

.ssr-ico-WCHR-reject {
  background-image: url(tais-styles/svg/ssr/WCHR-reject.svg);
}

.ssr-status-ok {
  color: var(--brand-highlight);

  & .ssr-status-text {
    color: var(--brand-highlight);
  }
}

.ssr-status-cancel {
  visibility: hidden;
  color: var(--brand-secondary);

  & .ssr-status-text {
    color: var(--brand-secondary);
  }
}


.ssr-status-wait {
  color: var(--brand-secondary);

  & .ssr-status-text {
    color: #FFC000;
  }

  & .rounded-checkbox input[type=checkbox]:checked + i {
      border: 1px solid var(--brand-secondary);
      background-color: var(--brand-secondary);
  }
}

.ssr-status-reject,
.ssr-status-fail,
.ssr-status-unknown {
  color: var(--brand-secondary);

  & .ssr-status-text {
    color: var(--brand-warning);
  }
}

.ssr-status-reject {
  & .rounded-checkbox input[type=checkbox]:checked + i {
    border: 1px solid var(--brand-secondary);
    background-color: var(--brand-secondary);
  }
}

.ssr-cost-status {
  min-width: 8em;
  margin-left: 0.5rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: flex-start;
}

.ssr-status-fail .ssr-cost-status {
  cursor: auto;
}

.ssr-active .ssr-cost-status,
.ssr-active .rounded-checkbox + i {
  cursor: auto;
}


.rbd-seg-status {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  border-radius: var(--border-radius);
  border: 1px solid var(--brand-secondary);
  margin: 0 6px 0 8px;
  font-size: 0.8em;

  & .ssr-rbd {
    width: 1.5em;
    margin-left: 0.1875rem;
    color: var(--brand-secondary);
    text-align: center;
  }

  & .ssr-seg-status {
    padding: 0 0.125rem;
    color: #fff;
    background-color: var(--brand-secondary);
  }
}

.single-ssr {
  padding: 0.3125rem 0;

  &.ssr-active {
    background-color: #fff;
    border-radius: 0.625rem 0.625rem 0 0;
  }

  & .rounded-checkbox {
    padding-right: 0.625rem;
  }
}

.ssr-actions {
  position: relative;
  background: #fff;
  padding: 0.625rem;
  border-radius: 0 0 0.625rem 0.625rem;
  margin-left: 0;
}

.close-ssr {
  position: absolute;
  top: 0;
  background: transparent;
  transform: rotate(45deg);
  width: 1.25rem;
  height: 1.25rem;
  border: 0;
  border-bottom: 1px solid;
}

.close-ssr:after {
  content: '';
  position: absolute;
  top: 0.625rem;
  transform: rotate(90deg);
  border-bottom: 1px solid;
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
}

.ssr-direct {
  & .close-ssr {
    left: 8.8rem;
    top: -1.85rem;
  }

  & .ssr-segment-block {
    padding-left: 1.25rem;
  }
}

.ssr-reverse {
  margin-left: 0.9375rem;

  & .ssr-actions {
    margin-left: 0;
    margin-right: 0;
    border-radius: 0 0 0.625rem 0.625rem;
  }

  & .ssr-cost-status {
    text-align: right;
  }

  & .close-ssr {
    right: 0.005rem;
    top: -1.8rem;
  }
}

.ssr-retry {
  cursor: pointer;
  color: var(--brand-warning);
  font-size: 14px;

  &__lnk {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.ssr-actions {

  &__no-text {
    border-radius: 0 0 0.625rem 0.625rem;
    margin-left: 0;

    & .close-ssr {
      left: 8.8rem;
      top: -3rem;

      &__no-text {
        top: -2rem;
      }
    }
  }
}
