/**/
:root {
  --terminal-menu-toggle-size: 2.25rem; /* 36px */

  --terminal-menu-indent-base: 1rem;

  --terminal-menu-font-size-base: 1rem;
  --terminal-menu-font-size-small: 0.875rem;

  --terminal-menu-main-header-font-size: 1.5rem;        /* 24px */
  --terminal-menu-tabs-nav-font-size: 1.25rem;          /* 20px */
  --terminal-menu-block-header-font-size: 1.125rem;     /* 18px */
  --terminal-menu-comand-font-size: 1rem;               /* 16px */
  --terminal-menu-comand-toggle-font-size: 0.875rem;    /* 14px */

  --terminal-menu-border-size: 0.125rem;

  --terminal-menu-side-width: 15.625rem;

  --terminal-menu-default-border-radius: 0.25rem;
}
