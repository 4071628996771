/*
* --------------------------------------------------
* This file contains CSS helper classes.
* --------------------------------------------------
*/

/**
 * Clear inner floats
 */
.clearfix::after {
  clear: both;
  content: '';
  display: table;
}

/**
* Flexbox container
*/
.flex {
  position: relative;
  display: flex;

  &_width-available {
    flex: 1 1 100%;
  }

  &_sp-between {
    justify-content: space-between;
  }

  &_sp-arround {
    justify-content: space-around;
  }

  &_wrap {
    flex-wrap: wrap;
  }

  &_nowrap {
    flex-wrap: nowrap;
  }

  &_align-center {
    align-items: center;
  }

  &_align-start {
    align-items: flex-start;
  }

  &_align-end {
    align-items: flex-end;
  }

  &_align-stretch {
    align-items: stretch;
  }

  &_align_baseline {
    align-items: baseline;
  }

  &_justify_start {
    justify-content: flex-start;
  }

  &_justyfy_center {
    justify-content: center;
  }

  &_justify-end {
    justify-content: flex-end;
  }

  &_centered {
    align-items: center;
    justify-content: center;
  }

  &_dir-column {
    flex-direction: column;
  }

  &_align-item-right {
    margin-left: auto;
  }

  &-item-full {
    flex-grow: 1;
    width: 100%;
    display: inline-flex;
    margin-bottom: 20px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &_grow_one {
    flex-grow: 1;
    width: auto;
  }
}

.float-left { float: left; }
.float-right { float: right; }

._vhidden {
  visibility: hidden;
}

._hidden {
  display: none;
}

.center_item {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.relative_item {
  position: relative;
}

.i-flex {
  display: inline-flex;
}

.not-visible {
  width: 0 !important;
  height: 0 !important;
  opacity: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
}
