.hint {
  &__wrapper {
    position: relative;
  }

  &__wrapper:hover {
    cursor: pointer;
  }

  &__body {
    pointer-events: none;
    position: absolute;
    background-color: #eee;
    padding: 0.6rem;
    padding-top: 0.4rem;
    padding-bottom: 0.3rem;
    min-width: 6rem;
    min-height: 2rem;
    border-radius: 5px;
    top: -35%;
    left: 2rem;
    text-align: left;
    z-index: var(--z-above-content);
    display: none;

    &_down {
      left: -40px;
      top: 22px;
    }

    &:after {
      position: absolute;
      bottom: 42%;
      display: block;
      width: 0;
      height: 0;
      margin-left: -1.3rem;

      content: '';
      border-right: 0.5rem solid transparent;
      border-bottom: 0.5rem solid #eee;
      border-left: 0.5rem solid transparent;

      transform: rotate(270deg);
    }

    &_down:after {
      left: 65%;
      bottom: 0;
      top: -7px;
      transform: rotate(0deg);
    }
  }
}
