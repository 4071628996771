.eqp-editor {
  display: flex;
  flex: 1 1 100%;
  overflow: hidden;
  flex-direction: column;
}

.equipment__options {
  display: inline-block;
}

.cabin-row__business td:first-child{
  text-transform: uppercase;
  border-right: 3px solid gold;
}

.cabin-row__economy td:first-child{
  text-transform: uppercase;
  border-right: 3px solid gray;
}

.equipment-arrangement-usage,
.equipment-cabin
{
  table-layout:fixed;

  thead td {
    text-transform: uppercase;
    color: #777;
  }

  td {
    padding: 5px 10px;
  }
}

.equipment-seat {
  border: 1px solid;
  height: 90px;
}

.seat-remark {
  color: #888;
}

.seat-remark__active {
  color: black;
  font-weight: bold;
}

.seat-usage:disabled {
  border: 0;
  opacity: .3;
}

.equipment-select {
  border: 1px solid;
  padding: 10px 0;
  text-align: left;
}
