.ss__smart-input {
  position: relative;
  padding: 0.5rem 10.5rem 0.3125rem calc(var(--ss-indent-base) * 4);
  background-color: #fff;
  border-radius: 5px;
  min-height: 3rem;
  max-height: 12rem;
  overflow-y: auto;

  .smartsearch {
    display: inline-block;
  }
}

.ss__container_suggestions-closed {
  .ss__smart-input {
    z-index: 1;
  }
}

/*
 node
*/

.ss__smart-input__node {
  display: inline;
  position: relative;
  z-index: 30;
  padding: 0;
  font-size: 1.125rem;
  line-height: 2;
  vertical-align: top;
  max-width: 100%;

  /* TODO: BEM... */

  &.rfisc-code,
  &.rfisc-text,
  &.reqRfiscReqComment_rfisc-code,
  &.reqRfiscOptComment_rfisc-code,
  &.reqRfiscNoComment_rfisc-code,
  &.time-end,
  &.mnd-date-opt-interval_end,
  &.mnd-date-interval-opt-weekdays_end,
  &.mnd-date-interval-opt-weekdays_weekdays,
  &.fp-date-opt-interval_end,
  &.flight,
  &.ak-opt-flight_flight,
  &.ak-opt-flight-interval_flight,
  &.ak-opt-flight-interval_second-flight,
  &.param-flight,
  &.queue-opt-office_office-code,
  &.queue-opt-office-agency_office-code,
  &.queue-notification_office-code,
  &.date-opt-time_time,
  &.agency-opt-operator_operator,
  &.agency-opt-operator-name_name,
  &.fixedpos-flight,
  &.rbd-opt-rbdholder_rbdholder,
  &.dep-arr-time_end,
  &.ak-opt-aircraft_aircraft,
  &.ak-opt-aircraft-compos_aircraft,
  &.ak-opt-mak-base,
  &.ak-opt-mak-base_mak,
  &.ak-opt-mak-agreement_mak,
  &.ak-opt-mak-agreement_agreement,
  &.ak-opt-aircraft-compos_compos,
  &.arrival-opt-gate_gate,
  &.departure-opt-gate_gate,
  &.payment-info-card-type,
  &.payment-info-sert-type,
  &.payment-info-card-number,
  &.payment-info-expire-date,
  &.payment-sert-expire-date,
  &.payment-info-confirmation-code,
  &.payment-sert-confirmation-code,
  &.payment-info-counterpart,
  &.payment-info-counterpart-org,
  &.payment-info-doc-number,
  &.payment-info-vt-doc-number,
  &.payment-info-doc-order-number,
  &.payment-info-doc-code,
  &.payment-info-country-code,
  &.payment-info-doc-issue-date,
  &.payment-info-payment-date,
  &.payment-info-order-date,
  &.fps-payment-info-order-date,
  &.payment-info-contract,
  &.mnd-payment-info-contract,
  &.fp-payment-info-doc-issue-date,
  &.payment-info-org-name,
  &.payment-info-vt-org-name,
  &.payment-info-in-text,
  &.payment-info-mc-certificate-number,
  &.payment-info-mc-certificate-text,
  &.certificate-number,
  &.certificate-text,
  &.date-opt-time-point_time,
  &.date-opt-time-point_point,
  &.date-opt-time-point-tl_time,
  &.date-opt-time-point-tl_point,
  &.second-date-opt-time_time,
  &.optional-opt-date-opt-interval_end,
  &.optional-opt-date-opt-interval-departure_end,
  &.optional-opt-date-opt-interval-operation_end,
  &.optional-opt-date-opt-interval-pl_end,
  &.optional-opt-date-opt-time_time,
  &.ak-opt-flightlist_flightlist,
  &.ffp-card-number,
  &.office-code-operator_operator,
  &.office-code-gds-1a +
  &.operator-code-gds-1a,
  &.two-pax-category_second,
  &.two-pax-category-no-seat_second,
  &.optional-time-limit-at-moment_time,
  &.optional-time-limit-from-moment_time,
  &.optional-time-limit-from-date_time,

  &[class*="optional-card-type"],
  &[class*="optional-sert-type"],
  &[class*="optional-card-number"],
  &[class*="optional-sert-number"],
  &[class*="optional-card-expire-date"],
  &[class*="optional-sert-expire-date"],
  &[class*="optional-in-text"],
  &.ak-opt-text_text,

  &[class*="optional-info-org-name"],
  &[class*="optional-doc-number"],
  &[class*="optional-doc-date"],
  &[class*="optional-kr-counterpart-org"],
  &[class*="optional-kr-doc-number"],
  &[class*="optional-kr-doc-date"],
  &[class*="optional-kr-org-name"],
  &[class*="optional-kr-doc-code"],
  &[class*="optional-kr-country-code"],
  &[class*="optional-kr-doc-issue-date"],
  &[class*="optional-kr-payment-date"],

  &.reqRfiscNoComment_fee,
  &.reqRfiscReqComment_fee,
  &.reqRfiscOptComment_fee,
  &.noRfiscReqComment_fee,
  &.noRfiscOptComment_fee,
  &.noRfiscNoComment_fee,
  &.ak-opt-orggrdoffice_orggrdoffice,

  &.card-type,
  &.card-number,
  &.card-expire-date,
  &.card-confirmation-code,

  &.st-info-org-name,
  &.st-doc-number,
  &.st-doc-date,
  &.st-contract,

  &.kr-counterpart-org,
  &.kr-doc-number,
  &.kr-doc-date,
  &.kr-org-name,
  &.kr-doc-code,
  &.kr-country-code,
  &.kr-doc-issue-date,
  &.inv-info-org-name,
  &.inv-doc-number,
  &.inv-doc-date,
  &.inv-contract,

  &.vo-cert-type,
  &.vo-cert-number,
  &.vo-cert-expire-date,
  &.vo-confirmation-code,

  &.vz-info-org-name,
  &.vz-doc-number,
  &.vz-doc-date,
  &.vz-contract,
  &.kr-payment-date,

  &.in-text,

  &.cf-account-number,
  &.cf-confirmation-code,
  &.payment-info-account-number,
  &[class*="optional-account-number"],
  &[class*="optional-confirmation-code"],

  &.optional-exchange-xxtime,
  &.optional-exchange-xxpoint,

  &.optional-nn-arrtime1_time,
  &.optional-nn-arrtime2_time,
  &.optional-nn-arrtime3_time,
  &.optional-nn-arrtime4_time,
  &.optional-nn-arrtime5_time,
  &.optional-nn-deptime1_time,
  &.optional-nn-deptime2_time,
  &.optional-nn-deptime3_time,
  &.optional-nn-deptime4_time,
  &.optional-nn-deptime5_time,

  &[class*="optional-certificate-number"],
  &[class*="optional-certificate-text"],

  &[class*="optional-plane-composition-"],

  &.ffs-card-number,
  &.ffs-text,
  &.ffr-card-number,
  &.ffr-text,

  &.optional-date-time-begin_time,
  &.optional-date-time-end_time,
  &.optional-logdate_time,

  &.amount-opt-transfer-currency_transfer {
    .ss__smart-input__node-value::before {
      content: "-" !important;
      vertical-align: top;
    }

    .ss__smart-input__node-selection__begin {
      padding-left: 0;

      &::before {
        content: "-" !important;
      }
    }
  }

  &.cash-literal-with-comma,
  &.card-literal-with-comma,
  &.inv-literal-with-comma,
  &.kr-literal-with-comma,
  &.st-literal-with-comma,
  &.vo-literal-with-comma,
  &.vz-literal-with-comma,
  &.cf-literal-with-comma,
  &.in-literal-with-comma,
  &.mc-literal-with-comma,
  &.ffs-literal-with-comma,
  &.ffr-literal-with-comma,
  &[class*="optional-fssr-"],
  &[class*="optional-rfisc-pl-"],
  &[class*="optional-aircraft-"],
  &[class*="optional-rpl-skh-with-param"],
  &[class*="column-field"] {
    .ss__smart-input__node-value::before {
      content: "," !important;
      vertical-align: top;
    }
  },

  &.optional-time-limit-at-moment_place,
  &.optional-time-limit-from-moment_place,
  &.optional-time-limit-from-date_place,
  &.optional-opt-date-opt-interval-operation_start_time,
  &.optional-opt-date-opt-interval-operation_end_time,
  &[class*="column-format"] {
    .ss__smart-input__node-value::before {
      content: ":" !important;
      vertical-align: top;
    }
    .ss__smart-input__node-selection__begin {
      padding-left: 0;
    }
  }

  &[class*="column-byline"] {
    .ss__smart-input__node-value::before {
      content: "::" !important;
      vertical-align: top;
    }
    .ss__smart-input__node-selection__begin {
      padding-left: 0;
    }
  }

  &.optional-fssr-1,
  &.optional-rfisc-pl-1,
  &.optional-aircraft-1,
  &.optional-rpl-skh-with-param-1,
  &.column-field-1 {
    .ss__smart-input__node-value::before {
      content: "" !important;
      vertical-align: top;
    }
  }

  &.pax-opt-segment_segment,
  &.pax-index-opt-segment_segment{
    .ss__smart-input__node-value::before {
      content: ",F";
      vertical-align: top;
    }
    .ss__smart-input__node-selection__begin {
      padding-left: 0;
    }
  }

  &.ak-opt-flightlist_group {
    .ss__smart-input__node-value::before {
      content: "-%";
      vertical-align: top;
    }
    .ss__smart-input__node-selection__begin {
      padding-left: 0;
    }
  }

  &.sf-segment-indices {
    .ss__smart-input__node-value::before {
      content: " F";
      vertical-align: top;
    }
  }

  &.ns-pax-indices {
    .ss__smart-input__node-value::before {
      content: " P";
      vertical-align: top;
    }
  }

  &.ssr-opt-pax_pax {
    .ss__smart-input__node-value::before {
      content: ",P";
      vertical-align: top;
    }
    .ss__smart-input__node-selection__begin {
      padding-left: 0;
    }
  }

  &.service-code_2,
  &.service-code_3 {
    .ss__smart-input__node-value::before {
      content: ",";
      vertical-align: top;
    }
    .ss__smart-input__node-selection__begin {
      padding-left: 0;
    }
  }

  &.qualifier_code {
    .ss__smart-input__node-value::before {
      color: var(--brand-primary);
      content: '/';
      vertical-align: top;
    }
  }

  &.residence-country-code,
  &.permanent-country-code,
  &.street-data,
  &.city-code,
  &.region-code,
  &.zip-code,
  &.visa-doc-type,
  &.visa-number,
  &.visa-issue-place,
  &.visa-issue-date,
  &.visa-country,
  &.visa-due-date,
  &.ak-market
   {
    .ss__smart-input__node-value::before {
      content: '/';
      vertical-align: top;
    }
  }

  &.ssr-rfisc-code-text {
    .ss__smart-input__node-value::before {
      color: var(--brand-primary);
      content: " SSR=";
      vertical-align: top;
    }
    .ss__smart-input__node-value_active::before {
      content: " SSR=";
      vertical-align: top;
    }
    .ss__smart-input__node-value[data-twtss-hint="whole-set"]::before {
      content: " ";
    }
    &.selected {
      .ss__smart-input__node-value::before {
        color: #fff;
        content: " SSR=";
        vertical-align: top;
      }
    }
  }

  &.action-si_qty {
    .ss__smart-input__node-value::before {
      content: "";
    }
  }

  &.ssr-code {
    margin-right: 0;
  }

  &.ssr-indices {
    .ss__smart-input__node-value::before {
      content: " S";
      vertical-align: top;
    }
    .ss__smart-input__node-value_active::before {
      color: #ccc;
      content: " S";
      vertical-align: top;
    }
    .ss__smart-input__node-value[data-twtss-hint="whole-set"]::before {
      content: "";
    }
  }

  &.ssr-indices-s-wildcard {
    .ss__smart-input__node-value::before {
      content: " S";
      vertical-align: top;
    }
    .ss__smart-input__node-value_active::before {
      content: " S";
      vertical-align: top;
    }
    .ss__smart-input__node-value[data-twtss-hint="whole-set"]::before {
      content: " S";
      vertical-align: top;
    }
  }

  &.info-chapter-code {
    .ss__smart-input__node-value::before {
      content: " .";
      vertical-align: top;
    }
    .ss__smart-input__node-selection__begin {
      padding-left: 0;
    }
  }

  &.cmd-verb {
    .ss__smart-input__node-value::before {
      content: "";
    }
    .ss__smart-input__node-selection__begin {
      padding-left: 0;
    }
  }

  &[class*="embedded-"] {
    .ss__smart-input__node-value::before {
      content: "-";
      vertical-align: top;
    }
    .ss__smart-input__node-selection__begin {
      padding-left: 0;
    }
  }

  &.embedded-fromto_arrival {
    .ss__smart-input__node-value::before {
      content: "";
    }
  }

  &[class*="param-"],
  &[class*="optional-"] {
    .ss__smart-input__node-value::before {
      content: "";
    }
    .ss__smart-input__node-selection__begin {
      padding-left: 0;
    }
  }

  &[class*="next-arrival"],
  &.segment-arrival-2,
  &.fromto_arrival,
  &.fp_fromto_arrival,
  &.from-opt-to_arrival {
    .ss__smart-input__node-value::before {
      content: "";
    }
    .ss__smart-input__node-selection__begin {
      padding-left: 0;
    }
  }

  &.fromto-airport_arrival {
    .ss__smart-input__node-value::before {
      content: "";
    }
    .ss__smart-input__node-selection__begin {
      padding-left: 0;
    }
  }

  &.both_directions {
    .ss__smart-input__node-value::before {
      content: "";
    }
    .ss__smart-input__node-selection__begin {
      padding-left: 0;
    }
  }

  ::selection{
    color: #fff;
    background-color: var(--selection-bg);
  }

  &.selected {
    color: #fff;
    background-color: var(--selection-bg);
  }
}

.ss__smart-input__node-value {
  display: inline;
  position: relative;
  min-width: 10px;
  font-size: 1.125rem;
  line-height: 2;
  vertical-align: top;
  white-space: normal;
  max-width: 100%;
  word-wrap: break-word;
  padding: 0 0 10px;

  &::before {
    content: " ";
    vertical-align: top;
  }

  &.editable {
    outline: 0 none;
  }

  /* hide-effect for node pointer arrow */
  .ss__container:not(.ss__container_suggestions-closed) .ss__smart-input__node_with-actions:hover &.ss__smart-input__node-value_active {
    &::after {
      content: "";
      position: absolute;

      left: -0.5rem;
      right: -0.5rem;
      bottom: -1px;
      height: 0.6rem;

      border-bottom: 2px solid #ccc;
      background-color: #fff;
    }
  }

  &.placeholder {
    text-transform: none;
    color: #ccc;

    &:focus {
      color: #aaaaaa;
    }
  }

  &.error {
    color: var(--ss-color-danger);
  }
}

.ss__smart-input__node-selection {
  position: absolute;
  height: 100%;
  color: #fff;
  background: var(--selection-bg);
  bottom: 0;
  z-index: 10;
  line-height: normal;
}

.ss__smart-input__node-selection__begin {
  left: 0;
  padding-left: var(--ss__smart-input_node-indent);
}

.ss__smart-input__node-selection__end,
.ss__smart-input__node-selection__inner {
  right: 0;
}

.ss__smart-input__post-node {
  display: inline-block;
  position: absolute;
  top: 100%;
  left: 2px;
  margin-top: -33px;
  height: 14px;
  line-height: 1;
}

.ss__smart-input__node-actions {
  display: none;
  height: 40px;
  margin-top: 1px;
  font-size: 1.25rem;
  line-height: 1;
  overflow: hidden;
  pointer-events: none;

  .ss__smart-input__node:not(.selected):hover & {
    display: inline-block;
  }
}

.ss__smart-input__node-actions__action {
  position: relative;
  top: 24px;
  width: 14px;
  height: 18px;
  vertical-align: top;
  pointer-events: auto;

  color: #aaaaaa;
  cursor: pointer;

  &:hover {
    color: var(--ss-color-danger);
  }
}

.ss__smart-input__node-actions__action__top {
  position: relative;
  top: -4px;
  width: 18px;
  height: 18px;
  vertical-align: top;
  font-size: 1rem;
  pointer-events: auto;

  color: #aaaaaa;
  background-color: rgba(255, 255, 255, .5);

  cursor: pointer;

  &:hover {
    color: var(--ss-color-danger);
  }
}

.ss__smart-input__growth-point {
  display: inline;
  position: relative;
  min-width: 10px;
  margin-left: var(--ss__smart-input_node-indent);
  padding: 0 0 10px;
  font-size: 1.125rem;
  line-height: 1;
  white-space: normal;
  max-width: 100%;
  word-wrap: break-word;

  &.active {
    line-height: 2;
    vertical-align: top;
  }

  &.editable {
    outline: 0 none;
  }

  &.placeholder {
    text-transform: none;
    color: #ccc;

    &:focus {
      color: #aaaaaa;
    }
  }

  &.selected {
    color : transparent;
    text-shadow : 0 0 0 #000;
  }
}

/*
  command switcher
*/
.ss__suggestions__command-switcher {
  position: absolute;
  z-index: 50;
  left: 50%;
  top: 1.125rem;

  margin-left: -9rem;
  padding-left: 2rem;

  text-transform: none;
  font-size: var(--ss-font-size-small);
  line-height: 1;

  .ss__suggestions__command-switcher__items {
    margin-top: -0.3125rem;
    border: 1px solid #ccc;
    border-radius: 3px;

    .ss__suggestions__command-switcher__item {
      display: inline-block;
      padding: 0.5rem 0.5rem 0.375rem 0.5rem;
      min-width: 110px;
      text-align: center;
      vertical-align: top;

      cursor: pointer;

      &:first-child {
        border-right: 1px solid #ccc;
        white-space: nowrap;
      }

      &_active {
        color: #fff;
        background-color: #ccc;
        cursor: default;
      }
    }
  }
}

/*
  command preset selector
*/
.ss__suggestions__command__preset-selector {
  .ss__suggestions__command__preset-selector__open-button {
    color: #fff;
    background-color: #ccc;
  }

  .ss__suggestions__command__preset-selector__item {
    color: #fff;
    background-color: #ccc;
    padding: 7px
  }

  .ss__suggestions__command__preset-selector__item:hover {
    background-color: #aaaaaa;
    cursor: pointer;
  }
}

.ss__smart-input__btn-go,
.terminal__go {
  position: absolute;
  z-index: calc(var(--z-search-history) + 1);

  display: block;
  width: 1.125rem;
  height: 1.75rem;
  padding: 0;

  cursor: pointer;

  color: var(--brand-highlight);

  border: none;
  box-shadow: none;
  background: transparent;
}

.ss__smart-input__btn-go {
  left: calc(var(--ss-indent-base) * 4);
  top: calc( 2px + 0.875rem );

  margin-top: -0.25rem;
  margin-left: calc(var(--ss__smart-input_node-indent) * 2);
}

.terminal__go {
  right: calc(8rem + var(--stack-panel-toggle-width));
  top: calc( 1.125rem + 2px );
  /*
  0.5rem - smart input top margin
  2px - smart input border-width
  0.625rem - icon indent to center it in the field
  */
}

.ss__smart-input__btn-go::after,
.terminal__go::after {
  position: absolute;
  top: calc( 50% - 0.875rem);
  left: 0.25rem;
  content: '';

  display: block;
  width: 0;
  height: 0;

  border-top: solid 0.875rem transparent;
  border-bottom: solid 0.875rem transparent;
  border-left: solid 0.875rem currentColor;
}

.ss__smart-input__img-go {
  display: inline-block;
  margin-left: 0.125rem;
  font-size: 0.625rem;

  cursor: pointer;
  color: var(--brand-highlight);

  line-height: 1;
  vertical-align: 1px;

  &::after {
    display: inline-block;
    content: '\25ba';/*►*/
    transform: scaleY(2);
  }
}

.ss__smart-input__btn-clear {
  position: absolute;
  left: 0.625rem;
  top: calc( 0.75rem + 2px ); /* 2px - border-width, specified in px */
  z-index: calc(var(--z-search-history) + 1);

  display: block;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;

  cursor: pointer;

  color: #ccc;

  border: 0.125rem solid currentColor;
  border-radius: 50%;

  background: transparent;
}

.ss__smart-input__btn-clear::before,
.ss__smart-input__btn-clear::after {
    position: absolute;
  top: calc( 50% - 0.0625rem );
  left: calc( 50% - 0.375rem );

  content: '';

  display: block;
  width: 0.75rem;
  height: 0.125rem;
  background: currentColor;

  transform-origin: 50% 50%;
}

.ss__smart-input__btn-clear::before {
  transform: rotate(45deg);
}

.ss__smart-input__btn-clear::after {
  transform: rotate(-45deg);
}

.ss__smart-input__btn-clear:hover {
  color: var(--ss-color-danger);
}

.ss__smart-input__btn-favorites {
  position: absolute;
  top: calc( 0.5rem + 2px ); /* 2px - border-width, specified in px */
  right: 4rem;
  z-index: calc(var(--z-search-history) + 1);

  display: block;
  width: 2rem;
  height: 2rem;
  padding: 0;

  cursor: pointer;

  color: #ccc;

  border: none;
  background-image: url("smartsearch-ui/css/images/btn-favorites__icon_inactive.svg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 100%;
}

.ss__smart-input__btn-favorites:hover {
  background-image: url("smartsearch-ui/css/images/btn-favorites__icon_active.svg");
}

.ss__smart-input__btn-favorites.stored,
.ss__smart-input__btn-favorites.stored:hover {
  background-image: url("smartsearch-ui/css/images/btn-favorites__icon_filled.svg");
}

.terminal-generation {
  top: calc( 2rem - 8px );
  /*
  0.5rem + 2px + (3rem - 20px) / 2
  0.5rem - smart input top margin
  2px - smart input border-width
  (3rem - 20px) / 2 - block indent to center it in the field
  */
  right: calc(8rem + var(--stack-panel-toggle-width));

  padding: 0;

  font-size: 14px;
  line-height: 1.43;

  .terminal-generation__time,
  .terminal-generation__postfix {
    color: #cccccc;
    font-size: inherit;
  }

  .terminal-generation__time::before,
  .terminal-generation__time::after {
      display: none;
    }

  .terminal-generation__time:empty ~ .terminal-generation__postfix {
    display: none !important;
  }
}

.terminal__header:not(.use-simple-search) {
  .terminal__go {
    right: calc(5.25rem + var(--stack-panel-toggle-width));
  }

  .terminal-generation {
    right: calc(7.5rem + var(--stack-panel-toggle-width));
  }
}

.terminal-query__btn.btn-go,
.terminal-generation__refresh.btn-reload {
  display: none;
}

.side-panel__header {
    margin-bottom: 3.125rem;
}

/* hide elements */
.twt.add-node,
.twt.post-node:not(.ss__smart-input__post-node),
.twt.post-node > *:not(.ss__smart-input__node-actions) {
  display: none !important;
}

.free-multi-line-text,
.telegramm-text {
  margin-bottom: 1em;
}

.telegramm-text .value br {
  content: ' ';
}

.telegramm-text .value br::after {
  content: ' ';
}

.free-multi-line-text .value {
  height: 1em;
}

.telegramm-text .value {
  height: 1.6em;
}

.twt.scope {
  display: inline;
  flex-wrap: wrap;
  max-width: 100%;
  line-height: 2;
}

.collapsed .value {
  display: none;
}

.collapsed::after {
  content: "\00a0" attr(data-twtss-collapsed-value);
  cursor: text;
  vertical-align: top;
}

.ss__segment-two-letter-status {
  line-height: 22.4px;

  &__notifications {
    margin-top: 1rem;
  }
}

.ss__rpl-with-params {
  line-height: 22.4px;
}
