/**
* TOURISTS
*/

.tourists-list {
  &.screen-sect__details {
    padding: 0 10px 10px;
  }

  &__psgr {
    margin-bottom: 10px;
  }

  &__psgr:last-child {
    margin-bottom: 0;
  }

  &__errors {
    font-size: var(--font-size-m);
  }

  &__upload {
    width: 30rem;
    margin-left: 1.5rem;
  }

  .screen-sect__details & {
    padding: 0;
  }
}

.panel_tourist {
  padding: 10px;
  align-items: center;

  &.passenger_form {
    padding-bottom: 0;
    align-items: normal;
  }

  &.passenger_error {
    padding-bottom: 16px;
  }

  .panel__info-flex {
    padding: 0;
  }

  & .popover {
    width: auto;
    margin-right: 10px;
  }

  .popover__icon {
    margin: 0;
    width: 36px;
    height: 36px;
    line-height: 37px;
    font-size: 17px;
  }

  & .popover__body {
    left: -6px;
    padding: 5px 10px 5px 70px;
  }

  &.passenger_error .popover__body {
    padding: 7px 10px 7px 70px;
  }

  & .popover__switcher {
    padding-left: 0.125rem;
  }

  & .panel__control_disabled {
    width: 3.5rem;
    padding-left: 0.375rem;
  }

  .btn-add-contact,
  .btn-add-ssr {
    text-transform: uppercase;
    font-size: 10px;
    margin-left: 22px;

    &:hover {
      text-decoration: none;
      color: #333;
    }
  }
}

.tourist-gender {
  flex: 0 0 auto;
  margin-right: 10px;

  &__switch {
    min-width: 2.5rem;
  }
}

.tourist-name {
  max-width: 29rem;
  flex: 0 0 auto;
  white-space: nowrap;
  text-overflow: ellipsis;

  &__text {
    font-weight: bold;
    font-size: var(--font-size-md);

    text-transform: uppercase;
  }

  & .input {
    padding-top: 0.9375rem;
  }
}

.tourist-dob {
  flex: 0 0 auto;
  white-space: nowrap;

  &__text {
    font-weight: normal;
    font-size: 14px;
    max-width: 100px;
  }

  & .input {
    padding-top: 0.75rem;
  }
}

.tourist-doc {
  flex: 0 0 auto;
  white-space: nowrap;
  margin-right: 10px;

  &__text {
    font-weight: normal;
    font-size: 14px;
    text-transform: uppercase;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  &__type {
    position: absolute;
    bottom: -0.9rem;
    left: 0;
    white-space: nowrap;
    font-size: 0.7em;
  }

  & .input {
    padding-top: 0.75rem;
  }
}

.passenger_form .tourist-doc__type{
  bottom: auto;
  top: -0.1rem;
  font-size: 0.9em;
  text-transform: lowercase;
}

.tourist-country {
  flex: 0 0 auto;
  margin-right: 10px;

  &__text {
    font-weight: normal;
    font-size: var(--font-size-m);
    text-transform: uppercase;
  }

  & .input {
    padding-top: 0.75rem;
  }
}

.tourist-ssr {
  &__text {
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: left;
    margin-left: 5px;
    padding: 2px 1px;
    height: auto;
    max-width: calc(100% - 5px);
  }
}

.tourist-doc-expire {
  flex: 0 0 auto;

  &__text {
    font-weight: normal;
    font-size: var(--font-size-m);
  }

  & .input {
    padding-top: 0.75rem;
  }
}

.tourist-cat {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tourist-seat {
  flex: 0 0 auto;
  white-space: nowrap;

  &__text {
    font-weight: normal;
    font-size: 10px;
    text-transform: uppercase;
  }
}

.tourist-seat-check {
  flex: 0 0 auto;

  & .checkbox_type_switch {
    display: flex;
    flex-direction: column;
    min-width: 65px;

    & .checkbox__text {
      flex: 0 0 auto;
      white-space: nowrap;
      font-weight: normal;
      font-size: 12px;
    }
  }
}

.tourist-back,
.tourist-forward {
  flex: 0 0 auto;
  padding-left: 0.3rem;

  font-size: var(--font-size-base);
  white-space: nowrap;
  color: var(--brand-secondary);
  text-transform: uppercase;

  &__departure {
    text-align: right;
    width: 3.5em;
    margin-right: .25em;
    display: inline-block;
  }

  &__arrival {
    text-align: left;
    width: 3.5em;
    margin-left: .25em;
    display: inline-block;
  }
}

.tourist-rbd {
  flex: 0 0 auto;
  margin-right: 10px;

  &__bus {
    background-color: var(--bg-tag-business);
    border-color: var(--bg-tag-business);
  }
}

.tourist-ticket {
  flex: 0 0 auto;

  &__locator {
    font-weight: bold;
  }

  &__info {
    font-weight: normal;
    font-size: var(--font-size-xxs);
  }

  &__text {
    color: #000;
  }
}

.tourist-tickets {
  height: 2.25rem;
  display: flex;
  flex-direction: column;
}

.tourist-badges {
  flex: 1 1 auto;
  text-align: right;

  &__text {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;

    font-size: var(--font-size-m);
  }

  &__text.select__control {
    text-align: right;
    font-size: 11px;
    text-transform: uppercase;
    padding-right: 14px;
  }

  & .input {
    padding-top: 0.75rem;
  }
}

.tourist-noallotment {
  color: red;
}

.flt-contract-num {
  margin-bottom: 4px;
}
