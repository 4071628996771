.scroll {
  &_up, &_down {
    display: none;
    cursor: pointer;
    position: absolute;
    right: 65px;
    width: 30px;
    height: 30px;
    border-bottom: 0.25rem solid var(--color-light);
    border-right: 0.25rem solid var(--color-light);

    &:hover {
      border-color: var(--brand-highlight);
    }
  }

  &_up {
    top: calc(40px + 5%);
    transform: rotate(-135deg);
  }

  &_down {
    bottom: 5%;
    transform: rotate(45deg);
  }
}
