/*
* --------------------------------------------------
* This file contains all styles related to the main parts of
* the site/application.This file contains very basic styles.
* --------------------------------------------------
*/

/**
* Logo in the side panel
*/
.logo_sp {
  display: block;
  width: 100%;
  height: auto;
}

/**
* MAIN wrapper of terminal app
* which contains all screens
*/
.terminal {
  display: flex;
  flex-direction: column;
}

.terminal__header {
  position: relative;

  flex: 0 0 auto;
  margin-left: 3.5rem;

  background: var(--color-lighter);

  z-index: calc(var(--z-terminal-header) + 1);

  /*background: linear-gradient(to bottom, #eee 0%, var(--color-lighter) 100%);*/
}

.terminal__header:before {
  display: block;
  content: '';
  position: absolute;
  left: -3.5rem;
  top: 0;
  height: 100%;
  width: 3.5rem;
  background-color: var(--color-lighter);
}

.terminal__header_indent-left {
  /* Reserve place for side panel switcher on the main screen */
  padding-left: 2.5rem;
}

.terminal__header__active {
  z-index: calc(var(--z-navigation) + 1);
}

.terminal__header--history {
  .terminal-query__field,
  .ss__smart-input {
    background-color: #eeeeee;
  }

  .terminal-generation__time {
    color: var(--brand-highlight);
  }
}

.terminal__header-wrapper {
  display: flex;
  flex-grow: 1;
  width: 100%;
}

.terminal__body {
  position: relative;

  flex: 1 1 100%;
}

.screens {
  position: relative;

  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
}

.screens__screen {
  /* One screen of terminal */
  flex: 1 1 100%;
}

/**
* One screen of terminal app
*/
.screen {
  display: flex;
  flex: 1 1 100%;
  overflow: hidden;
  flex-direction: column;

  &_old:after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    background: rgba(66, 66, 66, 0.3);
    top: 0;
    z-index: var(--z-overlay);
  }

  @media (--vp-min-xxs) {
    min-width: 48rem;
  }

  &:not(.screen_hidden) ~ &:not(.screen_hidden) {
    border-left: 1px solid #888;
  }

  &__header {
    position: relative;
    flex: 0 0 auto;
    padding: 0 calc( 0.875rem + var(--stack-panel-toggle-width) ) 0 0.625rem;
    border-bottom: 1px solid var(--color-light-extra);
    background: linear-gradient(to bottom, var(--color-lighter) 0%, var(--color-disabled) 100%);

    @media print {
      background: none;
    }

    @media (min-width: 640px) and (min-height: 360px) {
      padding-right: 0.75rem;
    }

    &_indent-left {
    /* Reserve place for side panel switcher on the main screen */
      padding-left: 2.5rem;
    }

    &_with-search {
    /* Background in case there are separate query field for each screen */
      background: linear-gradient(to bottom, #eee 0%, var(--color-disabled) 100%);
    }

    &_with-btn {
      position: relative;

      .screen__header-left {
        display: flex;
        align-items: center;
      }
    }
  }

  &__header-btn {
    position: absolute;
    top: 0;
    right: 1rem;

    margin-top: 0.3125rem;
  }

  &__header-sandwich {
    display: inline-block;
  }

  &__sub-header {
    position: relative;
    flex: 0 0 auto;

    padding: 1rem;
  }

  &__header-cont {
  /* Block of information under the main query field */
    display: flex;
    align-items: center;
    min-height: 3rem;
    padding-top: 0.5rem;
    padding-bottom: 0.375rem;

    & .text-right {
      white-space: nowrap;
    }
  }

  & .filtered-count {
    display: inline-block;
    padding-right: 1rem;
    font-size: var(--font-size-m);

    &__num {
      font-weight: bold;
      font-size: var(--font-size-md);
    }
  }

  &__name {
    display: inline-flex;
    align-items: center;
    margin: 0;
    color: #333;
    font-weight: bold;
    font-family: var(--font-family-sans-serif-arial);
    font-size: 24px;
    text-transform: uppercase;

    .screen_index & {
    /* Application title on the main screen */
      display: block;
      text-align: center;
      color: var(--brand-primary);
    }
  }

  &__name-title {
    margin-right: 20px;

    @media (min-resolution: 1.2dppx) and (max-resolution: 1.25dppx) {
     .page_with-sidebar & {
       font-size: 22px;
      }
    }
  }

  &__name-subtitle {
    font-weight: normal;
    margin-right: 16px;

    @media (min-resolution: 1.2dppx) and (max-resolution: 1.25dppx) {
     .page_with-sidebar & {
       font-size: 18px;
      }
    }
  }

  &__header-mid {
    padding-top: 0.5rem;
    padding-bottom: 0.1rem;
    text-align: center;
  }

  &__header-right {
    padding-top: 0.2rem;
    padding-bottom: 0.1rem;
    text-align: right;
  }

  &__title {
    text-align: inherit;
    color: #000;
    font-size: var(--font-size-md);
    font-weight: bold;
    line-height: 1;
  }

  &__subtitle {
    text-align: inherit;
    color: #000;
    font-size: var(--font-size-base);
    font-weight: bold;
  }

  &__pretitle {
    text-align: inherit;
    color: #000;
    font-size: var(--font-size-base);
    font-weight: normal;
    text-transform: uppercase;
  }

  &__error {
    color: red;
    justify-content: center;

    &-info {
      color: black;
    }
    &-warn {
      color: yellow;
    }

    &-text {
      border-radius: 9px;
      background: #ffffff;
      padding: 2.5rem 6.25rem;
      font-size: 1.1rem;
    }
  }

  &__body {
    position: relative;
    flex: 1 1 100%;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 9px;
      height: 9px;
    }
  }

  &__body.schedule__fne-body {
    justify-content: center;
  }

  &__body.schedule__fne-body .panel {
    padding: 2.5rem 6.25rem;
	  font-size: 1.1rem;
  }

  &__body-cont {
    padding: .75rem .675rem;
  }

  &__scroller {
    &_offset-bottom {
      padding-bottom: 2rem;
    }
  }

  &__footer {
    position: relative;
    flex: 0 0 auto;
    align-items: center;
    padding: 0.625rem 1.25rem 1.875rem;
    box-shadow: var(--footer-box-shadow);

    .btn-back {
      width: auto;
    }
  }

  &_hidden {
    display: none !important;
  }

  &__hide-on-fix {
    position: relative;
    overflow-y: auto;
    max-height: 100vh;

    transition: max-height 0.2s ease;
    transform-origin: 50% 0 0;
  }

  &__sub-header,
  &__body,
  &__footer {
    transition: all 0.2s ease;
    transform-origin: 50% 0 0;
  }

  &_panels-fixed {
    & .screen__hide-on-fix {
      overflow: hidden;
      max-height: 0;
    }

    & .screen__sub-header {
      padding-bottom: 0.5rem;

      &:after {
        position: absolute;
        z-index: calc( var(--z-main-content) + 1 );
        top: 100%;
        right: 0;
        left: 0;

        display: block;
        height: 2rem;

        content: '';

        background: linear-gradient(to bottom, var(--color-lighter) 0%, var(--body-bg-transparent) 100%);
      }
    }

    & .screen__scroller {
      padding-top: 2rem;
    }
  }
}

.list-header__head {
  font-size: .875rem;
  font-weight: bold;
  text-shadow: 1px 1px 0 #fff;
}

.list-header__head_ml24 {
  margin-left: 24px;
}

@media (--vp-min-xxs) {
  .page_side-bars:not(.app-mode-full) .screen {
    min-width: calc( 48rem - var(--side-panel-width) );
  }
}

@media print {
  .terminal__header,
  .screen__footer,
  .stack-panel {
    display: none!important;
  }
}
