.screen__busy .btn-undoredo {
  pointer-events: none;
  opacity: .2;
}

.btn-undoredo {
  display: inline-block;
  margin-left: 20px;
}

.btn-undo,
.btn-redo
{
  padding: .5em;
  background-position-y: 3px;
  width: 3em;
  height: 2em;
  border: none;
  cursor: pointer;

  &:disabled {
    opacity: .4;
    pointer-events: none;
  }
}

.btn-undo {
  background: url(tais-styles/svg/main-footer__undo.svg) 50% 50% no-repeat;
  background-size: contain;
}

.btn-redo {
  background: url(tais-styles/svg/main-footer__redo.svg) 50% 50% no-repeat;
  background-size: contain;
}
