.json-format {
  outline: 1px solid #ccc;
  padding: 5px;
  margin: 5px;
  font-size: var(--font-size-xs);
  min-height: 100px;
}

.json-format .string {
  color: green;
}

.json-format .number {
  color: darkorange;
}

.json-format .boolean {
  color: blue;
}

.json-format .null {
  color: magenta;
}

.json-format .key {
  color: darkblue;
}
