/*
* --------------------------------------------------
* Styles for translate.
* --------------------------------------------------
*/

.translate-upload-form {
    position: fixed;
    right: 100%;
    visibility: hidden;
}

.translate-dialog {
  width: auto !important;
  .dialog-message__text {
    white-space: pre-line;
  }
}

body.l10n-mark-untranslated-enabled .msg-untranslated {
  color: #666 !important;
  background-color: rgba(253, 149, 63, .8) !important;
}
