.dow-day{
  text-transform: uppercase;
}

.dow-day-holder {
  display: inline-block;
  vertical-align: top;
  min-width: 1.35em;
}

.dow-day__miss:after {
  display: inline-block;
  content: '\2014';
}

.dow-day,
.dow-day__miss:after {
  min-width: 1.35em;
  text-align: center;
}
