/*
* --------------------------------------------------
* This file contains all styles for input component.
* --------------------------------------------------
*/

/*doc
  name: Input field
  description: Text input and its variations
  code: |
    <label class="input">
      <input class="input__control" type="text" placeholder="Placeholder" />
      <span class="input__text">Text input field</span>
    </label>
    <br />
    <label class="input input_s">
      <input class="input__control" type="text" placeholder="Placeholder" />
      <span class="input__text">Input size s</span>
    </label>
    <label class="input input_m">
      <input class="input__control" type="text" placeholder="Placeholder" />
      <span class="input__text">Input size m</span>
    </label>
    <label class="input input_l">
      <input class="input__control" type="text" placeholder="Placeholder" />
      <span class="input__text">Input size l</span>
    </label>
    <br />
    <label class="input input_has-clear">
      <span class="input__box">
        <input class="input__control" type="text" placeholder="Placeholder" />
        <span class="input__clear">&times;</span>
      </span>
      <span class="input__text">Input with "clear" button</span>
    </label>
    <label class="input input_primary">
      <input class="input__control" type="text" placeholder="Placeholder" />
      <span class="input__text">Input primary</span>
    </label>
    <label class="input input_secondary">
      <input class="input__control" type="text" placeholder="Placeholder" />
      <span class="input__text">Input secondary</span>
    </label>
    <br />
    <label class="input input_has-clear input_s input_inline input_primary">
      <span class="input__text">Input inline</span>
      <span class="input__box">
        <input class="input__control" type="text" placeholder="Placeholder" />
      </span>
    </label>
*/

.input {
  position: relative;
  display: inline-block;
  text-align: left;

  &_width_available {
    width: 100%;
  }

  &__box {
    position: relative;
    display: block;
  }

  &__control {
    position: relative;

    display: inline-block;
    width: 100%;
    height: 1.5rem;
    padding: 0.125rem 0 0.25rem;

    line-height: 1;

    font-weight: bold;
    font-size: var(--font-size-md);
    font-family: inherit;

    text-align: inherit;

    color: #000;

    background: transparent;

    border: solid var(--color-disabled);
    border-width: 0 0 1px;

    outline: none;

    &:focus {
      border-color: var(--brand-secondary);
      transition: all .3s;
    }

    &:focus + .input__text {
      color: var(--brand-secondary);
      transition: color .3s;
    }

    &.text-center {
      text-align: center;
    }

    &_hidden {
      position: absolute;
      z-index: -1;

      top: 0;
      left:0;
      opacity: 0;
    }

    &::-webkit-input-placeholder {
      opacity: 1;
      font-style: italic;
      font-weight: normal;
      color: var(--color-light-extra);
    }
    &:-moz-placeholder {
      opacity: 1;
      font-style: italic;
      font-weight: normal;
      color: var(--color-light-extra);
    }
    &::-moz-placeholder {
      opacity: 1;
      font-style: italic;
      font-weight: normal;
      color: var(--color-light-extra);
    }
    &:-ms-input-placeholder {
      opacity: 1;
      font-style: italic;
      font-weight: normal;
      color: var(--color-light-extra);
    }

    /* Input with large placeholder */
    .input_lg & {
      height: 1.875rem;
      padding-top: 0.3125rem;
      padding-bottom: 0.3125rem;
      line-height: 1.2;
      font-size: var(--font-size-md);

      &::-webkit-input-placeholder {
        line-height: 1.66;
        font-size: var(--font-size-lg);
        color: var(--color-light);
      }
      &:-moz-placeholder {
        line-height: 1.66;
        font-size: var(--font-size-lg);
        color: var(--color-light);
      }
      &::-moz-placeholder {
        line-height: 1.66;
        font-size: var(--font-size-lg);
        color: var(--color-light);
      }
      &:-ms-input-placeholder {
        line-height: 1.66;
        font-size: var(--font-size-lg);
        color: var(--color-light);
      }
    }
  }

  &__control_sec {
    height: auto;
    padding: 3px 2px;
    border-radius: 3px 3px 0 0;
    font-size: 17px;
  }

  &__control_sec:focus {
    background-color: #eee;
  }

  &__text {
    display: block;
    line-height: 1;
    font-size: var(--font-size-xs);

    color: var(--color-light-extra);

    &_show_on-error {
      visibility: hidden;
    }
  }

  &__clear {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;

    display: block;
    width: 1.125rem;

    overflow: hidden;
    cursor: pointer;
    text-indent: -9999px;

    &:before,
    &:after {
      position: absolute;
      top: 50%;
      left: 50%;

      display: block;
      height: 0.125rem;
      width: 0.625rem;
      margin-top: -0.0625rem;
      margin-left: -0.375rem;

      content: '';

      background: var(--color-light-extra);

      transform-origin: 50% 50% 0;
    }

    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(135deg);
    }
  }

  &_has-clear {
    & .input__control {
      padding-right: 1.25rem;
    }
  }

  &__icon {
    display: inline-block;
  }

  /* variations */
  &_inline {
    & .input__control,
    & .input__box,
    & .input__text {
      display: inline-block;
      vertical-align: baseline;
    }
  }

  /* size */
  &_xs {
    font-size: var(--font-size-xxs);
    line-height: 1;

    & .input__control {
      height: 0.75rem;
      padding-top: 0;
      padding-bottom: 0;
    }

    & .input__control,
    & .input__box,
    & .input__text {
      font-size: inherit;
      line-height: inherit;
    }
  }

  &_s {
    font-size: var(--font-size-base);
    line-height: 1.33;

    & .input__control {
      height: 1.125rem;
      padding-bottom: 0.125rem;
    }

    & .input__control,
    & .input__box,
    & .input__text {
      font-size: inherit;
      line-height: inherit;
    }
  }

  &_m {
    font-size: var(--font-size-m);
    line-height: 1.286;

    & .input__control,
    & .input__box,
    & .input__text {
      font-size: inherit;
      line-height: inherit;
    }
  }

  &_l {
    font-size: var(--font-size-md);
    line-height: 1;

    & .input__control,
    & .input__box,
    & .input__text {
      font-size: inherit;
      line-height: inherit;
    }
  }

  &_s,
  &_m,
  &_l {
    vertical-align: text-bottom;

    & .input__control {
      &::-webkit-input-placeholder {
        font-size: var(--font-size-m);
      }
      &:-moz-placeholder {
        font-size: var(--font-size-m);
      }
      &::-moz-placeholder {
        font-size: var(--font-size-m);
      }
      &:-ms-input-placeholder {
        font-size: var(--font-size-m);
      }
    }
  }

  /* color */
  &_primary {
    & .input__control {
      text-transform: uppercase;
      color: var(--brand-highlight);
      border-bottom-color: var(--brand-highlight);
    }

    & .input__text {
      text-transform: uppercase;
      font-weight: bold;
      color: var(--brand-primary);
    }
  }

  &_secondary {
    & .input__control {
      text-transform: uppercase;
      color: var(--brand-highlight-sec);
    }
  }

  &_primary,
  &_secondary {
    & .input__control {
      &::-webkit-input-placeholder {
        color: var(--color-light-extra);
        font-style: italic;
      }
      &:-moz-placeholder {
        color: var(--color-light-extra);
        font-style: italic;
      }
      &::-moz-placeholder {
        color: var(--color-light-extra);
        font-style: italic;
      }
      &:-ms-input-placeholder {
        color: var(--color-light-extra);
        font-style: italic;
      }
    }
  }

  &_date {
    white-space: nowrap;

    & .input__icon {
      display: inline-block;
      width: 1.375rem;
      height: 1.25rem;
      margin-bottom: -1px;

      overflow: hidden;
      cursor: pointer;
      text-indent: -9999px;

      vertical-align: bottom;

      background: transparent 50% 50% no-repeat;
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='21' viewBox='0 0 20 21' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.35 0C3.87 0 3.48 0.4 3.48 0.88L3.48 1.75 0.87 1.75C0.37 1.75 0 2.12 0 2.63L0 5.69 20 5.69 20 2.63C20 2.12 19.63 1.75 19.13 1.75L16.52 1.75 16.52 0.88C16.52 0.4 16.13 0 15.65 0L14.78 0C14.31 0 13.91 0.4 13.91 0.88L13.91 1.75 6.09 1.75 6.09 0.88C6.09 0.4 5.69 0 5.22 0L4.35 0 4.35 0ZM4.35 0.88L5.22 0.88 5.22 3.5 4.35 3.5 4.35 0.88 4.35 0.88ZM14.78 0.88L15.65 0.88 15.65 3.5 14.78 3.5 14.78 0.88 14.78 0.88ZM0 6.56L0 20.13C0 20.63 0.37 21 0.87 21L19.13 21C19.63 21 20 20.63 20 20.13L20 6.56 0 6.56 0 6.56ZM4.35 9.19L6.52 9.19 6.52 11.38 4.35 11.38 4.35 9.19 4.35 9.19ZM7.39 9.19L9.57 9.19 9.57 11.38 7.39 11.38 7.39 9.19 7.39 9.19ZM10.44 9.19L12.61 9.19 12.61 11.38 10.44 11.38 10.44 9.19 10.44 9.19ZM13.48 9.19L15.65 9.19 15.65 11.38 13.48 11.38 13.48 9.19 13.48 9.19ZM4.35 12.25L6.52 12.25 6.52 14.44 4.35 14.44 4.35 12.25 4.35 12.25ZM7.39 12.25L9.57 12.25 9.57 14.44 7.39 14.44 7.39 12.25 7.39 12.25ZM10.44 12.25L12.61 12.25 12.61 14.44 10.44 14.44 10.44 12.25 10.44 12.25ZM13.48 12.25L15.65 12.25 15.65 14.44 13.48 14.44 13.48 12.25 13.48 12.25ZM4.35 15.31L6.52 15.31 6.52 17.5 4.35 17.5 4.35 15.31 4.35 15.31ZM7.39 15.31L9.57 15.31 9.57 17.5 7.39 17.5 7.39 15.31 7.39 15.31ZM10.44 15.31L12.61 15.31 12.61 17.5 10.44 17.5 10.44 15.31 10.44 15.31ZM13.48 15.31L15.65 15.31 15.65 17.5 13.48 17.5 13.48 15.31 13.48 15.31Z' fill='%230054d5'/%3E%3C/svg%3E");
      background-size: 1.25rem auto;

      @media print {
        display: none;
      }
    }

    &.input_disabled .input__icon {
      cursor: auto;
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='21' viewBox='0 0 20 21' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.35 0C3.87 0 3.48 0.4 3.48 0.88L3.48 1.75 0.87 1.75C0.37 1.75 0 2.12 0 2.63L0 5.69 20 5.69 20 2.63C20 2.12 19.63 1.75 19.13 1.75L16.52 1.75 16.52 0.88C16.52 0.4 16.13 0 15.65 0L14.78 0C14.31 0 13.91 0.4 13.91 0.88L13.91 1.75 6.09 1.75 6.09 0.88C6.09 0.4 5.69 0 5.22 0L4.35 0 4.35 0ZM4.35 0.88L5.22 0.88 5.22 3.5 4.35 3.5 4.35 0.88 4.35 0.88ZM14.78 0.88L15.65 0.88 15.65 3.5 14.78 3.5 14.78 0.88 14.78 0.88ZM0 6.56L0 20.13C0 20.63 0.37 21 0.87 21L19.13 21C19.63 21 20 20.63 20 20.13L20 6.56 0 6.56 0 6.56ZM4.35 9.19L6.52 9.19 6.52 11.38 4.35 11.38 4.35 9.19 4.35 9.19ZM7.39 9.19L9.57 9.19 9.57 11.38 7.39 11.38 7.39 9.19 7.39 9.19ZM10.44 9.19L12.61 9.19 12.61 11.38 10.44 11.38 10.44 9.19 10.44 9.19ZM13.48 9.19L15.65 9.19 15.65 11.38 13.48 11.38 13.48 9.19 13.48 9.19ZM4.35 12.25L6.52 12.25 6.52 14.44 4.35 14.44 4.35 12.25 4.35 12.25ZM7.39 12.25L9.57 12.25 9.57 14.44 7.39 14.44 7.39 12.25 7.39 12.25ZM10.44 12.25L12.61 12.25 12.61 14.44 10.44 14.44 10.44 12.25 10.44 12.25ZM13.48 12.25L15.65 12.25 15.65 14.44 13.48 14.44 13.48 12.25 13.48 12.25ZM4.35 15.31L6.52 15.31 6.52 17.5 4.35 17.5 4.35 15.31 4.35 15.31ZM7.39 15.31L9.57 15.31 9.57 17.5 7.39 17.5 7.39 15.31 7.39 15.31ZM10.44 15.31L12.61 15.31 12.61 17.5 10.44 17.5 10.44 15.31 10.44 15.31ZM13.48 15.31L15.65 15.31 15.65 17.5 13.48 17.5 13.48 15.31 13.48 15.31Z' fill='%23cccccc'/%3E%3C/svg%3E");
    }
  }

  &_error {
    & .input__control {
      color: var(--brand-warning);
      border-bottom-color: var(--brand-warning);

      &::-webkit-input-placeholder {
        color: var(--brand-warning);
      }
      &:-moz-placeholder {
        color: var(--brand-warning);
      }
      &::-moz-placeholder {
        color: var(--brand-warning);
      }
      &:-ms-input-placeholder {
        color: var(--brand-warning);
      }
    }
    & .input__text {
      color: var(--brand-warning);

      &_show_on-error {
        visibility: visible;
      }

      &_hide_on-error {
        display: none;
      }
    }
  }

  &_disabled .input__control,
  &__control:disabled {
    color: var(--color-medium);
    border-bottom-color: var(--color-disabled);
  }
}

select.input__control {
  border-radius: 0;
  appearance: none;
}

.input .input_prefix {
  position: absolute;
  bottom: 0.70rem;
  font-weight: bold;
  font-size: var(--font-size-md);
}

.input .input_prefix + .input_with-prefix {
  padding-left: 2rem;
}
