.recent-pnr {
  padding-right: 2rem;

  &__header {
    height: 1rem;
    font-size: 0.625rem;
    color: var(--brand-secondary);
    text-transform: uppercase;
  }

  &__title-locator {
    float: left;
    width: 12%;
  }

  &__title-passenger {
    float: left;
    width: 28%;
    padding-left: 3px;
  }

  &__title-flight {
    float: left;
    width: 6%;
    padding-left: 3px;
  }

  &__title-status {
    float: right;
    width: 12%;
    padding-right: 3px;
  }

  &__item {
    height: 5rem;
    min-width: 55rem;
    margin-top: 0.5rem;
    border: 3px solid var(--color-disabled);
    border-radius: 0.625rem;
    cursor: pointer;

    &:hover {
      border-color: var(--brand-highlight-sec);
    }
  }

  &__icon {
    float:left;
    width: 5%;
    min-width: 3rem;
    margin-top: 0.5rem;

  }

  &__icon > * {
    margin-left: 0.6rem;
  }

  &__locator {
    min-width: 7rem;
    margin-top: 1.55rem;
    margin-left: 4rem;
    font-size: 1.125rem;
    text-transform: uppercase;
    font-weight: bold;
  }

  &__passenger {
    width: 28%;
    min-width: 12rem;
    margin-top: 1.55rem;
  }

  &__name {
    margin-right: 0.5rem;
    font-size: 1.125rem;
    text-transform: uppercase;
    font-weight: bold;
    white-space: nowrap;
  }

  &__more {
    margin-top: 0.22rem;
    font-size: 0.75rem;
    color: var(--brand-secondary);
  }

  &__flight {
    float:left;
    width: 6%;
    margin-top: 1.05rem;
    text-transform: uppercase;
  }

  &__number {
    font-size: 1.125rem;
    font-weight: bold;
    white-space: nowrap;
  }

  &__plane {
    width: 3.7rem;
    font-size: 0.75rem;
    text-align: center;
    color: var(--brand-secondary);
  }

  &__date {
    float:left;
    width: 5%;
    min-width: 3rem;
    margin-top: 1rem;
    text-transform: uppercase;
    text-align: center;
  }

  &__day {
    width: 4.5rem;
    font-size: 1.125rem;
    line-height: 1.1rem;
    font-weight: bold;
  }

  &__month {
    width: 4.5rem;
    font-size: 0.75rem;
    line-height: 0.9rem;
    font-weight: bold;
  }

  &__dow {
    width: 4.5rem;
    font-size: 0.625rem;
    color: var(--brand-secondary);
  }

  &__departure,
  &__arrival {
    float:left;
    width: 7%;
    margin-top: 1.05rem;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
  }

  &__point {
    font-size: 1.125rem;
  }

  &__time {
    font-size: 0.75rem;
    text-align: center;
  }

  &__info {
    float:left;
    width: 5%;
    min-width: 5rem;
    margin-top: 1.9rem;
    text-align: center;
  }

  &__arrow {
    position: relative;
    width: 4.5rem;
    height: 2px;
    margin: 0.25rem auto;
    background: var(--brand-secondary);
  }

  &__arrow::before {
    position: absolute;
    top: 50%;
    right: 0;
    display: block;
    box-sizing: border-box;
    width: 0.625rem;
    height: 0.625rem;
    margin-top: -0.3125rem;
    content: '';
    transform: rotate(45deg);
    border: 0.125rem solid;
    border-color: var(--brand-secondary) var(--brand-secondary) transparent transparent;
  }

  &__duration {
    font-size: 0.625rem;
    color: var(--brand-secondary);
  }

  &__count {
    float:left;
    margin-top: 1.85rem;
    margin-left: -0.5rem;
    font-size: 0.75rem;
    color: var(--brand-secondary);
  }

  &__status {
    float: right;
    width: 12%;
    margin-top: 1.85rem;
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;

    &_completed {
      color: #1baa0e;
    }

    &_canceled {
      color: #ff0000;
    }

    &_returned {
      color: #989898;
    }
  }
}

.recent-pnr__header {
  display: flex;
  justify-content: space-between;
}

.recent-pnr__header > div {
  width: 10rem;
  text-align: center;
}

.recent-pnr__item {
  display: flex;
  justify-content: space-between;
}

.recent-pnr__item > div {
  width: 10rem;
}
