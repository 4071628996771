/*
* --------------------------------------------------
* This file contains very basic styles.
* --------------------------------------------------
*/
html {
  font-size: 8px;
  background: #000000;
}

@media (min-width: 320px) and (min-height: 240px) {
  html {
    font-size: 12px;
  }
}

@media (min-width: 640px) and (min-height: 360px) {
  html {
    font-size: 16px;
  }
}

@media print {
  // for Firefox multipages print
  html {
    overflow: visible !important;
  }

  body {
    height: auto !important;
  }

  .html_print-font-increased {
    font-size: 20px;
  }
}


html, body {
  height: 100%;
}

.page {
  display: flex;
  min-width: 900px;
  margin-right: auto;
  margin-left: auto;
  overflow-x: auto;

  background: var(--color-lighter);
}

.page.page_ovxh {
  overflow-x: hidden;
}

.loading-done .page_non-expert .progress-bar,
.loading-done .page_non-expert .progress-bar__loader {
  height: 0.5rem;
}

.loading-done .page_non-expert .progress-bar__text {
  display: none;
}

.loading-done .page_non-expert .main-loader__header {
  border: none;
  z-index: 7000;
}

.page__panel {
  flex: 0 0 auto;
  height: 100%;
}

.page__body {
  position: relative;

  flex: 1 1 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.page__body_history .screen .screen__header {
  padding-top: 0.875rem;
}

.page__body_history .old-screen-header {
  position: absolute;
  top: 0;
  right: 20px;

  margin: 0;

  font-weight: bold;
  font-size: 0.75rem;
  color: #333333;
  text-transform: uppercase;
}

.page__body_history .terminal-generation .terminal-generation__time,
.page__body_history .terminal-generation .terminal-generation__postfix {
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.43;
  color: rgba(255, 0, 0, 0.5);

  cursor: default;
}

.page__body_history .terminal-generation .terminal-generation__time:before,
.page__body_history .terminal-generation .terminal-generation__time:after {
  display: block;
}

.page__body_history .terminal-generation .terminal-generation__time:before {
  color: #ffffff;

  background-color: rgba(255, 0, 0, 0.6);
}

.page__body_history .terminal-generation .terminal-generation__time:after {
  border-color: transparent transparent transparent rgba(255, 0, 0, 0.6);
}

* {
  box-sizing: border-box;
}

*:before,
*:after {
  box-sizing: border-box;
}

/**
 * Basic styles for links
 */
a {
  color: var(--brand-highlight);
  text-decoration: none;
}

/**
* OVERLAY
* under pop-ups
*/
.page-overlay_active {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: var(--z-overlay);

  display: block;

  background: var(--bg-overlay);
}

.page-overlay_transparent {
  background: transparent;
}

.uppercase {
  text-transform: uppercase;
}

[data-twt-execute=true] {
  cursor: pointer;
}

ins {
  text-decoration: none;
}

mark {
  background-color: transparent;
}

input,
select,
option,
button,
::-webkit-input-placeholder,
::-moz-placeholder {
  font-family: inherit;
}

fieldset {
  margin: 0;
  padding: 0;

  border: none;
}

dialog {
  padding: 0;
  border: none;
}
