/*
* --------------------------------------------------
* This file contains all styles for different sections of screens.
* --------------------------------------------------
*/

.screen-sect {
  transition: background-color .3s;

  @media print {
    display: none;

    &.screen-sect_active {
      display: block;
    }
  }

  &_is_animated {
    background-color: var(--color-light-extra);
    transition: background-color .3s;
  }

  &_is_hidden {
    visibility: hidden;
    transition: background-color .3s, visibility .2s;

    .screen-sect__title:before {
      opacity: 0;
      transition: opacity .1s;
    }
  }

  & + & {
    border-top: 1px solid var(--color-light-extra);
  }

  &__header {
  /* Section header */
    min-height: 1.8rem;
    padding: 10px 0.9rem;

    background-color: var(--color-light-extra);
    border-top: 1px solid var(--color-lighter);
    transition: background-color .7s;

    &_nobg {
      background-color: transparent;
      border-top: none;
    }

    @media print {
      background-color: #fff;
    }

  }

  &__header_is_animated {
    background-color: var(--color-light);
    transition: background-color .5s;

    .screen-sect__title {
      color: #fff;
    }
  }


  &__title {
  /* Section title */
    display: inline-block;
    margin: 0 0.5rem 0 0;
    vertical-align: baseline;

    text-transform: uppercase;
    color: var(--color-medium);
    font-size: var(--font-size-m);
    font-weight: bold;
    line-height: 1.4;
  }

  &__switcher {
  /* Title-switcher of a screen section */
    cursor: pointer;

    &:before {
    /* Title-switcher caret */
      display: inline-block;
      margin-right: 0.4rem;

      vertical-align: baseline;
      content: '';

      transform: rotate(-90deg);
      transition: all .2s ease;

      border-top: 0.625rem solid var(--brand-secondary);
      border-right: 0.375rem solid transparent;
      border-left: 0.375rem solid transparent;

      @media print {
        display: none;
      }
    }

    .screen-sect_active > .screen-sect__header &:before {
      transform: rotate(0deg);
    }
  }

  &__btn_hdr {
  /* Button in a sections header */
    margin: 0.0625rem 0 0 0.3125rem;
    float: right;
  }

  &__btn_hdr_required {
    color: #fff;
    background-color: var(--brand-highlight);
    border-color: var(--brand-highlight);
  }

  &__link_hdr {
    vertical-align: middle;

    @media print {
      display: none;
    }
  }

  &__subtitle {
    display: inline-block;
    vertical-align: baseline;

    color: #fff;

    &_counter {
      min-width: 2.1em;
      padding: 0 0.5em;

      text-align: center;

      border-radius: var(--border-radius);
      background: var(--color-light);

      font-weight: bold;
    }
  }

  &__addinfo {
    display: inline-block;
    margin: 0 0.5rem 0 0;

    color: var(--color-medium);

    font-size: var(--font-size-base);
    font-weight: bold;
    line-height: 1.4;
  }

  &__content {
    min-height: 0.5rem;
    padding: 1.3rem 0.9rem;
  }

  &__details {
  /* Body of a section */
    max-height: 0;
    padding-right: 10px;
    padding-left: 10px;
    overflow: hidden;
    margin: 0;

    transition: height .5s ease;

    .screen-sect_active > & {
      max-height: none;
      padding-top: 10px;
      padding-bottom: 10px;
      overflow: visible;
    }
  }

  &__paddings {
    padding-right: 0.675rem;
    padding-left: 0.675rem;
  }

  & > .screen-sect__header > .screen-sect__hidden-wn-active {
    display: inline;
  }

  & > .screen-sect__header > .screen-sect__hidden-wn-active.grid {
    display: flex;
  }

  & > .screen-sect__header > .screen-sect__hidden-wn-active_nowrap {
     flex-wrap: nowrap;
  }


  &_active > .screen-sect__header > .screen-sect__hidden-wn-active,
  &_active > .screen-sect__header > .screen-sect__hidden-wn-active.grid
  {
    display: none;
  }

  & > .screen-sect__header > .screen-sect__visible-wn-active {
    display: none;
  }

  &_active > .screen-sect__header > .screen-sect__visible-wn-active {
    display: inline;
  }

  &_active > .screen-sect__header > .screen-sect__visible-wn-active.grid {
    display: flex;
  }

  /* Modifier 'rounded' */
  &_rounded {
    border-radius: var(--border-radius-xl) var(--border-radius-xl) 0 0;
    background-color: #eee;
    position: relative;
    transition: padding-bottom .5s ease, height .5s ease;

    &::after {
      pointer-events:none;
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;

      display: block;
      height: 1.625rem;

      content: '';

      background-image: linear-gradient(to bottom, rgba(144,144,144,0) 0%, rgba(204,204,204,1) 100%);

      @media print {
        background: none;
      }
    }

    & + & {
      margin-top: calc( -1 * var(--border-radius-xl) );
      border-top: none;
    }

    & > .screen-sect__header {
      padding-top: 0.4rem;
      background: none;
      background-color: transparent;
      border-top: none;
      transition: height .5s ease;
    }

    & > .screen-sect__header .screen-sect__title {
      font-size: var(--font-size-md);
    }

    &.screen-sect_active {
      padding-bottom: 1.875rem;
      transition: padding-bottom .5s ease;

      .screen-sect__details {
        transition: padding-top .5s ease, height .5s ease;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &_muted {
    background-color: #c9c9c9;
  }

  &_last {
    margin-bottom: 0;
  }

  &_disabled {
    position: relative;

    &:before {
      position: absolute;
      z-index: calc( var(--z-terminal-header) - 1 );
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      display: block;
      content: '';

      background: var(--color-disabled);
      opacity: 0.3;
    }
  }

  /* Modifier 'btn' */
  &_btn.btn {
    display: block;
    width: calc( 100% - 2.5rem );
    height: auto;

    padding: 0.75rem 1rem 1.5625rem;
    margin-right: auto;
    margin-left: auto;

    color: var(--color-light);
    border: var(--btn-border-width) solid var(--color-light-extra);
    border-radius: var(--border-radius-xl);
    background-color: transparent;

    &:only-child {
      padding-bottom: 0.75rem;

      &::after {
        display: none;
      }
    }
  }
}
