.help-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .3);
  z-index: var(--z-top);

  &:after {
    content: '';
    display: block;
    width: 50%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    color: rgba(255, 255, 255, .2);
    background: url(tais-styles/svg/question-mark.svg) 50% 50% no-repeat;
    background-size: 80% 80%;
    opacity: .2;
  }

  &__header {
    color: #fff;
    font-size: 36px;
    text-align: center;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    text-shadow: 1px 1px 10px #000, -1px -1px 10px #000;
    opacity: 0;
    animation:
      shadow-decrease 5s,
      hide-header 1s 5s;

    &_is_hidden {
      display: none;
    }
  }

  &__symbol {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, .8);
    color: var(--brand-highlight);
    border-radius: 50%;
    width: 43px;
    height: 43px;
    border: 2px solid #fff;
    text-decoration: none;
    text-shadow: none;
    font-size: 32px;
  }
}

.help-closer {
  position: absolute;
  right: 70px;
  top: 70px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background: #fff;
  border: none;
  z-index: var(--z-top);
  cursor: pointer;
  transition: box-shadow .3s ease;
  display: inline-block;
  text-align: center;

  &:before {
    content: "×";
    font: 60px/50px Arial, Helvetica, sans-serif;
  }

  &:hover {
    box-shadow: inset 0 0 0 4px var(--brand-highlight);
  }
}

.tooltip-hint {
  position: fixed;
  min-width: 10px;
  min-height: 10px;
  cursor: help;
  color: transparent;
  border: 2px solid;
  border-radius: 8px;
  box-sizing: content-box;
  transition: border .3s ease;
  z-index: 2;
  animation:
    blink-hint-border 5s,
    hide-hint-border 1s 5s;

  &:hover,
  &_is_visible {
    border-color: #fff;
    transition: border .3s ease;
  }

  &:active,
  &_is_pressed {
    border-color: var(--brand-highlight-sec);

    &:hover {
      border-color: var(--brand-highlight-sec);
    }
  }

  @media (max-width: 768px) {
    border-color: #fff;
  }
}

.help-tooltip {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 374px;
  opacity: 0;
  transition: opacity .4s ease;
  display: flex;
  align-items: center;
  z-index: 0;

  &_is_visible {
    opacity: 1;
    z-index: calc(var(--z-popup) + 1);
  }

  &__text {
    display: inline-block;
    font-size: 15px;
    font-style: normal;
    font-weight: bold;
    color: #000;
    text-shadow: 1px 1px #fff;
    padding: 10px 30px 10px 14px;
    background: rgba(255, 255, 255, .9);
    border-radius: 14px;
    max-width: 331px;
  }

  &:before,
  &:after {
    content: '';
    display: inline-block;
    background: url(tais-styles/src_icons/arr-hint_sprite.svg) no-repeat;
    width: 43px;
    height: 39px;
    background-size: cover;
  }

  &:after {
    background-position: -43px 0;
  }

  &_is_left-top {

    &:before {
      transform: rotateX(-180deg);
      align-self: flex-start;
    }

    &:after {
      display: none;
    }
  }

  &_is_left-bottom {

    &:before {
      align-self: flex-end;
    }

    &:after {
      display: none;
    }
  }

  &_is_right-top {
    justify-content: flex-end;

    &:after {
      transform: rotateX(-180deg);
      align-self: flex-start;
    }

    &:before {
      display: none;
    }

    .help-tooltip__closer {
      right: 52px;
    }
  }

  &_is_right-bottom {
    justify-content: flex-end;

    &:before {
      display: none;
    }

    &:after {
      align-self: flex-end;
    }

    .help-tooltip__closer {
      right: 52px;
    }
  }

  &__closer {
    position: absolute;
    top: 9px;
    right: 9px;
    cursor: pointer;
    text-decoration: none;

    &:after {
      content: '\D7';
      display: inline-block;
      color: var(--color-light);
      font-size: 27px;
      line-height: 14px;
    }

    &:hover:after {
      color: #000;
    }
  }
}

.is_relative {
  position: relative;
}

.page_overflow_hidden {
  overflow-y: hidden;
}

.above-overlay {
  position: relative;
  z-index: calc(var(--z-top) + 1);
}

.help-show-hints {
  display: inline-block;
  text-align: center;
  position: absolute;
  right: 10px;
  bottom: 40px;
  width: 26px;
  height: 26px;
  padding: 0;
  font-size: 20px;
  background: transparent;
  border: 2px solid #fff;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  transition: box-shadow .3s;

  &_is_pressed,
  &:hover {
    box-shadow: 0 0 10px #fff;
  }
}

@keyframes shadow-decrease {
  from, to {
    opacity: 1;
    text-shadow: 1px 1px 10px var(--color-dark), -1px -1px 10px var(--color-dark);
  }

  50% {
    text-shadow: 1px 1px 1px #000, -1px -1px 1px #000;
  }
}

@keyframes hide-header {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes blink-hint-border {
  from, to {
    border-color: #fff;
  }
}

@keyframes hide-hint-border {
  from {
    border-color: #fff;
  }

  to {
    border-color: transparent;
  }
}
