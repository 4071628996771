.prf-rbd-editor {
  &__rbd-input {
    text-transform: uppercase;
    width: 140px;
    right: 12px;
    top: -3px;

    .dropdown_active .dropdown__switcher {
      z-index: var(--z-loader);
    }

    .dropdown_active .dropdown__switcher:after {
      display: none;
    }

    .dropdown__body {
      left: 0;
      right: auto;
      padding-bottom: 10px;
    }
  }

  &__table {
    margin: 0 0 10px;
    padding: 0;
    width: 100%;
    table-layout: fixed;

    &-title {
      font-weight: bold;
      font-size: 14px;
      text-transform: uppercase;
      color: #545454;
    }

    .input__control {
      text-align: center;
    }
  }

  &__table:last-child {
    margin-bottom: 0;
  }

  &__table-caption {
    position: relative;

    .prf-init-cabin__edit-mode & {
      display: none;
    }
  }

  &__table-caption:after {
    content: '';
    position: absolute;
    display: block;
    height: 2px;
    width: 100%;
    bottom: 50%;
    background-color: #fff;
  }

  &__caption-txt {
    display: inline-block;
    color: #525252;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    padding: 2px 10px;
    background-color: #eee;
    position: relative;
    z-index: 1;
  }

  &__actions {
    display: none;

    .prf-init-cabin__edit-mode & {
      display: block;
      margin-left: auto;
    }
  }

  &__create-rbd {
    margin-bottom: 10px;
  }

  &__add-rbd {
    border: none;
    background-color: #fff;
    cursor: pointer;
    color: var(--brand-highlight);
    font-size: 11px;
    font-weight: bold;
    display: inline-flex;
    align-items: center;
    text-transform: uppercase;
    padding: 7px 12px;
    border-radius: 4px;

    &:before {
      content: '\002b'; /*+*/
      font: bold 15px/9px monospace;
      color: currentColor;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      transition: background-color .1s, color .1s;
      margin-right: 5px;
    }
  }

  &__add-rbd:hover,
  &__add-rbd:hover:before {
    color: var(--brand-highlight-sec);
  }

  &__add-rbd__group {
    align-items: baseline;

    &[data-nesting-type=PARALLEL_NESTING] {
      .dropdown__body {
        top: -142px;
      }
    }
  }

  &__add-rbd__group:first-of-type {
    margin-bottom: 20px;
  }

  &__name-rbd {
    &__group {
      margin-right: 10px;
      text-transform: uppercase;
    }
  }

  &__dropdown_nesting {
    text-align: right;
    position: absolute;
    right: 0;
    font-size: 0;
    line-height: 0;

    .dropdown__body {
      position: absolute;
      top: 10px;
      right: 0;
      left: auto;
      box-shadow: 1px 1px 2px #999;
      padding: 16px 10px 16px 16px;
      align-items: flex-end;
      transform: none;
      margin-top: 0;

      & .dropdown__ok {
        padding: 0 10px;
      }
    }

    .dropdown__body::before {
      content: '';
      display: block;
      position: absolute;
      top: -6px;
      right: 19px;
      border-style: solid;
      border-width: 0 6px 6px;
      border-color: transparent transparent #fff;
    }

    .dropdown__switcher::after {
      display: none;
    }

    .select__options {
      margin-right: 20px;
    }

    .select__option {
      padding: 6px 12px;
      font-size: 14px;
    }
  }

  &__table-mixed {
    tbody {
      .prf-rbd-line {
        .shift-serial {
          padding-left: 23px;

          .prf-init-cabin__edit-mode & {
            padding-left: 27px;
          }
        }

        .shift-serial .nesting-indication {
          border-left: 2px dashed #fff;
        }
      }

      .prf-rbd-line:nth-of-type(2),
      .prf-rbd-line:nth-of-type(3) {
        .shift-serial {
          padding-left: 18px;
        }

        .shift-serial .nesting-indication {
          border: none;
        }
      }
    }
  }

  &__last-added {
    animation: animatedShowTable 4s;
  }
}

.prf-init-cabin__view-mode {
  .prf-rbd-editor {
    &__add-rbd {
      cursor: default;
    }
  }
}

.prf-rbd-editor {
  &__rbd-input {
    line-height: 24px;

    & .dropdown__body {
      top: -96px;
      z-index: calc(var(--z-popup) + 11);
    }
  }
}

@keyframes animatedShowTable {
  0% {
    background-color: transparent;
  }

  50% {
    background-color: rgb(76 165 255 / .5);
  }

  100% {
    background-color: transparent;
  }
}
