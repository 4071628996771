.notify-zone {
  position: relative;
}

.notify-message {
  position: relative;
  padding: 0.625rem 2.5rem;
  text-align: center;
  font-size: var(--font-size-m);
  color: #fff;
  background: var(--color-light-extra);
  z-index: calc(var(--z-navigation) + 1);
  margin-top: 8px;

  &__txt {
    margin: 0;
  }

  &_success {
    background: #228b22;
    font-weight: bold;
  }
  &_warning,
  &_warn {
    background: var(--notify-warn-bg);
  }

  &_error,
  &_err {
    background: var(--notify-error-bg);
  }

  &__close.btn-close {
    float: right;
  }

  & button {
    color: #000;
  }
}
