/**
* PASSENGERS
*/

.passenger {
/**
* 1. In order to aling the content of .passenger__tickets block exactly to the left
* when it is moved to the second line because of a small width of a parent block
*
* 2. One ticket number is centered vertically with a popover control
* but more than one ticket number vertically aligned to top with a popover control.
*/
  &__info {
    display: flex;
    align-items: center;
    flex: 0 0 100%;
  }

  &_form {
    .passenger__info {
      align-items: flex-start;
    }
  }

  & .passenger__menu-link {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
  }

  &__info-main {
    display: flex;
    flex: 2 0 auto;
    width: auto;
    margin-right: 5px;
    max-width: 45%;
  }

  &__info-name-doc {
    display: inline-flex;
    max-width: max-content;
    flex: 1 1 auto;
    margin-right: 5px;
    flex-direction: column;
    align-self: center;
  }

  &__subtext {
    text-transform: uppercase;
    font-size: 13px;
    white-space: normal;
  }

  &_document-validity {
    white-space: nowrap;
  }

  &__commands {
    display: inline-block;
    padding: 0 .25rem;
    white-space: nowrap;

    &-optional {
      color: var(--color-light);
    }
  }

  &__badges {
    display: inline-block;
    padding-top: 0;
    padding-bottom: 0;
  }

  &__categories {
    display: inline-flex;
    flex-wrap: wrap;
    flex: 1 1 3%;
    align-self: flex-end;
    align-items: center;
    align-content: center;

    padding-top: 0;
    padding-bottom: 0;

    .passenger__cat_doc__nowrap {
      text-wrap: nowrap;
    }
  }

  &__cat {
    margin-left: 0.125rem;
    margin-right: 0.125rem;
  }

  &__ssrs-badges {
    display: flex;
    flex-wrap: wrap;
    margin-right: 5px;

    .tag-group {
      margin: 1px;
    }

    .tag-group:last-child {
      margin-right: 0;
    }

    .tag {
      display: inline-flex;
      align-items: center;
    }
  }

  &__info-add {
    flex: 1 0 auto;
  }

  &__ticket {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &-number {
      padding-left: 0;
      margin-left: 0.4rem;
      margin-top: 1px;
    }
  }

  &__tickets {
    display: flex;
    align-items: flex-start;
    margin-left: -0.35rem; /* 1 */

    & + & {
      margin-top: 1rem;
    }
  }

  &__ticket-nums {
    display: flex;
  }

  &__footer {
    display: flex;
    padding: 10px;
    margin: 0 -10px;

  }

  &_error {
    position: relative;
    box-shadow: inset 0 0 0 0.1875rem var(--brand-warning);
    padding-bottom: 10px;
  }

  &_uploaded {
    box-shadow: inset 0 0 0 0.1875rem var(--notify-success-bg);
  }

  &_form,
  &_selected {
    box-shadow: inset 0 0 0 0.1875rem var(--brand-highlight);

    & .panel__control .badge {
      background-color: var(--brand-highlight);
    }

    & .panel__footer {
      background: var(--brand-highlight);
    }
  }

  & .mta-deselect {
    display: none;
  }

  &__errors {
    position: absolute;
    right: 0;
    bottom: 0;
    color: #fff;
    background-color: var(--brand-warning);
    margin: 0;
    padding: 0 var(--border-radius-lg);
    border-radius: var(--border-radius-lg) 0 var(--border-radius-lg) 0;
  }

  &__errors li {
    display: inline-block;
    margin-left: 5px;
  }

  &__errors li:not(:last-child) {
    padding-right: 5px;
    border-right: 3px solid #fff;
  }

  &_hide {
    display: none;
  }
}

.passenger-list__empty {
  font-size: 2em;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
}

.passenger_selected {
  & .mta-select {
    display: none;
  }
  & .mta-deselect {
    display: block;
  }
}

.pax-tickets {
  padding-top: 0.1875rem;
  padding-bottom: 0.1875rem;
  margin-left: 0.625rem;

  font-size: var(--font-size-m);
  font-weight: bold;

  line-height: 1.15;

  white-space: nowrap;

  &__prefix {
    display: inline-block;
    margin-right: 0.25rem;
    vertical-align: middle;
  }

  &__ticket {
    display: inline-block;
    vertical-align: top;
  }
}

.pax-tckt {
  display: flex;
  vertical-align: middle;
  width: 12em;

  &__num {
    display: inline;
    padding: 0.1rem;
    vertical-align: middle;
    color: #999;
    border: 1px solid var(--color-light);
    border-radius: var(--border-radius-tag) 0 0 var(--border-radius-tag);
  }

  &__num_clickable {
    color: #666;
    text-decoration: underline;
    text-decoration-style: dashed;
  }

  &__num_clickable:hover {
    text-decoration: none;
  }

  &_none {
    border-color: red;
    color: red;
    border-radius: var(--border-radius-tag);
  }

  &__state {
    display: block;
    border-radius: 0 var(--border-radius-tag) var(--border-radius-tag) 0;
    background: var(--color-light);

    padding: 1px 0.2rem 1px 0.1rem;
    line-height: 1.3;
    font-weight: bold;
    font-size: var(--font-size-m);
    color: #fff;
    text-align: center;
    width: 1.4em;

    &_inconsistent {
      background: var(--brand-warning);
      font-weight: normal;
    }

    & + & {
      position: relative;
      padding-left: 0.15rem;
    }

    & + &:before {
      position: absolute;
      top: -1px;
      bottom: -1px;
      left: -4px;

      display: block;
      width: 7px;

      content: '';

      background-color: var(--color-light);
      background-clip: content-box;

      border: solid #fff;
      border-width: 1px 1px 1px 0;
      border-radius: 0 var(--border-radius-tag) var(--border-radius-tag) 0;
    }

    &_inconsistent + &:before {
      background-color: var(--brand-warning);
    }
  }

  &__state-text {
    display: block;
    padding: 1px 1px 1px 0;
    line-height: 18px;
    font-weight: bold;
    font-size: var(--font-size-m);
    color: #fff;
    text-align: center;
    width: 20px;
  }

  &__state-advice {
    font-style: normal;
    color: var(--brand-warning);
    max-width: 230px;
  }

  &_link {
    position: relative;
    display: flex;

    & + & {
      justify-content: flex-end;
      margin-top: 0.75rem;
    }

    & + & .pax-tckt__num {
      position: relative;
    }

    & + & .pax-tckt__num:before {
      position: absolute;
      top: calc( -0.75rem - 1px );
      left: 0.5rem;

      display: block;
      height: 0.75rem;
      width: 0.5rem;

      content: '';

      background: url(tais-styles/svg/pax-link.svg) 0 50% no-repeat;
    }
  }

  .pax-tckt__num:hover {
    color: var(--brand-highlight);
  }

  .app-mode-full &:hover &__state,
  .app-mode-full &:hover &__state + &__state:before {
    background: var(--brand-highlight);
  }

  &__empty-coupons {
    border-radius: var(--border-radius-tag);

    .action_deleted &,
    .action_new &,
    .action_edited & {
      border-radius: 3px;
    }
  }
}
