/*
* --------------------------------------------------
* This file contains all styles for different blocks (parts) of sections.
* --------------------------------------------------
*/

/* Block with dark bg under the main query field */
.header-block {
  display: inline-flex;
  flex: 1 0 auto;

  padding: 0.0625rem 0.5rem;
  vertical-align: top;

  text-align: right;
  color: #fff;
  border-radius: var(--border-radius);
  background: var(--bg-dark);

  &_col {
    flex-direction: column;
  }

  &__title {
    margin-right: 0.625rem;
    text-align: left;
    font-weight: bold;
  }
}

/* GROUP OF PANELS */
.panels-group {
  padding: 0.375rem 0;

  border-radius: var(--border-radius-xl);
  background: var(--color-light-extra);

  &__footer {
    margin: 0.5rem 0 -0.375rem;

    border-radius: 0 0 var(--border-radius-xl) var(--border-radius-xl);
  }

  &__av {
    min-width: 51em;
    margin-bottom: 0.5rem;
  }

  .panel {
    min-width: 0;
  }
}

/**
* CAPTION before / between panels
*/
.panels-caption {
  padding-bottom: 0.3rem;
  line-height: 1;
  white-space: nowrap;

  &__title,
  &__title_s {
    display: inline-block;
    margin: 0.25rem 0.5rem 0.5rem 0;
    vertical-align: middle;

    font-family: var(--font-family-sans-serif-arial);
    font-weight: bold;
    text-transform: uppercase;

    color: var(--brand-secondary);
  }

  &__title {
    font-size: var(--font-size-md);
  }

  &__title_s {
    font-size: var(--font-size-m);
  }
}

/**
* PANEL with white bg and rounded corners
*/
.panel {
  display: flex;
  clear: both;
  padding: 0 1rem 0 0.375rem;

  border-radius: var(--border-radius-lg);
  background: #fff;

  &_flex-start {
  /* Panel with items aligned to the top */
    align-items: flex-start;
  }

  &_in-group {
  /* Panels inside a group */
    padding-right: 0.625rem;
    padding-left: 0;
    margin-left: 0.375rem;
    margin-right: 0.375rem;

    & + & {
      margin-top: 0.5rem;
    }
  }

  &_in-col {
  /* Panels inside a col */
    & + & {
      margin-top: 0.625rem;
    }
  }

  &__control {
  /* Section of a panel with menu */
    flex: 0 0 auto;
    width: 4.75rem;
    display: flex;
    align-items: center;

    &_disabled {
    /* Disabled control without popover and link */
      padding: 0.5625rem 0 0.5625rem 1.75rem;
    }

    &_badge {
    /* Panel's badge without control (just number) */
      width: 3.75rem;
      padding: 0.625rem 0 0.625rem 0.625rem;
    }
  }

  &__info-flex {
  /* Section of a panel with information */
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1 1 100%;
    padding: .625rem 0;

    .panel_flex-start & {
      justify-content: flex-start;
      align-items: flex-start;
    }
  }

  &__info-text {
    align-self: center;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;

    & > * {
      display: inline-block;
      margin-right: 0.25rem;
      vertical-align: middle;
    }
  }

  &__badges {
    flex: 1 0 auto;
    align-self: center;

    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    padding-left: 0.5rem;

    text-align: right;

    & > * {
      display: inline-block;
      vertical-align: middle;
    }
  }

  &__title {
    font-weight: bold;
    font-size: var(--font-size-md);
    line-height: 1.2;
    &_link {
      text-decoration: underline;
    }
  }

  &__subtitle {
    font-weight: bold;
    font-size: var(--font-size-m);
  }

  &__text {
    font-size: var(--font-size-m);
  }

  &__text-em {
    font-style: normal;
    font-size: 1rem;
  }

  &__row {
    padding-right: 0.625rem;
    padding-bottom: 0.625rem;

    &_indent_badge {
      padding-left: 3.75rem;
    }
  }

  &__footer {
    margin-left: -0.375rem;
    margin-right: -1rem;
    border-radius: 0 0 var(--border-radius-lg) var(--border-radius-lg);
  }
}
