/*
* --------------------------------------------------
* This file contains all styles for a popover menu.
* --------------------------------------------------
*/

/**
* POPOVER menu with actions for separate blocks
* with a `3 dots` control
* ex: segment, passenger, service */
.popover {
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  font-size: 1rem;

  &__switcher {
  /* Popover toggle icon `3 dots` */
    position: relative;
    flex: 0 0 auto;
    box-sizing: content-box;
    width: 0.5em;
    height: 2.375em;
    padding: 0.625em;
    cursor: pointer;

    & > div,
    & > div:before,
    & > div:after {
      position: absolute;
      display: block;
      width: 0.5em;
      height: 0.5em;
      border-radius: 50%;
      background: var(--color-light);
    }

    & > div {
      top: 50%;

      margin-top: -0.25em;
    }

    & > div:before,
    & > div:after {
      top: 0;
      left: 0;

      content: '';
    }

    & > div:before { transform: translate(0, -0.875em); }
    & > div:after { transform: translate(0, 0.875em); }

    .popover_active & {
    /* Popover toggle icon while menu is active */
      z-index: calc(var(--z-popup) + 10);
    }
  }

  &__body {
  /* Popover menu body
  * this class specifies background and indents for a pop-up */
    position: absolute;
    top: 0;
    left: 0;

    display: none;
    min-width: 12.5em;
    min-height: 100%;
    padding: 6px 10px 6px 75px;

    border-radius: var(--border-radius-lg);
    background: #fff;

    .popover_active & {
      z-index: var(--z-popup);

      display: flex;
    }

    &_column {
      flex-direction: column;
    }
  }

  &__icon {
  /* Panel type icon */
    position: relative;

    overflow: hidden;
    flex: 0 0 auto;
    cursor: pointer;
    width: 2.5rem;
    height: 2.5rem;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    font-size: var(--font-size-md);
    line-height: 2.5rem;

    .popover_active & {
    /* Panel type icon while menu is active */
      z-index: calc(var(--z-popup) + 10);
    }
  }

  &_nohover &__icon{
    cursor: default;
  }

  &_s {
  /* Smaller modification of the popover menu */
    font-size: 0.625rem;


    & .popover__body {
      min-width: 17em;
      padding-left: 6em;
      margin-top: -0.5em;
      margin-left: -0.5em;
    }

    & .popover__icon {
      width: 1.875rem;
      height: 1.875rem;
      margin-top: 0.2em;
      margin-bottom: 0.2em;

      font-size: var(--font-size-m);
      line-height: 1.875rem;
    }
  }

    &_xs {
      /* Smallest modification of the popover menu */
      font-size: 0.325rem;
    }

  &_no {
    padding-left: 1.75em;
  }

  &_right {
    & .popover__body {
      right: 0;
      left: auto;

      padding-right: 6em;
      padding-left: 1.2em;
      margin-top: -0.5em;
      margin-right: -0.5em;
      margin-left: 0;
    }
  }

  &__empty {
    visibility: hidden;
  }

  .passenger__ticket-number {
    min-width: 132px;
  }
}

.popover_bottom-wrap {
  position: relative;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  z-index: 6000;
}

.popover_bottom {
  position: static;

  .popover__body {
    top: 100%;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: 2px solid #999;
    width: 100%;
  }
}

.popover_xs {
  font-size: 0.5rem;
}

.popover_xs.popover_active {
  .popover__switcher:hover {
    background-color: var(--brand-highlight-sec);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;


    .popover__switcher-dots,
    .popover__switcher-dots::before,
    .popover__switcher-dots::after {
      background-color: #ffffff;
    }
  }
}

.popover__switcher_xs:hover {
  background-color: #fff;
  border-radius: 0.3125rem;
}

.popover__body_xs {
  border-radius: 0.3125rem;
  padding: 0.375rem 0.375rem 0.375rem 1rem;
}

.popover__switcher_two-items {
  height: 2.9375rem;
}

.popover__list {
  padding: 0;
  list-style: none;
  display: grid;
  grid-gap: 0.3125rem;
}

.popover__list_nogap {
  grid-gap: 0;
}

.popover__list-item {
  font: bold 0.625rem var(--font-family-sans-serif);
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  padding: 0.125rem 0.375rem;
  border-radius: 0.1875rem;
}

.popover__list-item:hover {
  background-color: var(--brand-highlight-sec);
  color: #ffffff;
}

.popover__list-item_disabled {
  color: #cccccc;
  cursor: not-allowed;
}

.popover__list-item_disabled:hover {
  background-color: #ffffff;
  color: #cccccc;
}


.popover__list-item_xs {
  font-size: 0.5625rem;
}
