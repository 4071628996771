
.selected-line {
  position: absolute;
  left: 50%;

  display: none;
  width: 3px;
  margin-left: -1.5px;

  background-color: var(--brand-highlight);

  &:before,
  &:after {
    position: absolute;
    z-index: 0;

    display: block;
    height: 3px;

    content: '';

    background-color: inherit;
  }

  &:before {
    top: 0;
    right: calc( -1 * var(--grid-gutter) );
    left: 0;
  }

  &_reversed:before {
    right: 0;
    left: calc( -1 * var(--grid-gutter) );
  }

  &:after {
    right: 0;
    bottom:0;
    left: calc( -1 * var(--grid-gutter) );
  }

  &_reversed:after {
    right: calc( -1 * var(--grid-gutter) );
    left: 0;
  }

  .msg-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 7px 0 6px;
    color: #fff;
    background-color: var(--brand-highlight);
    border-radius: var(--btn-border-radius);
    white-space: nowrap;
    z-index: 1;
  }
}
