/*
* --------------------------------------------------
* This file contains all styles for the seat map.
* --------------------------------------------------
*/
.sm-bg {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 1rem 0.5rem;
}

/**
* SEAT MAP LEGEND
*/
.sm-legends {
  display: flex;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  font-size: 0;
  margin: 0 0 2rem;
}

.sm-legend {
  margin-top: .7rem;
  display: block;

  &__item {
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.667;
    color: var(--brand-secondary);

    &:before {
      box-sizing: border-box;
      display: inline-block;
      width: 1rem;
      height: 1rem;
      margin-right: 0.3125rem;
      vertical-align: middle;

      content: '';
    }

    &_business:before {
      background-color: var(--color-orange);
    }

    &_economy:before {
      background-color: var(--color-green);
    }

    &_0:before {
      background-color: var(--bg-seat-price-0);
    }

    &_1:before {
      background-color: var(--bg-seat-price-1);
    }

    &_2:before {
      background-color: var(--bg-seat-price-2);
    }

    &_3:before {
      background-color: var(--bg-seat-price-3);
    }

    &_4:before {
      background-color: var(--bg-seat-price-4);
    }

    &_5:before {
      background-color: var(--bg-seat-price-5);
    }

    &_6:before {
      background-color: var(--bg-seat-price-6);
    }

    &_7:before {
      background-color: var(--bg-seat-price-7);
    }

    &_8:before {
      background-color: var(--bg-seat-price-8);
    }

    &_unavailable:before {
      background: #fff;
      border: 1px solid var(--color-disabled);
    }

    &_occupied:before {
      background: #fff;
      border: 1px solid var(--brand-warning);
    }
  }
}

/**
* PLANE SCHEME
*/
.plane {
  display: flex;
  align-items: center;
  width: 100%;
  margin: -1.4rem 0 .5rem;

  &__head {
    width: 6.5625rem;
    margin-right: 1rem;

    &-img {
      width: 6.5625rem;
      height: 10.8125rem;
    }

    &_deck-upper {
      width: auto;
    }
  }

  &__rows-nums {
    height: 100%;
    flex: 1 1 1%;
  }

  &__rows-nums_econom {
    background: var(--bg-green);
  }

  &__cabin {
    overflow: hidden;
    padding: .5rem 0;
    flex-grow: 1;
    max-width: 88%;

    html.mac & {
      padding-bottom: 1rem;
    }

    &-heading {
      margin: 0 0 0 3rem;
      font-size: var(--font-size-md);
    }
  }

  &__tail {
    margin-left: 1rem;

    &-img {
      width: 3.8124rem;
      height: 10.75rem;
    }
  }

  &_deck-upper {
    width: auto;

    .plane__cabin {
      max-width: 100%;
    }
  }
}

/**
* SEAT MAP arrows
*/
.sm-nav {
  &__cnt {
    position: relative;

    &.active {
      cursor: pointer;

      &:hover {
        background-color: lightyellow;
      }
    }
  }

  &__arr {
    position: absolute;
    top: calc(50% - 1rem);

    display: block;
    width: 2rem;
    height: 2rem;

    transform-origin: 50% 50% 0;

    & > span {
      display: block;

      font-size: .9375rem;
      font-weight: bold;
      text-align: center;

      color: var(--bg-dark);
      line-height: 2rem;
    }

    &_prev {
      right: -0.75rem;

      border-top: 2px solid var(--border-sm-nav);
      border-left: 2px solid var(--border-sm-nav);

      transform: rotate(-45deg);

      & > span {
        transform: rotate(45deg);
      }
    }
    &_next {
      left: -0.75rem;

      border-top: 2px solid var(--border-sm-nav);
      border-right: 2px solid var(--border-sm-nav);

      transform: rotate(45deg);

      & > span {
        transform: rotate(-45deg);
      }
    }

    &_disabled {
      border-color: var(--border-sm-nav-disabled);
    }
  }
}

/**
* SEAT MAP
*/
.sm {
  display: flex;
  padding-top: 0.9rem;
  padding-bottom: 0.9rem;
  align-items: center;

  &-deck {
    &-list {
      max-width: 100%;
      display: flex;
      flex-direction: column;
    }

    &-item {
      display: none;
      align-self: center;

      &--active {
        display: flex;
      }

      .plane__head {
        width: auto;
      }
    }

    &-tabs {
      display: flex;
      margin-bottom: .5rem;
    }

    &-tab {
      border: 1px solid var(--color-disabled);
      color: var(--color-disabled);
      padding: .25rem 2rem;
      cursor: pointer;

      &:first-child {
        margin-right: calc(var(--border-radius) * -1);
        border-top-left-radius: var(--border-radius);
        border-bottom-left-radius: var(--border-radius);
      }

      &:last-child {
        border-top-right-radius: var(--border-radius);
        border-bottom-right-radius: var(--border-radius);
      }

      &--active {
        background-color: var(--color-disabled);
        color: #fff;
      }
    }
  }

  &__row {
  /* One row */
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    flex: 0 0 auto;
  }

  &__window {
    width: 100%;
    height: 2.25rem;
    padding: 0.5rem 0.25rem;

    text-align: center;

    &:after {
      box-sizing: border-box;
      display: inline-block;
      width: 1rem;
      height: 1.25rem;

      content: '';

      border: 1px solid var(--border-sm-window);
      border-radius: 8px;
    }

    .sm__row_first & {
    /* Place in a first row after a bulkhead in economy class */
      padding-left: 0.75rem;
      margin-left: -1px;
    }

    .sm__row_first-business & {
    /* Place in a first row in a business class */
      padding-left: 0.5rem;
    }

    .sm__row_last & {
    /* Last row in economy class */
      padding-right: 0.375rem;
    }

    .sm__row_last-business & {
    /* Last row in business class */
      padding-right: 0.75rem;
    }

    .sm__row_extra & {
    /* Row with an extra legroom */
      padding-left: 1.75rem;
    }
  }

  &__cab {
  /* One row of seats */
    display: flex;
    flex-direction: column-reverse;
    flex-grow: 1;
    justify-content: space-between;

    &_business {
    /* Row in business class */
      background: var(--bg-orange);
    }
    &_economy {
    /* Row in economy class */
      background: var(--bg-green);
    }
  }

  &__class-title-business,
  &__class-title-economy {
    display: inline-block;
    padding-left: 0.5rem;
    line-height: 1;
    white-space: nowrap;
    border-left: 1px solid;
    font-size: var(--font-size-m);
    vertical-align: top;
  }

  &__class-title-business {
  /* Business class title */
    color: var(--color-sm-business-class);
    border-left-color: var(--bg-orange);
  }
  &__class-title-economy {
  /* Economy class title */
    width: 100%;
    color: var(--color-green);
    border-left-color: var(--bg-green);
    margin-left: 1rem;
  }

  &__row-num {
  /* Fixed row numbers at the plane head */
    box-sizing: content-box;
    width: 2rem;
    height: 2rem;
    padding: 0 0 0.1875rem;

    font-weight: bold;
    font-size: var(--font-size-s);
    line-height: 2rem;
    text-align: center;

    color: var(--color-light);

    .sm__aisle ~ & {
      padding-top: 0.1875rem;
      padding-bottom: 0;
    }

    &:first-child {
      padding-bottom: 0.5rem;
    }
    &:last-child {
      padding-top: 0.5rem;
    }
  }

  &__aisle {
  /* Aisle */
    min-width: 2rem;
    height: 1.5rem;
  }

  &__place {
  /* Block of one place */
    box-sizing: content-box;
    width: 2rem;
    height: 2rem;
    padding: 0 0.25rem 0.1875rem;

    .sm__aisle ~ & {
      padding-top: 0.1875rem;
      padding-bottom: 0;
    }

    &:first-child {
      padding-bottom: 0.5rem;
    }
    &:last-child {
      padding-top: 0.5rem;
    }

    .sm__row_first & {
    /* Place in a first row after a bulkhead in economy class */
      padding-left: 0.5rem;
      margin-left: -1px;
    }

    .sm__row_first-business & {
    /* Place in a first row in a business class */
      padding-left: 0.5rem;
    }

    .sm__row_last & {
    /* Last row in economy class */
      padding-right: 0.375rem;
    }

    .sm__row_last-business & {
    /* Last row in business class */
      padding-right: 0.75rem;
    }

    .sm__row_extra & {
    /* Row with an extra legroom */
      padding-left: 1.75rem;
    }

  }

  &__seat {
    width: 2rem;
    height: 2rem;
    font-size: var(--font-size-s);
    font-weight: bold;
    line-height: 2rem;
    text-align: center;

    color: #fff;

    & > b {
      color: var(--color-seat-letter);
    }

    &_business {
      background-color: var(--color-orange);
    }

    &_economy {
      background-color: var(--color-green);
    }

    &_0 {
      background-color: var(--bg-seat-price-0);
    }

    &_1 {
      background-color: var(--bg-seat-price-1);
    }

    &_2 {
      background-color: var(--bg-seat-price-2);
    }

    &_3 {
      background-color: var(--bg-seat-price-3);
    }

    &_4 {
      background-color: var(--bg-seat-price-4);
    }

    &_5 {
      background-color: var(--bg-seat-price-5);
    }

    &_6 {
      background-color: var(--bg-seat-price-6);
    }

    &_7 {
      background-color: var(--bg-seat-price-7);
    }

    &_8 {
      background-color: var(--bg-seat-price-8);
    }

    &_unavailable {
      cursor: default;
      color: var(--color-disabled);
      background: #fff;
      border: 1px solid var(--color-disabled);

      & > b { color: inherit; }
    }

    &_occupied {
      cursor: default;
      color: var(--color-disabled);
      background: #fff;
      border: 1px solid var(--brand-warning);

      & > b { color: inherit; }
    }

    &_non-selectable {
      cursor: default;
    }
  }

  &-passengers {
    display: flex;
    margin-bottom: 1rem;
    width: 100%;
  }

  &-legends + &-passengers {
    margin-top: 1rem;
  }

  &-note {
    margin-bottom: 1rem;
    color: var(--color-medium);
    font-size: var(--font-size-base);
  }

  &-note,
  &-legends,
  &-passengers {
    margin-left: 9.5625rem;
  }

  .plane_deck-upper.sm-deck-item--active ~ &-note,
  .plane_deck-upper.sm-deck-item--active ~ &-legends,
  .plane_deck-upper.sm-deck-item--active ~ &-passengers {
    margin-left: 3rem;
  }

  &__psgr {
    &-h {
      display: inline-block;
      width: 100%;
      margin-bottom: .25rem;
    }

    &-list {
      padding: 0;
      border-left: 1px solid #fff;
    }

    &-itm {
      background-color: #eee;
      border: 2px solid transparent;
      padding: 0 1rem 0 .25rem;
      margin: .5rem 0;

      &_seated {
        cursor: default;
        background-color: #eee;
        color: var(--brand-secondary);
      }

      &_not-seated {
        cursor: pointer;
      }

      &_active {
        border-color: var(--brand-highlight);
      }

      .sm__seat_unavailable {
        border: 0;
      }
    }

    &-name {
      display: inline-block;
      margin-left: .25rem;

      &_with-impossible-seat {
        margin-left: 40px;
        margin-right: 20px;
      }
    }

    &-seat {
      display: inline-block;
      font-weight: bold;
      transform: scale(.75);
      border-radius: .125rem;
    }

    &--lined {
      flex-flow: column wrap;

      .sm__psgr {
        &-list {
          display: flex;
          flex-flow: row wrap;
        }

        &-itm {
          margin-right: 1rem;
        }
      }
    }
  }
}
