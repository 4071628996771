/*
* --------------------------------------------------
* This file contains styles that are specific to the main screen
* and it's elements.
* --------------------------------------------------
*/
@import 'tais-styles/styles/utils/custom_media.cssnext';

/**
* ACTIONS MENU
* on the main screen of the terminal app
*/
.terminal-actions {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  margin-right: 0.75rem;
  margin-left: 0.75rem;
}

.terminal-actions_compact {
  padding-top: 0;
  padding-bottom: 0;
  margin-right: 0;
  margin-left: 0;
}

.terminal-action {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 8.125rem;
  padding: 0.5rem 0.5rem 0.5rem 8.125rem;
  cursor: pointer;
  text-decoration: none;
  color: var(--brand-secondary);
  border: 3px solid transparent;
  border-radius: var(--border-radius-lg);

  @media (--vp-max-xl) {
    padding-left: 6.6rem;
  }

  &:hover,
  &:active,
  &_selected {
    text-decoration: none;
    color: #000;
    border-color: var(--brand-highlight);
    background-color: #fff;
  }

  &_selected {
    cursor: auto;
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 1.8rem;
    -ms-flex: 1;
    display: inline-block;
    width: 4.375rem;
    height: 4.375rem;
    margin-top: -2.1875rem;

    color: #fff;

    @media (--vp-max-xl) {
      left: 1.2rem;
    }
  }

  &:hover &__icon,
  &:active &__icon,
  &_selected &__icon {
    color: inherit;
  }

  &__text {
    font-weight: bold;
    font-family: var(--font-family-sans-serif-arial);
    font-size: var(--font-size-xlg);
  }

  &__content {
    display: inline-block;
    -ms-flex: 1;
    flex: 1 1 100%;
  }

  &__hidden {
    display: none;
  }

  &_selected &__on-select_hide {
    display: none;
  }

  &_selected &__on-select_show {
    display: block;
  }

  .paddingless {
    padding-bottom: 0;
  }
}

.terminal_label {
  font-weight: bold;
  font-family: var(--font-family-sans-serif-arial);
  font-size: 1rem;
  color: #424242;
}

.terminal-action_without_icon {
  padding: 0rem 0rem 0rem 0.2rem;
  min-height: 0.5rem;
  border: 2px solid transparent;
  border-radius: 4px;
}

.terminal-action_commands {
  padding: 0.28rem 0rem 0rem 0rem;
  line-height: 1.7;
}
