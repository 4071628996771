.terminal-result {
  &-header {
    position: relative;
    border-bottom: 1px solid #000;
    background-color: #D0D0D0;
    font-family: Verdana, sans-serif;
    font-size: var(--font-size-m-extra);
    padding: 5px 10px;

    & > button {
      font-family: FontAwesome, sans-serif;
      height: 100%;
    }

    & > .back-button {
      float: left;
    }

    & > .clear-button {
      float: right;
    }

    & > .back-button::before {
      content: '\f060';
    }

    & > .clear-button::before {
      content: '\f00d';
    }
  }

  &-toolbar {
    border-bottom: 1px solid #000;
    background-color: #D0D0D0;
    font-family: Verdana, sans-serif;
    font-size: var(--font-size-m-extra);
    vertical-align: middle;
    text-align: center;
  }
}

.result_container {
  height: calc(100vh - 52px - 49px - 36px - 16px);
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  position: relative;
}
