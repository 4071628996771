.dropdown_select {
  &__read-only {
    pointer-events: none;

    & .select__box {
      cursor: default;
    }
    & .select__control:not(:empty) {
      cursor: default;
    }
  }

  span.select__option {
    white-space: nowrap;
  }

  &_bottom-top {

    .dropdown__body {
      margin-bottom: 40px;
      top: -18px;
    }

    .dropdown__switcher:after {
      top: -0.6rem !important;
      transform: rotate(180deg);
    }
  }
}
