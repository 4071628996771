.app-mode-twt {
  --brand-highlight: #0054d5;
  --brand-highlight-sec: #001b7d;
  --brand-highlight-100: rgba(128, 178, 255, 1);
  --brand-highlight-0: rgba(128, 178, 255, 0);
  --selection-bg: rgba(128, 178, 255, 1);
}

.page.app-mode-twt {
  min-width: 1000px;
}

.app-mode-twt{
  .br-text-field__control:focus,
  .br-text-field__textarea:focus,
  .br-text-field__control:focus-visible,
  .br-text-field__textarea:focus-visible {
    outline: none;
    box-shadow: none;
    border-radius: 0.125rem;
  }
}

.app-mode-twt {
  .br-text-field_changes-value .br-text-field__control,
  .br-text-field_changes-value .br-text-field__textarea,
  .br-text-field_primary .br-text-field__textarea {
    color: var(--tf-color-primary);
  }
}

.app-mode-twt {
  .br-text-field_clearable .br-text-field__control{
    padding: 0.25rem 0.975rem 0.25rem 0.25rem;
  }
}

.app-mode-twt {
  .br-text-field__clear::before,
  .br-text-field__clear::after {
    position: absolute;
    top: calc(50% - 1px);
    left: calc(50% - 7px);

    color: inherit;
    content: '';
    background-color: currentColor;

    display: block;
    width: 14px;
    height: 2px;

    transform-origin: 50% 50%;
  }

  .br-text-field__clear::before {
    transform: rotate(-45deg);
  }

  .br-text-field__clear::after {
    transform: rotate(45deg);
  }
}

.app-mode-twt {
  .br-text-field__error {
    margin-top: 6px;
    display: inline-block;

    color: var(--tf-color-error);
    font-size: 0.75rem;
  }
}

.app-mode-twt {
  .br-text-field_focus .br-text-field__control {
    outline: none;
    box-shadow: none;
    border-radius: 0.125rem;
  }

  .br-text-field_focus .br-text-field__box {
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      z-index: 2;

      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      width: calc(100% + 10px);
      height: calc(100% + 10px);

      border: 2px solid var(--focus-visible-outline-color);
      border-radius: 0.125rem;

      pointer-events: none;
    }
  }

  .br-text-field_error .br-text-field__box {
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      z-index: 2;

      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      width: calc(100% + 10px);
      height: calc(100% + 10px);

      border: 2px solid var(--tf-color-error);
      border-radius: 0.125rem;

      pointer-events: none;
    }
  }
}

.app-mode-twt {
  .br-text-field_error .br-text-field__control:focus {
    box-shadow: none;
    border-bottom-color: #ffffff;
  }

  .br-text-field_required .br-text-field__control,
  .br-text-field_required .br-text-field__textarea {
    border-bottom-color: var(--brand-highlight);
  }
}

.loading-done .app-mode-twt .main-loader__header {
  margin-left: 0;
}

.loading-done .app-mode-twt .main-loader__header .progress-bar {
  height: 3.75rem;
}

.loading-done .app-mode-twt .main-loader__header .progress-bar__loader {
  height: 3.75rem;
}

.app-mode-twt .vc-tooltip__overlay,
.app-mode-twt .vc-dropdown__overlay {
  max-width: 1920px;
}

.app-mode-twt .main-menu__item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0;
  padding: 0.25rem 0.625rem;
}

.app-mode-twt .main-menu__icon {
  display: inline-block;
  width: 1.625rem;
  height: 1.625rem;
  margin: 0 1rem 0 0;
}

.app-mode-twt .eqp__screen__body {
  background-color: #f6f6f6;
}

.app-mode-twt .main-loader__header{
	margin-left:0;
	margin-right:-2px;
	border-right:2px solid #858585;
}

.app-mode-twt .main-loader__header .progress-bar{
	height:30px;
	height:3.75rem;
}

.app-mode-twt .main-loader__header .progress-bar__loader{
	height:30px;
	height:3.75rem;
}

.app-mode-twt .progress-bar__text {
  color: var(--brand-highlight);
}

.app-mode-twt .versions-box__logo {
  padding-left: 2.5rem;
  margin: 0 0.313rem 0 0.25rem;
  background-image: url('tais-styles/svg/ors-logo-new.svg');
  background-repeat: no-repeat;
  background-position: 0 -5px;
  background-size: 1.938rem 0.938rem;
}







.app-mode-twt {
  .screen_flt {
    .btn-history,
    .flt-subfly-comments__comment,
    .flt-init-cabin__params__item__edit-button,
    .flt-armor-editor__add-armor {
      visibility: hidden;
    }

    .flight__header__profile__name,
    .flight__header__equipment[data-twt-command],
    .flt-init-cabin__max-header__value {
      border: none;
      cursor: not-allowed;
      pointer-events: none;
    }

    .flight__header__sale__comp {
      cursor: not-allowed;
      pointer-events: none;

      .select__tick {
        visibility: hidden;
      }
    }

    .flight__header__equipment_btn-open-dropdown {
      cursor: not-allowed;
      pointer-events: none;

      &.select__tick {
        visibility: hidden;
      }
    }

    .flight__header__codeshare {
      pointer-events: none;
      cursor: not-allowed;
    }

    .flight {
      .flight__header__codeshare__partner {
        border: none;
      }
    }
  }
}
