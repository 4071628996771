.table-editable {
  position: relative;

  .table-new-line {
    display: none;
  }

  .table-remove {
    cursor: pointer;
  }
  .table-remove:hover {
  }

  .table-up,
  .table-down
  {
    color: #007;
    cursor: pointer;
  }

  .table-up:hover,
  .table-down:hover
  {
    color: #00f;
  }

  .table-add {
  }
  .table-add:hover {
  }

  .table-add.table-add-locked {
    display: none
  }

  .table-line {
  }
}
