.log {
  text-align: left;
  overflow-y: auto;
  max-height: 400px;
  scrollbar-width: thin;
  padding: 0 10px 0 0;
  margin: 0;

  &::-webkit-scrollbar {
    width: 9px;
  }

  &-header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &-screen-resolution {
    margin: 0;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
  }

  &-date {
    margin: 0;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: bold;
    color: #333;
  }

  &__command {
    color: #666;
    font-weight: bold;
    cursor: pointer;
    margin-bottom: 5px;
  }

  &__command:hover {
    color: #333;
  }

  &__command ~ &__row {
    padding-left: 10px;
    white-space: nowrap;

    .log__row-data-wrap {
      min-width: 400px;
    }
  }

  &__command ~ &__collapsed {
    padding-left: 10px;
  }

  &__row {
    margin-bottom: 2px;
  }

  &__collapsed {
    margin-bottom: 5px;
  }

  &__collapsed.screen-sect_active {
    .log__collapsed-details {
      padding: 10px 0 10px 19px;
      list-style-type: none;
    }

    .log__row-data-wrap {
      min-width: 380px;
    }
  }

  &__row:last-child,
  &__collapsed:last-child {
    margin-bottom: 0;
  }

  &__id {
    color: #999;
    font-size: 12px;
    justify-self: flex-end;

    &_cmd {
      font-size: 10px;

      .log__id-code {
        font-size: 12px;
        color: #333;
      }
    }
  }

  &__href {
    text-decoration: underline;
  }

  &__href:hover {
    text-decoration: none;
  }

  &__session {
    margin-left: 8px;
  }

  &__row-data-wrap {
    min-width: 410px;
    display: inline-block;
    align-items: center;
  }

  &__origin {
    font-size: 0;/*hide like this will add correct output after copy-paste and hide on page actually*/
  }

  .splunk-button {
    border: none;
    background: none;
    cursor: pointer;

    &__first {
      font-size: 14px;
    }
  }

  &__id-code {
    font-family: var(--font-family-monospace);
  }

  &__rpc-save {
    min-width: 24px;
    height: 24px;
    background-position: 50% 50%;
    background-repeat: no-repeat;;
    background-size: contain;
    border: none;
    border-radius: 3px;
    opacity: .5;
    background-color: #fff;
    background-image: url(tais-styles/svg/e2e.svg);

    &:hover {
      opacity: 1;
      cursor:pointer;
    }

    &:disabled {
      opacity: .2;
    }
  }

  &__rpc-clipboard {
    min-width: 24px;
    height: 24px;
    background-position: 50% 50%;
    background-repeat: no-repeat;;
    background-size: contain;
    border: none;
    border-radius: 3px;
    opacity: .5;
    background-color: #fff;
    background-image: url(tais-styles/svg/clipboard.svg);

    &:hover {
      opacity: 1;
      cursor:pointer;
    }

    &:disabled {
      opacity: .2;
    }
  }
}
