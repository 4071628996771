.screen_wbase_type_info_with_full_data,
.screen_wbase_type_info_with_uploading {

  .wbase-type-header {
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .wbase-object-list {
    padding: 10px;
  }

  .wbase-object-list__text {
    font-size: 16px;
  }

  .wbase-object {
    margin-bottom: 10px;
    padding: 4px;
    border: 2px solid #fff;
    transition: border-color .1s;
  }

  .wbase-object[data-twt-command]:hover {
    border-color: var(--brand-highlight);
  }

  .wbase-object:last-child {
    margin-bottom: 0;
  }

  .wbase-object-index {
    font-size: 15px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    margin: 0 44px 0 0;
  }

  .wbase-object-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}

.screen_wbase_object_info {
  .wbase-type-header {
    font-size: 16px;
    margin: 10px 0;
    padding: 10px;
  }

  .wbase-object-list {
    padding: 10px;
  }

  .wbase-object-list__text {
    font-size: 18px;
    font-family: var(--font-family-monospace);
  }

  .wbase-object {
    padding: 8px 0 8px 14px;
    margin-bottom: 10px;
  }

  .wbase-object[data-twt-command] {
    border: 2px solid #fff;
    transition: border-color .1s;
  }

  .wbase-object[data-twt-command]:hover {
    border-color: var(--brand-highlight);
  }

  .wbase-object:last-child {
    margin-bottom: 0;
  }
}

.screen_wbase_command_info {
  .wbase-command-info-list {
    padding: 10px;
  }

  .wbase-command-info-list__text {
    font-size: 16px;
  }
}
