.ssr-flight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;

  .ssr-direction-sign {
    margin: 0 5px;
    font-size: 13px;
  }


  & .ssr-dep-time,
  & .ssr-arr-time {
    font-weight: bold;
    font-size: 14px;
  }

  & .ssr-dep-time {
    text-align: left;
  }

  & .ssr-arr-time {
    text-align: right;
  }

  & .ssr-baggage {
    padding-right: 16px;
    padding-top: 4px;
    display: flex;
    flex-direction: column;
  }

  & .ssr-baggage-info {
    font-size: 10px;
    line-height: 8px;
  }

  & .ssr-segment-block {
    max-width: 15em;
  }

  & .ssr-segment {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
  }

  & .ssr-flight-time {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
  }

  & .ssr-flight-info {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    & .ssr-allotment {
      color: #777;
      font-size: 10px;
      text-transform: uppercase;
    }
  }

  .ssr-allotment:empty {
    display: none;
  }
}
