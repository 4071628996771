.wave {
  &&_short {
    display: inline-block;
    width: 1rem;

    & > .subwave {
      &:before {
        background-image: radial-gradient(circle at 4px -2px, transparent 4px, var(--color-lighter) 5px);

        .wave_ligtest & {
          background-image: radial-gradient(circle at 4px -2px, transparent 4px, #fff 5px);
        }

        .wave_lightest-extra & {
          background-image: radial-gradient(circle at 4px -2px, transparent 4px, #eee 5px);
        }

        .dropdown_active .dropdown__switcher & {
          background-image: radial-gradient(circle at 4px -2px, transparent 4px, var(--color-light) 5px);
        }

        .pnr__control .dropdown__switcher:hover,
        .pnr__control .dropdown__switcher:active,
        .dropdown_active .select__option:hover,
        .dropdown_active .select__option:active & {
          background-image: radial-gradient(circle at 4px -2px, transparent 4px, var(--brand-highlight) 5px);
        }
      }

      &:after {
        background-image: radial-gradient(circle at 4px 7px, var(--color-lighter) 3px, transparent 4px);

        .wave_ligtest & {
          background-image: radial-gradient(circle at 4px 7px, #fff 3px, transparent 4px);
        }

        .wave_lightest-extra & {
          background-image: radial-gradient(circle at 4px 7px, #eee 3px, transparent 4px);
        }

        .dropdown_active .dropdown__switcher & {
          background-image: radial-gradient(circle at 4px 7px, var(--color-light) 3px, transparent 4px);
        }

        .pnr__control .dropdown__switcher:hover,
        .pnr__control .dropdown__switcher:active,
        .dropdown_active .select__option:hover,
        .dropdown_active .select__option:active & {
          background-image: radial-gradient(circle at 4px 7px, var(--brand-highlight) 3px, transparent 4px);
        }
      }
    }
  }

  &, & > .subwave {
    position: relative;

    &:before, &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -5px;
      right: 0;
      background-repeat: repeat;
      height: 6px;
      background-size: 13px 10px;
      background-image: radial-gradient(circle at 4px -2px, transparent 3px, var(--brand-warning) 4px);
    }

    &:after {
      left: 7px;
      bottom: 0;
      background-image: radial-gradient(circle at 4px 7px, var(--brand-warning) 4px, transparent 5px);
    }
  }

  & > .subwave {
    z-index: 2;

    &:before {
      background-image: radial-gradient(circle at 4px -2px, transparent 4px, #fff 5px);
    }

    &:after {
      background-image: radial-gradient(circle at 4px 7px, #fff 3px, transparent 4px);
    }
  }
}
