/*
* --------------------------------------------------
* This file contains all styles for different tags.
* --------------------------------------------------
*/

.tag {
  /* Solid gray small (11px) - default label */
  display: inline-block;
  min-width: 3.637em;
  margin-top: 1px;
  margin-bottom: 1px;
  padding: 0 0.3em;

  vertical-align: middle;
  text-align: center;

  color: #fff;

  border: 1px solid var(--color-light);
  border-radius: var(--border-radius-tag);
  background: var(--color-light);

  font-size: var(--font-size-xs);
  font-weight: bold;
  line-height: 1.455;

  &_counter {
    min-width: 1.7rem;
  }

  /* Different color variants */
  &_olg {
    /* Gray outline, light gray text and transparent bg */
    color: var(--color-light);
    background: transparent;
  }

  &_odg {
    /* Gray outline, dark gray text and transparent bg */
    color: var(--brand-secondary);
    background: transparent;
  }

  &_sr {
    /* Solid red bg*/
    border-color: var(--brand-warning);
    background: var(--brand-warning);
  }

  &_or {
    /* Red outline */
    color: var(--brand-warning);
    border-color: var(--brand-warning);
    background: transparent;
  }

  &_so {
    /* Solid orange bg */
    color:  var(--color-orange);
    border-color: var(--bg-orange);
    background: var(--bg-orange);
  }

  &_oo {
    /* Orange outline */
    color: var(--brand-secondary);
    border-color: var(--bg-orange);
    background: transparent;
  }

  &_sg {
    /* Solid green bg */
    color:  var(--color-green);
    border-color: var(--bg-green);
    background: var(--bg-green);
    margin-left: -2.8em;
  }

  &_og {
    /* Green outline */
    color: var(--brand-secondary);
    border-color: var(--bg-green);
    background: transparent;
  }

  &_ogg {
    /* Green outline and green text */
    color: var(--color-green);
    border-color: var(--color-green);
    background: transparent;
  }

  &_rbd {
    color:  #fff;
    background: var(--color-light);
    border-color: var(--color-light);
  }

/* Different sizes */
  &_s {
    font-size: var(--font-size-base);
    line-height: calc( var(--font-size-base) * 1.42 );
  }

  &_lg {
    /* Bigger size label  */
    min-width: 3.125em;
    font-size: var(--font-size-root);
    line-height: 1.2;
  }

  &_sm {
    min-width: 1.5em;
  }

  /* Inner elements */
  & b {
    color: #000;
  }

  /* Modifications */
  &_disabled,
  &_disabled b {
    color: var(--color-disabled);
  }
}


.tag-group {
  display: inline-flex;
  padding: 0;
  margin: 0 .25rem .25rem 0;
  vertical-align: middle;

  &_br {
    display: flex;
    flex-wrap: wrap;
    margin-left: 2.8em;

    &:last-child {
      margin-bottom: 0.25rem;
    }
  }

  &_nobr {
    display: table;
    margin-top: 0.25rem;

    &:last-child {
      margin-bottom: 0.25rem;
    }
  }

  & > .tag {
    display: table-cell;
    min-width: 1.818em;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: middle;
  }

  & > .tag_label {
    min-width: 3em;
  }

  & > .tag:not(:first-child) {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  & > .tag:not(:last-child) {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &_br > .tag {
    display: block;
    margin-top: .25rem;
  }
}
