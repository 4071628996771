/*
* --------------------------------------------------
* This file contains all styles for check element
* (circle with check mark inside when checked).
* Which can be used with checkbox / radio control
* or as independent inline element.
* --------------------------------------------------
*/

.check {
  position: relative;

  display: inline-block;

  text-align: left;

  user-select: none;
}

.check_panel {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 5px 5px 7px;

  background-color: #ffffff;
  border-radius: 0.25rem;
  cursor: pointer;
}

.check_panel:hover {
  background-color: #eeeeee;
}

.check__control {
  position: absolute;

  margin: 0;

  opacity: 0;
  filter: Alpha(opacity=0);
}

.check__box {
  position: relative;

  display: inline-block;
  flex-shrink: 0;
  width: 1.75rem;
  height: 1.75rem;
  margin-right: 0.375rem;

  vertical-align: middle;
  color: var(--brand-highlight);

  border: 2px solid currentColor;
  border-radius: 50%;
}

.check__box_small {
  width: 1.5rem;
  height: 1.5rem;
}

.check__box__user-profile {
  margin: 0.25rem;
}

.check__control:disabled ~ .check__box,
.check_disabled .check__box {
  color: var(--color-disabled);
}

.check__control:not(:disabled) ~ .check__box,
.check__control:not(:disabled) ~ .check__text {
  cursor: pointer;
}

.check__control:checked ~ .check__box,
.check_checked .check__box {
  flex-shrink: 0;

  background-color: currentColor;
  background-image: url("data:image/svg+xml,%3Csvg width='15' height='12' viewBox='0 0 15 12' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.62 10.54C3.62 10.54 3.62 10.54 3.62 10.54L4.2 11.11C4.39 11.3 4.71 11.3 4.9 11.1L14.58 1.42C14.78 1.23 14.78 0.91 14.59 0.72L14.01 0.14C13.82-0.05 13.51-0.05 13.31 0.15L4.55 8.91 1.42 5.79C1.22 5.59 0.91 5.59 0.72 5.78L0.14 6.36C-0.05 6.55-0.05 6.87 0.15 7.06L3.62 10.54 3.62 10.54Z' fill='%23ffffff'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 58% center;
  background-size: auto 0.75rem;
}

.check:hover {
  .check__control[disabled]:not(:checked) + .check__box {
    background-color: transparent;
    background-image: none;
    border-color: var(--color-disabled);
  }

  .check__control:not(:checked) + .check__box {
    background-color: #eeeeee;
    border-color: var(--brand-highlight-sec);
  }

  .check__control:checked + .check__box {
    background-color: var(--brand-highlight-sec);
    border-color: var(--brand-highlight-sec);
  }

  [disabled]:checked + .check__box {
    background-color: var(--color-disabled);
    border-color: var(--color-disabled);
  }
}

.check__text {
  display: inline-block;

  font-weight: bold;
  vertical-align: middle;
  color: var(--brand-primary);
  text-transform: uppercase;

  user-select: initial;
}

.check__text_sec {
  margin-right: 0.375rem;

  font-weight: bold;
  font-size: 0.875rem;
  color: #666666;
}

.check__text_input {
  width: calc(100% - 39px);

  font-weight: normal;
  font-size: var(--font-size-xs);
  line-height: 1;
  color: var(--color-light-extra);
  text-transform: none;
}

.check_inv {
  & .check__box {
    color: #ffffff;
  }

  & .check__control:disabled ~ .check__box,
  &.check_disabled .check__box {
    color: var(--color-disabled);
  }

  & .check__control:checked ~ .check__box,
  &.check_checked .check__box {
    background-color: currentColor;
    background-image: url("data:image/svg+xml,%3Csvg width='15' height='12' viewBox='0 0 15 12' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.62 10.54C3.62 10.54 3.62 10.54 3.62 10.54L4.2 11.11C4.39 11.3 4.71 11.3 4.9 11.1L14.58 1.42C14.78 1.23 14.78 0.91 14.59 0.72L14.01 0.14C13.82-0.05 13.51-0.05 13.31 0.15L4.55 8.91 1.42 5.79C1.22 5.59 0.91 5.59 0.72 5.78L0.14 6.36C-0.05 6.55-0.05 6.87 0.15 7.06L3.62 10.54 3.62 10.54Z' fill='%234ca5ff'/%3E%3C/svg%3E");
  }

  & .check__control:checked:disabled ~ .check__box,
  &.check_checked.check_disabled .check__box {
    background-image: url("data:image/svg+xml,%3Csvg width='15' height='12' viewBox='0 0 15 12' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.62 10.54C3.62 10.54 3.62 10.54 3.62 10.54L4.2 11.11C4.39 11.3 4.71 11.3 4.9 11.1L14.58 1.42C14.78 1.23 14.78 0.91 14.59 0.72L14.01 0.14C13.82-0.05 13.51-0.05 13.31 0.15L4.55 8.91 1.42 5.79C1.22 5.59 0.91 5.59 0.72 5.78L0.14 6.36C-0.05 6.55-0.05 6.87 0.15 7.06L3.62 10.54 3.62 10.54Z' fill='%23ffffff'/%3E%3C/svg%3E");
  }
}

.check__num {
  display: block;
  width: 1.0625rem;

  font-weight: normal;
  font-size: 0.8125rem;
  text-align: center;
}
