.terminal-generation {
  position: absolute;
  z-index: calc(var(--z-search-history) + 1);
  top: 1.6875rem;
  right: calc(0.9rem + 2 * 0.3125rem + 0.0625rem);

  display: inline-flex;
  flex-direction: row;
  align-content: stretch;
  justify-content: flex-end;
  width: auto;
  padding: 0.6rem 0 0 0;

  &__title {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: -0.0625rem;
  }

  &__time {
  }

  &__refresh {
    width: 0.75rem;
    height: 0.75rem;
    margin-left: 0.3125rem;
  }
}
