@charset "UTF-8";
@import './config.cssnext';
@import './main-action.cssnext';

/* -------------------------------------------------------------------------- */

.terminal-menu__header {
  position: absolute;
  left: -3.5rem;
  top: 0;
  width: 0;
  height: 0;
}

.terminal__menu__toggle {
  position: absolute;
  left: 0.625rem;
  top: 1rem;

  width: var(--terminal-menu-toggle-size);
  height: var(--terminal-menu-toggle-size);

  color: #f2f2f2;
  background-color: var(--brand-highlight);

  border: calc(var(--terminal-menu-toggle-size) * 0.08333333) solid var(--brand-highlight);
  border-radius: 50%;

  cursor: pointer;
  user-select: none;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.terminal__menu__toggle::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: block;

  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  opacity: 1;
  transition: opacity 0.15s ease-in-out;
}

.terminal__menu__toggle:hover::after {
  opacity: 0;
}

.terminal__menu__toggle__path,
.terminal__menu__toggle__path::before,
.terminal__menu__toggle__path::after {
    position: absolute;

      display: block;
  height: 0.25rem;

  background-color: currentColor;

  transform-origin: 50% 50%;
  transition: transform 0.1s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .terminal__menu__toggle__path,
  .terminal__menu__toggle__path::before,
  .terminal__menu__toggle__path::after {
    transition: none;
    }
}

.terminal__menu__toggle__path {
  top: calc( 50% - 0.125rem );
  left: calc( 50% - 0.5625rem );

  color: #ffffff;
  width: 1.125rem;
}

.terminal__menu__toggle__path::before,
.terminal__menu__toggle__path::after {
  left: 0;

  width: 100%;

  content: '';
}

.terminal__menu__toggle__path::before {
  transform: rotate(0deg) translate(0, -0.4375rem);
  /* height 4px + 3px indent */
}

.terminal__menu__toggle__path::after {
  transform: rotate(0deg) translate(0, 0.4375rem);
  /* height 4px + 3px indent */
}

.terminal__menu_open {
  .terminal__menu__toggle::after {
    opacity: 0;
  }

  .terminal__menu__toggle__path {
    background: transparent;
  }

  .terminal__menu__toggle__path::before {
    transform: rotate(45deg);
  }

  .terminal__menu__toggle__path::after {
    transform: rotate(-45deg);
  }
}

.terminal__menu__toggle__path__icon {
  position: absolute;
  top: -4px;
  right: -10px;
  width: 20px;
  height: 14px;
  color: #999999;
}

.terminal-menu__main {
  display: none;
  position: absolute;
  left: -3.5rem;
  top: 100%;
  width: calc(100% + 3.5rem);
  height: calc(100vh - 100%);
  padding: 1rem 0 2.125rem 1rem;
  flex-flow: row;
  background-color: #fff;
  font-size: var(--terminal-menu-font-size-base);

  .terminal-menu__main__queues-link {
    text-transform: uppercase;
    font-size: 12px;
    color: #333;
    border-bottom: 1px dashed;
    transition: border-bottom-color .1s;

    &:hover {
      border-bottom-color: transparent;
    }
  }

  .terminal-menu__main__count-row:last-child {
    .terminal-menu__main__side__queue-widget__item__notifications-count::before {
      bottom: 0;
      top: auto;
    }
  }

  &__side__queue-widget__item {
    margin-bottom: 10px;
  }

  &__side__queue-widget__item:last-child {
    margin-bottom: 0;
  }
}

.terminal-menu__main-notification__bar__opened {
  padding-top: 3.2rem;
}

.terminal-menu__main__side {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: var(--terminal-menu-side-width);
  margin-left: 2.5rem;
  border-radius: var(--terminal-menu-default-border-radius);
  background-color: #eee;
  font-size: 14px;
  max-height: 100%;
  overflow-y: auto;

  &-wrap {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 8px;
    }
  }

  .pnr-panel-open & {
    margin-bottom: 40px;
  }

  &__logo {
    padding: 10px 10px 0;
    margin-bottom: 10px;
    text-align: center;

    &__link {
      cursor: pointer;
      min-width: 142px;
      max-width: 142px;
    }
  }

  &__content {
    flex-grow: 1;
    padding: 0 10px;
  }

  &__user {
    margin-bottom: 10px;
    padding: 0.25rem;
    border-radius: var(--terminal-menu-default-border-radius);
    background-color: var(--color-disabled);
    text-align: center;

    &__office-number {
          font-size: var(--font-size-xxs);
    }
  }

  &__footer {
    margin-top: 10px;
  }

  /* TODO: full style */
  .session-org,
  .session-location,
  .session-currency,
  .session-server,
  .main-menu__item:not(:hover) {
    color: #666;
  }

  .session-location {
    width: 100%;
    margin: 0 0 10px 0;

    &::before {
      background-image: url(tais-webterminal/svg/location-gray.svg)
    }
  }

  .session-currency {
    margin-bottom: 10px;

    .session-currency__tag {
      background-color: var(--color-disabled);
    }
  }

  .session-server {
    margin-bottom: 10px;
    text-align: center;
  }

  &__queue-widget {
    background-color: #fff;
    display: flex;
    max-height: 30%;

    [data-tooltip]::before {
      min-width: 120px;
      max-width: 250px;
    }

    &__container {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      border: 1px solid #ddd;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      padding: 0 0 0 10px;


    }

    &__item {
      &__command {
        display: inline-flex;
        font-size: 16px;
        align-items: center;

        &__qa {
          margin-top: 0.5rem;
          margin-bottom: 0.1rem;
          font-size: var(--font-size-m);
        }

        &__qs {
          margin-bottom: 0.6rem;
          font-size: var(--font-size-m);
        }
      }

      &__command:not([data-twt-command]){
        color: var(--color-disabled);
      }

      &__command[data-twt-command]{
        cursor: pointer;
      }

      &__notifications {
        display: inline-block;
      }

      &__notifications-count {
        display: inline-block;
        background-color: #ccc;
        border-radius: 5px;
        font-size: 11px;
        color: #000;
        padding: 2px 4px;
        text-align: center;
        min-width: 38px;
        margin-right: 7px;

        &_priority {
          background-color: var(--color-orange);
          color: #fff;
        }
      }

      &__number {
        display: inline-block;
        font-weight: bold;
        font-size: 14px;
        bottom: 1px;
        position: relative;
      }

      &__without-notifications {
        margin-bottom: 0;
      }
    }
  }

  .session-pnr {
    display: block;
    padding-bottom: 0.5rem;
    background: white;

    &__container {
      display: block;
      border: 1px solid var(--color-lighter);
      border-radius: var(--border-radius);
      width: 100%;
      padding: 0.5rem 0;

      margin: auto;
      text-align: center;
    }

    &__label {
      display: flex;
      align-items: center;
    }

    &__state {
      display: inline-block;
      width: 100%;
      text-align: left;
    }

    &__locator {
      display: inline-block;
      padding: 0 0.5rem;
    }
  }
}

.terminal-menu__pnr-group {
  font-weight: bold;
  margin-top: 1rem;
  text-align: center;
  color: #000;
  font-size: var(--font-size-md);
}

.pnr-show-badge,
.pnr-show-grouped-badge {
  .session-pnr__label:first-child::before {
    content: "";
    display: inline-block;
    border-radius: 0.5rem;
    width: 1rem;
    height: 1rem;
    margin: 0 0.7rem 0 1rem;
  }

  .session-pnr__label_opened:first-child::before {
    border: 0.5rem solid #2db000;
  }

  .session-pnr__label_edited:first-child::before {
    border: 0.5rem solid #ffbf00;
  }
}

.terminal-menu__main__content {
  flex: 1;
  display: flex;
  /*padding: 0 0 0 6rem;*/
  padding: 0 0 0 2rem;
  overflow: hidden;
}


.queue-widget-header {
  font-size: 13px;
  font-weight: bold;
  color: #333;
  text-transform: uppercase;
  border-bottom: 1px dashed;
  transition: border-bottom-color .1s;

  &:hover {
    color: #333;
    border-bottom-color: transparent;
  }
}



/* tabs --------------------------------------------------------------------- */

.terminal-menu__tabs {
  flex: 1;
  display: flex;
  flex-direction: column;

  &__nav {
    flex: auto;
    display: flex;
    width: calc(100% - 2rem);
    margin: 0 0 2rem 0;
    padding: 0;
    border-bottom: 1px solid var(--brand-highlight);
    list-style: none;
    font-size: var(--terminal-menu-tabs-nav-font-size);
    min-height: 35px;

    &__item {
      flex: 1;
      display: flex;

      &__link {
        display: inline-block;
        margin-left: auto;
        margin-right: auto;
        padding: 0 0 0.5rem 0;
        border-bottom: 3px solid transparent;
        color: var(--brand-highlight);
        cursor: pointer;

        &_active {
          border-bottom-color: var(--brand-highlight);
          font-weight: bold;
          cursor: default;
        }
      }
    }
  }

  &__content {
    position: relative;
    flex: 1 100%;
    overflow: auto;

    .pnr-panel-open & {
      margin-bottom: 40px;
    }

    &__item {
      display: none;

      &_active {
        display: block;
      }

      &_disabled {
        opacity: 0.25;

        * {
          pointer-events: none;
        }
      }

      /**/
      &__block {
        flex-wrap: wrap;
        display: block;

        @media (min-width: 1280px) {
          display: flex;
          justify-content: space-between;
        }

        .terminal-menu__command-block {
          margin: 0 0 32px 0;
          width: 20%;
          padding-right: 20px;

          @media (max-width: 1365px) {
            width: 30%;
            padding-right: 2%;
          }

          @media (max-width: 1279px) {
            width: 50%;
          }

          &_x6 {
            flex-basis: calc(16.666666% - 2rem);
          }
        }

        .terminal-menu__command-block:last-of-type {
          [data-tooltip-position=left]::before {
            right: auto;
            left: calc(100% + 6px);
          }

          [data-tooltip]::after {
            right: auto;
            left: 100%;
            border-width: 3px 6px 3px 0;
            border-color: transparent #c0c0c0 transparent transparent;
          }

          @media (min-width: 1280px) {
            [data-tooltip-position=left]::before {
              left: auto;
              right: calc(100% + 6px);
            }

            [data-tooltip-position=left]::after {
              left: auto;
              right: 100%;
              border-width: 3px 0 3px 6px;
              border-color: transparent transparent transparent #c0c0c0;
            }
          }
        }
      }
    }
  }

  &__content-section {
    @media (min-width: 1280px) {
      width: calc(50% - 16px);
    }
  }
}

.terminal-menu__main-menu__menu-blocks {
  display: table;
  width: 100%;
}

.terminal-menu__main-menu__menu-blocks__left {
  display: table-cell;
  width: 50%;
}

.terminal-menu__main-menu__menu-blocks__right {
  display: table-cell;
  width: 50%;
}

.terminal-menu__main-menu__menu-block {
  margin-bottom: calc(var(--terminal-menu-indent-base) * 2);
}

.terminal-menu__main-menu__menu-block__cols {
  display: table;
  width: 100%;
}

.terminal-menu__main-menu__menu-block__col {
  display: table-cell;
  padding: calc(var(--terminal-menu-indent-base) * 1);
}

.terminal-menu__main-menu__menu-blocks__left .terminal-menu__main-menu__menu-block__col {
  width: 33.333333%;
}

.terminal-menu__main-menu__menu-blocks__right .terminal-menu__main-menu__menu-block__col {
  width: 33.333333%;
}

.terminal-menu__main-menu__menu-block__col__header {
  margin-bottom: calc(var(--terminal-menu-indent-base) / 2);
  font-weight: bold;
  font-size: var(--terminal-menu-block-header-font-size);
}

.terminal-menu__main-menu__menu-block__col__item-header {
  display: inline-block;
  position: relative;
  margin-right: 2rem;
  margin-bottom: calc(var(--terminal-menu-indent-base) / 2);
  font-size: var(--terminal-menu-comand-font-size);
  cursor: pointer;

  &::after {
    content: ">";
    display: block;
    position: absolute;
    top: 0.1875rem;
    right: -1.5rem;
    font-size: var(--terminal-menu-comand-toggle-font-size);
    transform: rotate(90deg) scaleY(1.8);
    color: var(--brand-highlight);
  }

  &:hover {
    color: var(--brand-highlight);
  }
}

.terminal-menu__main-menu__menu-block__col__sub-items {
  display: none;
  padding-left: var(--terminal-menu-indent-base);
}

.terminal-menu__main-menu__menu-block__col__item {
  position: relative;

  &.terminal-menu__main-menu__menu-block__col__item_sub-items-open {
    &::before {
      content: "";
      position: absolute;
      z-index: -1;
      display: none;
      left: -calc(var(--terminal-menu-indent-base) / 1);
      top: -calc(var(--terminal-menu-indent-base) / 2);
      right: -calc(var(--terminal-menu-indent-base) / 1);
      bottom: 0;

      border: var(--terminal-menu-border-size) solid var(--brand-highlight);
      border-radius: calc(var(--terminal-menu-border-size) * 2);
    }

    &:hover::before {
      display: block;
    }

    .terminal-menu__main-menu__menu-block__col__item-header {
      &::after {
        content: "<";
        top: 0.125rem;
      }
    }

    .terminal-menu__main-menu__menu-block__col__sub-items {
      display: block;
    }
  }
}

.terminal-menu__main-menu__menu-block__command {
  display: inline-block;
  /*margin-bottom: var(--terminal-menu-indent-base);*/
  margin-bottom: calc(var(--terminal-menu-indent-base) / 2);
  font-size: var(--terminal-menu-comand-font-size);

  &[data-twt-execute=true]::before {
    background: #c0c0c0 url("tais-styles/svg/icon-execute.svg") right 5px center no-repeat;
    background-size: 9px 16px;
    padding: 4px 20px 4px 10px;
  }

  &:not([data-twt-command]) {
    color: var(--color-disabled);
  }

  &[data-twt-command] {
    cursor: pointer;

    &:hover {
      color: var(--brand-highlight);
    }
  }
}

.terminal__input-switcher {
  position: absolute;
  top: calc( 1rem + 2px );
  /*
  0.5rem - smart input top margin
  2px - smart input border-width
  0.5rem - icon indent to center it in the field
  */
  right: calc( var(--stack-panel-toggle-width) + 2rem );

  z-index: calc(var(--z-search-history) + 1);

  display: block;
  width: 2rem;
  height: 2rem;
  padding: 0;

  cursor: pointer;

  color: var(--brand-highlight);

  background-image: url("tais-styles/svg/btn-smart-search-switch_inactive.svg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 100%;
}

.terminal__input-switcher:hover,
.terminal__input-switcher.active {
  background-image: url("tais-styles/svg/btn-smart-search-switch_active.svg");
}


/* toggle ------------------------------------------------------------------- */

.terminal__menu_open {
  .terminal-menu__header {
    width: calc(100% + 3.5rem);
    height: 100%;

    background-color: #fff;
  }

  .terminal-menu__main {
    display: flex;
  }
}

.terminal__menu_open_force {
  .terminal__menu__toggle {
    display: none;
  }
}

.terminal__queue-container {
  position: absolute;
  right: 0;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-track {
  background-color: #eee;
}

::-webkit-scrollbar-thumb {
  background-color: #ccc;
  transition: background-color .2s;

  &:hover {
    background-color: #999;
    border-color: #999;
  }
}

.queues-extra-container {
  position: relative;
  overflow: auto;
  padding: 10px 0 10px;
  scrollbar-width: thin;
  height: 100%;
  min-height: 50px;

  &::-webkit-scrollbar {
    width: 7px;
  }
}

.queue-tooltip,
.queue-widget-header__tooltip {
  display: inline-block;
  position: fixed;
  padding: 6px;
  background-color: #333;
  color: #fff;
  border-radius: 4px;
  font-size: 11px;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  z-index: 10;
  max-width: 150px;

  &::before {
    content: '';
    display: inline-block;
    position: absolute;
    top: 50%;
    left: -6px;
    border-style: solid;
    border-color: transparent #333 transparent transparent;
    border-width: 3px 7px 3px 0;
    transform: translateY(-50%);
  }
}

.screen-sect {
  border: none !important;

  &_active {
    .screen-sect__switcher::before {
      transform: rotate(0deg);
    }
  }
}

.queue-header {
  display: flex;
  justify-content: flex-start;
}

.ui-datepicker-calendar {
  .ui-state-in-range {
    .ui-state-default {
      background-color: var(--brand-highlight);
      color: #fff;
    }
  }
}

.permissions-msg {
  background-color: #ddd;
  padding: 3rem 2rem;
  border-radius: 0.5rem;
  margin: auto;
  text-transform: uppercase;
}
