.rebookrules__select {
  position: static;
  width: 100%;
  margin-bottom: 5px;

  .select__value,
  .btn-link {
    text-transform: uppercase;
  }

  .dropdown {
    position: static;
  }

  .btn-close_lg {
    position: absolute;
    right: 23px;
  }

  .dropdown__body {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60vw;
    max-height: 80vh;
    padding: 16px;
    margin-top: 0;
    border-radius: 0;
    flex-direction: column;
    border-radius: 20px;
    overflow: hidden;

    .reaccomodation-rule__order {
      margin-right: 12px;
      font-size: 17px;
      color: #666;
      text-shadow: 2px 2px 0 #fff;
    }
  }

  .prt-all-rules {
    color: var(--brand-highlight);
    font-size: 14px;
    margin-right: 10px;

    &:hover {
      text-decoration: underline;
      text-decoration-style: dotted;
    }
  }

  .prt-rule-selection__header {
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    .select__option {
      transition: none;
      padding: 4px 10px;
      margin-bottom: 0;
    }

    .select__option:hover {
      background-color: #666;
      color: #fff;
      border-color: #666;
    }
  }

  .prt-rule-selection__header-aside {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
  }

  .dropdown__close {
    display: inline-block;
    cursor: pointer;
    background-color: transparent;
    border: none;
    font-size: 34px;
  }

  .prt-edit-rules {
    font-size: 22px;
    color: var(--color-light);
    transition: color .3s;

    &:hover {
      color: var(--brand-highlight);
    }
  }

  .select__box {
    display: inline-block;
  }

  .select__options {
    max-height: 94%;/* IE fix */
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 9px;
      height: 9px;
    }

    &::-webkit-scrollbar-thumb {
      height: 100px;
    }
  }

  .select__option {
    padding: 0;
    margin-bottom: 16px;

    &-selected {
      border: 3px solid var(--brand-highlight);
      border-radius: 5px;

      .prt-rule-selection-item {
        border: none;
      }
    }

    &:hover {
      background-color: var(--brand-highlight);
      color: white;
      color: inherit;
      background-color: inherit;
    }
  }
}

.prt-rule-selection-item {
  border: 3px solid var(--color-lighter);
  border-radius: 5px;
  padding: 14px;
  position: relative;
  display: flex;
  flex-wrap: wrap;

  &__title {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 16px;
    margin: 0;
    flex-grow: 1;
  }

  &__rules {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
  }

  .reaccomodation-rule {
    font-size: 11px;
    padding: 12px;
    margin-bottom: 10px;
    background-color: #eee;
    border-radius: 4px;
    justify-content: flex-start;
    align-items: flex-start;
    text-transform: uppercase;
    line-height: 15px;

    .reaccomodation-rule__predicat-ro-head {
      font-size: 12px;
    }

    .reaccomodation-rule__operation-name {
      font-size: 12px;
    }

    .reaccomodation-rule__with-docking {
      font-size: 13px;
      white-space: nowrap;
    }
  }

  .reaccomodation-rule:last-child {
    margin-bottom: 0;
  }

  .reaccomodation-rule__predicat {
    min-width: 40%;
    max-width: 40%;
    margin: 0;
    flex-grow: 1;
  }

  .reaccomodation-rule__operation {
    flex-grow: 1;

    .reaccomodation-rule__extra-wrap {
      margin-top: 16px;
    }
  }
}
