/**
* SIDE PANEL
* Side navigation bar
* main terminal menu
* default - closed state
*/

.side-panel {
  /* By default - opens over a content */
  position: absolute;
  z-index: var(--z-navigation);
  top: 0;
  left: calc(-1 * var(--side-panel-width));

  display: flex;
  flex-direction: column;

  width: var(--side-panel-width);

  color: var(--color-disabled);
  background: #222;

  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(.55, .055, .675, .19);

  &_active {
  /* Opened side panel (over a content) */
    left: 0;
    box-shadow: 5px 0 10px rgba(102, 102, 102, .65);
  }

  &_static {
  /* Side panel which moves content */
    position: relative;
    left: 0;
    margin-left: calc(-1 * var(--side-panel-width));
    box-shadow: none;
    padding-bottom: 1rem;
  }

  &_active {
  /* Opened side panel (move a content) */
    margin-left: 0;
  }

  &__switcher-bg {
  /* Side panel open/close control */
    position: absolute;
    top: 0.25rem;
    left: 100%;

    width: 2rem;
    height: 2.375rem;
    padding-top: 1.5rem;

    cursor: pointer;
    text-align: center;
    text-transform: uppercase;

    color: #fff;

    border-top-right-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
    background: #222;
    box-shadow: 1px 0 4px rgba(102, 102, 102, .65);

    font-size: 0.5625rem;
    font-weight: bold;
    line-height: 1;

    .side-panel_static & {
      display: none;
    }
  }

  &__switcher-bg:after {
    position: absolute;
    top: 0;
    right: 100%;
    bottom: 0;

    width: 4px;

    content: '';
    background: #222;
  }

  &__header {
    flex: 0 0 auto;
    display: none;
    padding: 1rem 0.875rem;

    @media (--vp-minheight-xs) {
      display: block;
    }

    .app-mode-twtt &
    {
      display: block;
    }
  }

  &__logo {
    cursor: pointer;
  }

  &__content {
    overflow-x: hidden;
    overflow-y: auto;
    flex: 1 1 100%;
  }

  &__footer {
    flex: 0 0 auto;
    padding: 1rem 0.875rem;

    .session-currency {
      margin: 5px 0 10px 0;
    }
  }
}

/* Side panel open/close control icon with animation */
.i-side-panel-switcher {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 1.6rem;

  & > div,
  & > div:before,
  & > div:after {
    position: absolute;

    box-sizing: border-box;
    height: 0.1875rem;

    background: #fff;

    transform-origin: 50% 50%;
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: cubic-bezier(.175, .885, .32, 1.275);
  }

  & > div {
    top: 50%;
    left: 25%;

    width: 50%;
  }

  & > div:before,
  & > div:after {
    top: 0;
    left: 0;

    display: block;
    width: 100%;

    content: '';
  }

  & > div:before {
    transform: rotate(0deg) translate(0, -0.3125rem);
  }
  & > div {
    transition-duration: 0.1s;
  }
  & > div:after {
    transform: rotate(0deg) translate(0, 0.3125rem);
  }

  .side-panel_active & > div,
  .side-panel_active & > div:before,
  .side-panel_active & > div:after {
    border-radius: 1px;
    height: 0.25rem;
  }
  .side-panel_active & > div:before {
    transform: rotate(45deg);
  }
  .side-panel_active & > div {
    background: #222;
  }
  .side-panel_active & > div:after {
    transform: rotate(-45deg);
  }
}

.side-panel__user {
  background: var(--bg-dark);
  color: black;
  border-radius: 5px;
  padding: 3px;
}

.operator-login {
  font-weight: bold;
  font-size: var(--font-size-root);
  text-align: center;
}

.session-org,
.session-location,
.session-operator-name,
.session-currency {
  width: 100%;
  text-align: center;
  word-wrap: break-word;
}

.session-org,
.session-location,
.session-currency {
  margin-top: 5px;
}

.session-org {
  font-weight: bold;
}

.session-location {
  margin-left: -0.875rem;
  width: calc(100% + 1.75rem);
}

.ticket_limit {
  margin-bottom: 0.5rem;
  font-weight: bold;
  text-align: center;
  width: 100%;
}

.session-location:before {
  content: '';
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url(tais-styles/svg/location.svg) no-repeat 0 0;
  background-size: contain;
  vertical-align: middle;
}

.session-currency__tag {
  background: var(--bg-dark);
  color: black;
  border-radius: 3px;
  padding: 3px 5px;
  display: inline-block;
  font-weight: bold;
  margin-left: 5px;
}

/* Number of console in the side panel footer */
.console-num {
  text-align: right;
  color: var(--color-disabled);
  font-weight: bold;
}

.logout-link,
.settings-link {
  cursor: pointer;
  font-size: 12px;
  text-align: center;
  color: var(--brand-highlight);
  text-transform: uppercase;
  font-weight: bold;

  &:hover {
    color: var(--brand-highlight-sec);
  }
}

.change-pass {
  text-align: center;
  cursor: pointer;
  color: var(--brand-highlight);
  text-transform: uppercase;
  font-style: normal;
  font-size: 12px;
  margin-bottom: 10px;
  font-weight: bold;

  &:hover {
    color: var(--brand-highlight-sec);
  }
}

.change-pass__wrap,
.settings__wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.settings__wrap {
  margin-bottom: .5em;
}
