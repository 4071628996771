.sticky-nav {
  background-color: var(--color-light-extra);
  border-top: 1px solid var(--color-lighter);
  display: none;

  &_is_visible {
    display: block;
  }

  &__link {
    display: inline-block;
    background-color: var(--color-light-extra);
    padding: 10px 14px;
    color: var(--color-medium);
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    transition: text-shadow .3s ease;
  }

  &__link-text {
    margin-right: 8px;
  }

  &__link:last-of-type {
    margin-right: 0;
  }

  &__link:hover,
  &__link:active,
  &__link:focus {
    .sticky-nav__link-text {
      text-shadow: 0 1px 0 #fff;
    }


    &:before {
      transform: rotate(90deg);
      transition: transform .6s ease;
    }
  }

  &__link:before {
    content: '';
    display: inline-block;
    margin-right: 6px;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 10px solid var(--brand-secondary);
    transition: transform .8s ease;
  }

  &__badge {
    min-width: 25px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 6px;
    text-decoration: none;
    color: #fff;
    background-color: var(--color-light);
    border-radius: 5px;
  }
}
