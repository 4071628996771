/**
* BADGES on PNR2 screen
* Number of a panel / badges in contacts, services etc.
* ex: number of a segment, passenger, etc;
*/

.popover:not(.popover_nohover):hover .badge,
.popover_active .badge {

  &.badge_bright {
    &:not(.badge_disabled) {
      background-color: var(--brand-highlight-100);
    }

    &.badge_active {
      background-color: #9bcdff;

      &.badge_disabled {
        background-color: #fff;
      }
    }

    &.badge_active:before,
    &.badge_disabled.badge_active:before {
      opacity: 1;
      background-color: var(--brand-highlight-100);
    }
    &.badge_disabled:not(.badge_active):before {
      opacity: 0.7;
    }
  }
}

.badge {
  position: relative;

  display: inline-block;
  overflow: hidden;

  width: 2.5rem;
  height: 2.5rem;
  margin: 0.1rem;

  font-size: var(--font-size-md);
  font-weight: bold;
  line-height: 2.5rem;

  text-align: center;
  text-transform: uppercase;
  color: #000;

  background-color: var(--color-disabled);
  border-radius: 50%;

  transition: all 0.1s ease;

  &_brand-bg {
    background: var(--brand-highlight);
  }

  & span {
    position: relative;
  }

  & b {
    color: var(--brand-secondary);
  }

  &_warning {
    color: var(--brand-warning);
  }

  &:before {
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    content: '';
    opacity: 0.7;
    background-repeat: no-repeat;
  }

  &.badge_solid:before {
    opacity: 1;
  }

  .popover:not(.popover_nohover):hover &,
  .popover_active & {
    color: #fff;
    background-color: var(--brand-highlight);

    &_disabled {
      color: #000;
      background-color: var(--color-disabled);
      cursor: default;
    }
  }

  .popover:hover & b,
  .popover_active & b {
    color: inherit;
  }

  .popover:not(.popover_nohover):hover &,
  .popover_active & {
    &:before {
      opacity: 0.3;
    }

    &_disabled:before {
      opacity: 0.7;
    }
  }

  &_dark {
    background-color: var(--color-medium);
    color: #fff;

    & b {
      color: #fff;
    }
  }

  &_white {
    color: var(--color-medium);
    border: 1px solid;
    background-color: #fff;

    & b {
      color: var(--color-medium);
    }
  }

  &_seg&_bright&_active {
    background-color: #fff;
  }

  &_seg:not(&_active):before {
  /* Segment number */
    background: url(tais-styles/svg/badge_seg.svg) 0.278em 0.444em no-repeat;
    background-size: 1.5em auto;
  }

  &_seg&_active:before {
    background: url(tais-styles/svg/badge_seg_active.svg) 0.278em 0.444em no-repeat;
    background-size: 1.5em auto;
    opacity: 1;
  }

  &_adt:before {
  /* Adult passenger number */
    background: url(tais-styles/svg/badge_adt.svg) 0.556em 0.167em no-repeat;
    background-size: 1.7em auto;
  }

  &_chd:before {
  /* Child passenger number */
    background: url(tais-styles/svg/badge_chd.svg) 0.75em 0.222em no-repeat;
    background-size: 1.2em auto;
  }

  &_inf:before {
  /* Infant passenger number */
    background: url(tais-styles/svg/badge_inf.svg) 0.68em 0.222em no-repeat;
    background-size: 1.25em auto;
  }

  &_psgr:before {
  /* Passenger (choose seat) badge */
    background: url(tais-styles/svg/badge_psgr.svg) 0.556em 50% no-repeat;
    background-size: 1.11em auto;
  }

  &_minus,
  &_plus {
    margin: 0;
    top: auto;
  }

  &_plus_svg {
    background: transparent;
    top: -2px;
    opacity: 0.7;

    &::before {
      background: url(tais-styles/svg/command__profiles__add-profile__add-class.svg) center center no-repeat;
    }
  }

  &_minus_svg {
    background: transparent;
    top: -2px;
    opacity: 0.7;

    &::before {
      background: url(tais-styles/svg/command__profiles__add-profile__remove-class.svg) center center no-repeat;
    }
  }

  &_minus {
    text-indent: -9999px;

    background: transparent;
    border: 0.125rem solid;
    border-color: currentColor;

    &::before {
      position: absolute;
      top: calc( 50% - 1px );
      right: 0.5rem;
      bottom: auto;
      left: 0.5rem;

      display: block;
      height: 2px;
      content: '';
      color: inherit;
      background: currentColor;
    }

    .btn & {
      color: inherit;
    }
  }

  &_plus {
    text-indent: -9999px;

    background: transparent;
    border: 0.125rem solid;
    border-color: currentColor;

    &::before,
    &::after {
      position: absolute;
      display: block;
      content: '';
      color: inherit;
      background: currentColor;
    }

    &::before {
      top: calc( 50% - 1px );
      right: 0.5rem;
      bottom: auto;
      left: 0.5rem;

      height: 2px;
    }

    &::after {
      top: 0.5rem;
      right: auto;
      bottom: 0.5rem;
      left: calc( 50% - 1px );

      width: 2px;
    }

    .btn & {
      color: inherit;
    }
  }

  &_xs {
    width: 1.5rem;
    height: 1.5rem;

    font-size: var(--font-size-m);
    line-height: 1.5rem;
  }

  &_s {
    width: 1.875rem;
    height: 1.875rem;

    font-size: var(--font-size-m);
    line-height: 1.875rem;
  }

  &_m {
    width: 3rem;
    height: 3rem;

    font-size: var(--font-size-m);
    line-height: 1.875rem;

    &.badge_plus_svg, &.badge_minus_svg {
      width: 3.3rem;
      height: 3.3rem;
      top: -6px;

      &::before {
        background-size: 4em;
      }
    }
  }
}
