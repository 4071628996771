html:not(.loading) .loading-spinner {
  font-size: var(--font-size-xxs);
  margin: 3.125rem auto;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: #ffffff;
  background: linear-gradient(to right, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  position: relative;
  animation: loadingspinner 1s infinite linear;
  transform: translateZ(0);

  &.loading-spinner_brand,
  &.loading-spinner_brand-white {
    background: linear-gradient(to right, var(--brand-highlight) 10%, transparent 40%);
  }

  &.loading-spinner_brand:after {
    background-color: #dcdcdc;
  }

  &.loading-spinner_brand-white:after {
    background-color: #ffffff;
  }

  &.loading-spinner_brand:before {
    background-color: var(--brand-highlight);
  }

  &.loading-spinner_white-grey-bg:after {
    background-color: #ddd;
  }

  &__wrapper {
	position:relative;
  }

  &__percent{
    display: block;
    color: blue;
    border-radius:100% 0 0 0;
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    color: var(--brand-highlight);
    font-size: 1.25rem;
    font-weight: bold;
  }

  &_size_xs {
    width: 1.25rem;
    height: 1.25rem;
  }

  &:before {
    width: 50%;
    height: 50%;
    background: #ffffff;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
  }

  &:after {
    background: #eeeeee;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  &_white-on-blue {
    &:after {
      background-color: var(--brand-highlight);
    }
  }

  &__header {
    text-transform: uppercase;
    margin: 0.625rem 0 0;
    color: #666666;
    font-size: 0.875rem;
    font-weight: bold;
  }
}

@-webkit-keyframes loadingspinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes loadingspinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
