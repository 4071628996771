/**/
/* TODO: FIXME: */
#ui-datepicker-div {
  z-index: calc(var(--z-tooltip) + 10) !important;
}

.terminal-menu__tabs__content__item__main {
  padding-right: 1rem;
}

.terminal-menu__tabs__content__item__block {
  .terminal-action {
    position: relative;
    min-height: 7rem;
    margin: 0 0 1rem 0;
    padding-top: 1rem;
    padding-bottom: 1rem;

    &:not(.terminal-action_selected) {
      height: 1px;
      cursor: pointer;
    }

    &_selected:hover,
    &_selected:active,
    &_selected {
      z-index: 1;
      border-color: transparent;
      padding-left: 8px;
    }

    &__icon {
      color: inherit;
    }

    &_selected .terminal-action__icon {
      z-index: 2;
      width: 3.125rem;
      height: 3.125rem;
      margin-top: 0;
      top: 1.875rem;

      @media (max-width: 1279px) {
        top: 30px;

        width: 64px;
        height: 64px;
      }
    }

    .terminal-action__main {
      position: relative;
      left: -3px;
      top: -3px;
      right: -3px;
      min-height: 100%;
      background-color: #fff;
      border: 3px solid var(--brand-highlight);
      border-radius: 9px;
    }

    .terminal-action__main__close {
      position: absolute;
      top: 0.25rem;
      right: 1rem;

      font-size: 3rem;
      line-height: 1;

      cursor: pointer;

      &:not(:hover) {
        color: #999;
      }

      &:after {
        content: '\00d7';
      }
    }

    .terminal-action__main__header {
      margin: 0 5rem;
      padding-top: 1rem;
      padding-bottom: 1.25rem;
      min-height: 4rem;
      font-size: var(--terminal-menu-main-header-font-size);
      font-weight: bold;
      color: var(--brand-highlight);
      text-align: center;

      @media (max-width: 1279px) {
        min-height: 78px;
      }
    }

    .terminal-action__main__subheader {
      margin-top: -1rem;
      font-size: var(--font-size-xs);
      color: var(--brand-highlight);
      text-align: center;
    }

    .terminal-action__main {
      .radio-group {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;
        align-content: flex-start;
      }

      .radio-group > * {
        display: inline-block;
        flex: 1 1 auto;
        min-width: min-content;
        max-width: max-content;

        margin-bottom: 0.25rem;
      }

      .radio-group_2els > * {
        width: 50%;
      }

      .radio-group_3els > * {
        width: 33.33%;
      }

      .radio-group .radio_type_button .radio__text {
        border: 1px solid var(--brand-highlight);
      }

      .radio-group .radio_type_button:first-child .radio__text {
        border-top-left-radius: var(--border-radius-tag);
        border-bottom-left-radius: var(--border-radius-tag);
      }

      .radio-group .radio_type_button:last-child .radio__text {
        border-top-right-radius: var(--border-radius-tag);
        border-bottom-right-radius: var(--border-radius-tag);
      }

      @media (min-width: 1280px) and (max-width: 1439px) {
        .radio-group .radio_type_button .radio__text {
          border: 1px solid var(--brand-highlight);
          border-radius: var(--border-radius-tag);
          margin-left: 0.25rem;
          margin-right: 0.25rem;
        }
      }

      @media (min-width: 1440px) {
        .radio-group::before,
        .radio-group::after {
          content: '';
          width: 100%;
          order: 1;
        }
      }

      @media (min-width: 1440px) and (max-width: 1599px) {
        .radio-group > *:nth-child(n+3) {
          order: 1;
        }

        .radio-group > *:nth-child(n+5) {
          order: 2;
        }

        .radio-group > *:nth-child(2n+1) .radio__text {
          border-top-left-radius: var(--border-radius-tag);
          border-bottom-left-radius: var(--border-radius-tag);
        }

        .radio-group > *:nth-child(2n) .radio__text {
          border-top-right-radius: var(--border-radius-tag);
          border-bottom-right-radius: var(--border-radius-tag);
        }
      }
    }

    .terminal-action__main:not(.terminal-action__main-fin-report) {
      @media (min-width: 1600px) {
        .radio-group_2els,
        .radio-group_3els {
          display: inline-table;
        }

        .radio-group_2els > *,
        .radio-group_3els > * {
          display: table-cell;
        }

        .radio-group > *:nth-child(n+4) {
          order: 1;
        }

        .radio-group > *:nth-child(n+7) {
          order: 2;
        }

        .radio-group > *:nth-child(3n+1) .radio__text {
          border-top-left-radius: var(--border-radius-tag);
          border-bottom-left-radius: var(--border-radius-tag);
        }

        .radio-group > *:nth-child(3n) .radio__text {
          border-top-right-radius: var(--border-radius-tag);
          border-bottom-right-radius: var(--border-radius-tag);
        }
      }
    }

    .terminal-action__main {
      .tabs-block {
        margin: 0 1rem 1rem 1rem;
      }

      .tabs-block__controls {
        padding-top: 0;
        padding-bottom: 0;
      }

      .tabs-block__tabs {
        margin-top: 1.25rem;
      }

      .input_main-action {
        text-transform: capitalize;
        padding: 6px;
        height: auto;
        margin-bottom: 0;

        &.input_service-type {
          padding-bottom: 0;
        }
      }
    }

    .tabs__tab {
      position: relative;
      margin-left: 6rem;
    }

    .tabs__tab_twa {
      display: flex;
      justify-content: center;
      gap: 20px;
    }

    .terminal-action__dropdown {
      &__select {
        z-index: 10;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;

        border-radius: 0.375rem;
        border: 0.125rem solid transparent;

        user-select: none;

        .terminal-action__dropdown__select__toggle {
          position: absolute;

          top: 0.125rem;
          right: 0.625rem;
          font-size: 1.375rem;

          cursor: pointer;

          &:not(:hover) {
            color: var(--color-light);
          }

          & i:before {
            content: "\f078"; /* fa-chevron-down */
          }

          &.terminal-action__dropdown__select__toggle_field {
            width: 100%;
            height: 1.5rem;
            right: 0;

            & i {
              display: none;
            }
          }
        }

        .terminal-action__dropdown__select__options {
          display: none;
        }

        &__option {
          position: relative;
          margin-bottom: 1rem;
          padding-left: 1rem;
          border-left: 0.1875rem solid transparent;
          text-align: left;
          transition: all 0.2s ease-out;

          &:hover {
            cursor: pointer;
          }

          &:hover,
          &[checked] {
            border-left-color: var(--brand-highlight);
            color: var(--brand-highlight);
          }

          .app-mode-twt & {
            margin-bottom: 10px;
          }
        }
      }

      &[opened] {
        .terminal-action__dropdown__select {
          padding: 1rem 3rem 0 0;
          border-color: var(--brand-highlight);
          background-color: #fff;

          .app-mode-twt & {
            top: 0;
            right: 0;
            left: auto;
          }
        }

        .terminal-action__dropdown__select__toggle {
          i:before {
            content: "\f077"; /* fa-chevron-up */
          }

          &.terminal-action__dropdown__select__toggle_field {
            width: auto;
            height: auto;
            right: 0.625rem;

            & i {
              display: block;
            }
          }
        }

        .terminal-action__dropdown__select__options {
          display: block;
        }

        .terminal-action__dropdown__targets {
          visibility: hidden;
        }
      }

      &__target__text {
        display: inline-block;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: bold;
        line-height: 1.2;
        font-size: var(--font-size-md);
      }
    }

    .radio-group .radio_type_button.radio_type_button_single .radio__text {
      border-radius: 0.375rem;
    }

    :not(.search-form_twp-services).search-form {
      max-width: 22rem;
      min-height: 6.25rem;
      padding-top: 0;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 1rem;
      text-align: left;
    }

    .search-form__carrier {
      .page_with-sidebar & {

        @media (max-width: 1392px) {
          width: 30%;
        }
      }
    }

    &__clear {
      margin-top: 5px;
      text-align: center;
      font-size: var(--font-size-m-extra);
      color: var(--color-lblue);
      text-transform: initial;
      cursor: pointer;
    }

    &__command {
      @media (min-width: 1280px) and (max-width: 1365px) {
        padding: 0 40px;
      }
      @media (min-width: 1280px) and (max-width: 1540px) {
        &_create-pnr {
          padding: 0 10px;
        }
      }
    }

    @media (min-width: 1280px) and (max-width: 1540px) {
      &__grid__create-pnr {
        &__clear-button {
          text-align: left;
        }

        &__create-button {
          text-align: right;
        }
      }
    }

    .terminal-menu__main-menu__menu-block__col__content {
      padding-top: 1rem;
      padding-left: 6rem;
    }

    @media (min-width: 1280px) and (max-width: 1439px) {
      .terminal-menu__main-menu__menu-block__col__content {
        padding-left: 2.5rem;
      }
    }

    .input {
      width: 100%;

      &_optional {
        width: calc(100% - 14px);
      }

      &_dropdown {
        vertical-align: top;
        height: 1.5rem;
        border-bottom: 1px solid #ccc;

        .terminal-action__dropdown__target {
          height: 1.5rem;
        }
      }
    }

    .input__control.hasDatepicker {
      text-transform: uppercase;
    }

    .input_flight {
      padding-right: 22px;
    }

    .input__value-cleaner {
      position: absolute;
      top: 0;
      right: 2px;
      cursor: pointer;
      font-size: 20px;
      line-height: 24px;
      opacity: 1;
    }

    .input__value-cleaner[disabled] {
      opacity: 0;
      cursor: default;
    }

    .input__value-cleaner:not[disabled]:hover {
      color: var(--brand-highlight);
    }
  }
}

.terminal-action__empty_action {
  border: 0px !important;
  cursor: auto !important;
  @media (max-width: 79.9rem) {
    display: none !important;
  }
}

.terminal-action_helps {
  .terminal-menu__tabs__content__item__block & .terminal-action__dropdown__select {
    width: auto;
    min-width: 178px;
  }

  .terminal-action__dropdown__select__option {
    white-space: nowrap;
  }
}

.terminal-action_schedule-help {
  @media (min-width: 1280px) {
    .grid_extra {
      .grid__col {
        width: 90%;
      }
    }
  }

  @media (min-width: 1520px) {
    .grid_extra {
      .grid__col-6 {
        width: calc(50% - 0.75rem);
      }

      .grid__col-3 {
        width: calc(25% - 0.75rem);
      }
    }
  }
}

.terminal-action_tariff-help {
  .tabs-block .search-form {
    max-width: 480px;
  }

  .grid__col {
    padding: 0 2px;
  }
}

.terminal-action_lists {
  .tabs-block .input {
    width: 30%;

    @media (min-width: 1280px) {
      width: 45%;
    }

    @media (min-width: 1386px) {
      width: 30%;
    }
  }
}

.terminal-action_create-pnr {
  @media (min-width: 1280px) {
    .terminal-action__content .terminal-action__main .tabs-block {
      .grid__col-3 {
        width: 44%;
      }
    }
  }

  @media (min-width: 1500px) {
    .terminal-action__main .tabs-block {
      .grid__col-3 {
        width: calc(25% - 0.75rem);
      }
    }
  }
}

@media (min-width: 1280px) and (max-width: 1799px) {
  .terminal-action_create-pnr .terminal-action__content .terminal-action__main .tabs__tab {
    margin-left: 4.625rem;
  }
}

.terminal-action__deactivate-optional {
  display: inline-block;
  vertical-align: top;
  margin-top: 0.6rem;
  cursor: pointer;
  font-size: 1em;
  font-weight: 700;
  line-height: 1;
  color: var(--color-light-extra);
  position: relative;
}

.terminal-action__activate_optional {
  cursor: pointer;
  position: relative;
  margin: 0.4rem 0 1rem;
  padding-bottom: 0.05rem;
  border-bottom: 1px dashed;

  &.input__text {
    display: inline-block;
  }

  &:before {
    display: inline-block;
    content: "+";
    font-size: medium;
    margin-right: 0.2rem;
  }
}

.terminal-action__dropdown-option_hidden {
  display: none;
}

.terminal-action__title {
  display: inline-block;
  min-width: 4.5rem;
  margin-left: -4.5rem;
  line-height: 1;
  margin-top: calc(1.5rem - 15px);

  & .terminal-action__deactivate-optional {
    margin-top: 0;
  }
}

.terminal-action .tabs__tab.reports-tab:not(.tabs__tab_hidden) {
  display: grid;
  grid-auto-flow: column;
  margin-left: 0;
  grid-column-gap: 0.3125rem;
  margin-bottom: 1.25rem;
  max-width: none;
  min-height: auto;
}

.avl-field-wrap {
  text-align: left;

  .input_optional.input_dropdown {
    width: 100%;
  }

  .terminal-action__dropdown__select__option {
    font-weight: bold;
    font-size: 1rem;
    color: #333;
    white-space: nowrap;
  }

  .terminal-action__dropdown[opened] {
    .terminal-action__dropdown__select__options.avl-field-wrap__rbd-codes {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }

    .terminal-action__dropdown__select {
      padding-right: 2rem;
      min-width: 12.5rem;
      left: auto;
      right: 0;
    }

    .terminal-action__dropdown__select.avl-field-wrap__rbb-codes-select {
      padding-right: 2rem;
    }
  }

  .avl-field-wrap__rbd-codes .terminal-action__dropdown__select__option {
    margin: 0 0.0625rem;
    padding: 0.125rem 0.375rem;
    border: none;
    min-width: 1.75rem;
    text-align: center;

    &:empty {
      min-width: 0;
      max-width: 0;
      padding: 0;
      margin: 0;
    }
  }

  .avl-field-wrap__rbd-codes .avl-field-wrap__cancel-btn {
    flex-basis: 100%;
    text-align: left;
  }

  .input__avl-cleaner {
    position: absolute;
    top: 0;
    right: 2px;
    cursor: pointer;
    font-size: 1.25rem;
    line-height: 1.5rem;
    opacity: 1;
    z-index: 11;
  }
}

.page_with-sidebar {
  .terminal-action .tabs__tab.reports-tab:not(.tabs__tab_hidden) {
    @media (min-width: 1280px) and (max-width: 1526px) {
      display: block;
    }
  }
}

.search-form_avl {
  display: grid;
}

.available-seats-field {
  max-width: 5.875rem;
}

.available-seats-field_value {
  padding-right: 0.875rem;
}

.input__avl-seats-cleaner[data-tooltip] {
  position: absolute;
  top: 0;
  right: 2px;
  cursor: pointer;
  font-size: 1.25rem;
  line-height: 1.5rem;
  opacity: 1;
  z-index: 11;
}

.input__avl-seats-cleaner_hidden {
  display: none;
}


.bookingholder-suggestions {
  span.ss__suggestions__list__item__code {
    white-space: nowrap;
    font-size: 1rem;
  }
}

/* Hide "Обработать PNR" TWP-5486 */
.skd-interface-mode .terminal-menu__tabs__content .terminal-action.terminal-action_skd-rea {
  display: none;
}
