pre {
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
  border-radius: 0;
}

.header-list__text {
  background-color: #E5E5E5;
  font-weight: bold;
  padding: 0.5em 1em 0.25em 1em;
  margin-bottom: 1em;

&__inner {
   margin-left: 0;
 }
}
