/*
* --------------------------------------------------
* This file contains all styles for grid system.
* --------------------------------------------------
*/

.grid {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  margin-right: calc(-1 * var(--grid-gutter) / 2);
  margin-left: calc(-1 * var(--grid-gutter) / 2);

  &_no-side-margins {
    margin-right: 0;
    margin-left: 0;
  }

  &_nowrap {
    flex-wrap: nowrap;
  }

  &_align-center {
    align-items: center;
  }

  &_justify-center {
    justify-content: center;
  }

  &_align-end {
    align-items: flex-end;
  }

  &__col {
    flex: 0 1 auto;

    margin-right: calc(var(--grid-gutter) / 2);
    margin-left: calc(var(--grid-gutter) / 2);

    & + & {
      margin-top: 0.625rem;
    }
  }

  &__col_reverse {
    flex: 0 1 auto;

    margin-right: calc(var(--grid-gutter) / 2);
    margin-left: calc(var(--grid-gutter) / 2);
    margin-bottom: 0.625rem;
  }

  &__clearence {
    margin-top: 0.625rem;
  }

  &__col-hidden {
    display: none;
  }

  &__col-1 {
    width: calc(8.33% - var(--grid-gutter));
  }

  &__col-2 {
    width: calc(16.66% - var(--grid-gutter));

    &:nth-child(2),
    &:nth-child(3) {
      margin-top: 0;
    }
  }

  &__col-5ths {
    width: calc(20% - var(--grid-gutter));

    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
      margin-top: 0;
    }
  }

  &__col-3 {
    width: calc(25% - var(--grid-gutter));

    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      margin-top: 0;
    }
  }

  &__col-4 {
    width: calc(33.33% - var(--grid-gutter));

    &:nth-child(2),
    &:nth-child(3) {
      margin-top: 0;
    }
  }

  &__col-5 {
    width: calc(41.66% - var(--grid-gutter));

    &:nth-child(2),
    &:nth-child(3) {
      margin-top: 0;
    }
  }

  &__col-6_narrow {
    width: calc(45% - var(--grid-gutter));

    &:nth-child(2),
    &:nth-child(3) {
      margin-top: 0;
    }
  }

  &__col-6 {
    width: calc(50% - var(--grid-gutter));

    &:nth-child(2) { margin-top: 0; }
  }

  &__col-6_wide {
    width: calc(50% + 0.2rem);
  }

  &__col-6.grid__col_row {
    width: 51%;
    &:nth-child(2) { margin-top: 0.625rem; }
  }

  &__col-7 {
    width: calc(58.33% - var(--grid-gutter));

    &:nth-child(2) { margin-top: 0; }
  }

  &__col-8 {
    width: calc(66.66% - var(--grid-gutter));

    /*&:nth-child(2) { margin-top: 0; }*/
  }

  &__col-9 {
    width: calc(75% - var(--grid-gutter));
  }

  &__col-10 {
    width: calc(84% - var(--grid-gutter));
  }

  &__col-11 {
    width: calc(91.66% - var(--grid-gutter));
  }

  &__col-12 {
    width: calc(100% - var(--grid-gutter));
  }


  &__col_nomt,
  &__col + &__col_nomt {
    margin-top: 0;
  }

  /* Two cols view for bigger resolution will work only with
  parent class .grid_lg to avoid problems when there are
  more than one terminal screen displayed at the same time */
  &_xs {

    @media (min-width: 960px) {
      &-nowrap {
        flex-wrap: nowrap;
      }

      .grid__col-xs-2 {
        width: calc(16.66% - var(--grid-gutter));

        &:nth-child(2),
        &:nth-child(3) {
          margin-top: 0;
        }
      }
      .grid__col-xs-4 {
        width: calc(33.33% - var(--grid-gutter));

        &:nth-child(2),
        &:nth-child(3) {
          margin-top: 0;
        }
      }
      .grid__col-xs-6 {
        width: calc(50% - var(--grid-gutter));

        &:nth-child(2) { margin-top: 0; }
      }
      .grid__col-xs-8 {
        width: calc(66.66% - var(--grid-gutter));
      }
      .grid__col-xs-12 {
        width: calc(100% - var(--grid-gutter));
      }

      .grid__col-xs-hide {
        display: none !important;
      }
      .grid__col-xs-show {
        display: block !important;
      }
    }
  }
  &_sm {
    @media (min-width: 980px) {
      .grid__col-sm-6 {
        width: calc(50% - var(--grid-gutter));

        &:nth-child(2) { margin-top: 0; }
      }
      .grid__col-sm-4 {
        width: calc(33.33% - var(--grid-gutter));

        &:nth-child(2),
        &:nth-child(3) {
          margin-top: 0;
        }
      }
    }
  }
  &_m {
    @media (min-width: 1024px) {
      .grid__col-m-6 {
        width: calc(50% - var(--grid-gutter));

        &:nth-child(2) { margin-top: 0; }
      }
      .grid__col-m-4 {
        width: calc(33.33% - var(--grid-gutter));

        &:nth-child(2),
        &:nth-child(3) {
          margin-top: 0;
        }
      }
    }
  }
  &_md {
    @media (min-width: 1280px) {
      .grid__col-md-6 {
        width: calc(50% - var(--grid-gutter));

        &:nth-child(2) { margin-top: 0; }
      }
      .grid__col-md-4 {
        width: calc(33.33% - var(--grid-gutter));

        &:nth-child(2),
        &:nth-child(3) {
          margin-top: 0;
        }
      }
    }
  }
  &_lg {
    @media (min-width: 1440px) {
      .grid__col-lg-2 {
        width: calc(16.66% - var(--grid-gutter));
      }

      .grid__col-lg-3 {
        width: calc(25% - var(--grid-gutter));

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
          margin-top: 0;
        }
      }

      .grid__col-lg-4 {
        width: calc(33.33% - var(--grid-gutter));

        &:nth-child(2) { margin-top: 0; }
      }

      .grid__col-lg-5 {
        width: calc(41.66% - var(--grid-gutter));

        &:nth-child(2) { margin-top: 0; }
      }

      .grid__col-lg-6 {
        width: calc(50% - var(--grid-gutter));

        &:nth-child(2) { margin-top: 0; }
      }

      .grid__col-lg-7 {
        width: calc(58.33% - var(--grid-gutter));
      }

      .grid__col-lg-8 {
        width: calc(66.66% - var(--grid-gutter));
      }

      .grid__col-lg_nomt {
        margin-top: 0;
      }
    }
  }
  &_xl {
    @media (min-width: 1600px) {
      .grid__col-lg-6 {
        width: calc(50% - var(--grid-gutter));

        &:nth-child(2) { margin-top: 0; }
      }
    }
  }
}

.grid_items_center {
  align-items: center;
}
