/*
* --------------------------------------------------
* This file contains all styles for tabs.
* --------------------------------------------------
*/
.tabs-block {
  &__controls {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }

  &__tabs {
    padding-bottom: 0.625rem;
  }
}

.tabs {
  &__control {
    cursor: pointer;
  }

  &__tab {
    &&_hidden {
      display: none !important;
    }
  }

  &__button {
    &_hidden {
      display: none !important;
    }
  }
}
