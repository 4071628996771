/**
* SCREENS STACK
*/

/* Side panel with the screens stack */
.stack-panel {
  /* Side panel header theme variables */
  --panel-header-bg: #eeeeee;
  --panel-header-color: #999999;
  --panel-header-border: #cccccc;
}

.server-color-scheme_test .stack-panel {
  --panel-header-bg: #4ece47;
  --panel-header-color: #ffffff;
  --panel-header-border: #4ece47;
}

.server-color-scheme_test.branding-host-s7u .stack-panel {
  --panel-header-bg: #7a85d3;
  --panel-header-color: #ffffff;
  --panel-header-border: #7a85d3;
}

.server-color-scheme_prod .stack-panel {
  --panel-header-bg: var(--brand-highlight);
  --panel-header-color: #ffffff;
  --panel-header-border: var(--brand-highlight);
}

.server-color-scheme_archive .stack-panel {
  --panel-header-bg: #dddddd;
  --panel-header-color: #666666;
  --panel-header-border: #cccccc;
}

.stack-panel {
  /* By default - opens over a content */
  position: absolute;
  top: 0;
  right: calc(-1 * var(--stack-panel-width));
  z-index: 6999;

  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  align-content: stretch;
  width: var(--stack-panel-width);
  height: 100%;
  padding-top: 0.5rem;

  color: #cccccc;

  background-color: #eeeeee;
  border-left: 2px solid #cccccc;

  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-duration: 0.2s;
  transition-property: box-shadow, right, margin-right, width;
}

.stack-panel_active {
  /* Opened side panel (over a content) */
  right: 0;

  box-shadow: -5px 0 10px rgba(102, 102, 102, 0.65);
}

.stack-panel_static {
  /* Side panel which moves content */
  position: relative;
  right: 0;

  margin-right: calc(-1 * var(--stack-panel-width));

  box-shadow: none;
}

.stack-panel_active {
  /* Opened side panel (move a content) */
  margin-right: 0;
}

.stack-panel_is_responsive {
  position: fixed;
}

/* Side panel toggle button */
.stack-panel__toggle {
  position: absolute;
  top: 0.5rem;
  right: 100%;

  flex: 0 0 auto;
  width: var(--stack-panel-toggle-width);
  height: 3.25rem;

  color: var(--panel-header-color);

  background-color: var(--panel-header-bg);
  border: solid var(--panel-header-border);
  border-width: 2px 0 2px 2px;
  border-top-left-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
  cursor: pointer;

  transition: none;
}

.stack-panel__toggle-indicator {
  position: absolute;
  top: calc( 50% - 0.75rem );
  left: calc( 50% - 0.3125rem );

  display: block;
  width: 0.625rem;
  height: 1.5rem;
  overflow: hidden;

  color: inherit;
}

.stack-panel__toggle-indicator::before,
.stack-panel__toggle-indicator::after {
  content: '';
  position: absolute;
  left: calc(50% - 1px);

  display: block;
  width: 2px;
  height: 0.875rem;

  background-color: currentColor;
  transform-origin: 50% 50%;

  transition: transform 0.1s ease-in-out, transform-origin 0.1s ease-in-out, width 0.1s ease-in-out;
}

.stack-panel__toggle-indicator::before {
  bottom: calc( 50% - 2px );

  transform: rotate(-37deg);
}

.stack-panel__toggle-indicator::after {
  top: calc( 50% - 2px );

  transform: rotate(37deg);
}

@media (min-width: 640px) and (min-height: 360px) {
  .stack-panel__toggle-indicator::before,
  .stack-panel__toggle-indicator::after {
    left: calc(50% - 0.0625rem);

    width: 0.125rem;
  }

  .stack-panel__toggle-indicator::before {
    bottom: calc( 50% - 0.125rem );
  }

  .stack-panel__toggle-indicator::after {
    top: calc( 50% - 0.125rem );
  }
}

@media (prefers-reduced-motion: reduce) {
  .stack-panel__toggle-indicator::before,
  .stack-panel__toggle-indicator::after {
    transition: none;
  }
}
/* Side panel header */
.stack-panel__title {
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  min-height: 3.25rem;
  padding: 0.25rem 0.625rem 0.25rem 0;

  background-color: var(--panel-header-bg);
  border: solid var(--panel-header-border);
  border-width: 2px 0;
}

.stack-panel__title-wrap {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  min-height: 2.5rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.stack-panel__title-name {
  display: inline-flex;
  flex-grow: 1;
  align-items: center;
  max-width: 8.875rem;
  margin: 0;
  padding: 0 0.25rem 0 0;

  font-weight: bold;
  color: var(--panel-header-color);

  cursor: default;
}

.stack-panel__title-name-txt {
  font-style: normal;
  font-size: 1.5rem;
  white-space: nowrap;
}

.stack-panel__title-type {
  flex-grow: 1;
  margin-left: 0.25rem;
  overflow: hidden;

  font-size: 0.625rem;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.stack-panel__operator-wrap {
  width: 100%;
  padding-left: 0.625rem;
}

.stack-panel__title-operator {
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  color: var(--panel-header-color);
}

@media (min-width: 640px) and (min-height: 360px) {
  .stack-panel__title-operator {
    font-size: 0.6875rem;
  }
}

/* Stack screens filter (all/favorites) */
.st-filter {
  display: inline-flex;
}

.st-filter__item {
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
  width: 2.25rem;
  height: 1.875rem;

  text-align: center;
  color: #ffffff;

  background: var(--panel-header-bg);
  border: 1px solid var(--panel-header-border);
  cursor: pointer;

  transition: background-color 0.2s;
}

.st-filter__item:first-child {
  border-right-width: 1px;
  border-top-left-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
}

.st-filter__item:last-child {
  border-left-width: 1px;
  border-top-right-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}

.st-filter__item.st-filter__item_active {
  color: var(--panel-header-border);

  background-color: #ffffff;
  border-color: var(--panel-header-border);
  cursor: default;
}

@media (min-width: 640px) and (min-height: 360px) {
  .st-filter__item {
    border-width: 2px;
  }
}

.server-color-scheme_test .st-filter__item,
.server-color-scheme_prod .st-filter__item {
  color: var(--panel-header-color);

  background: var(--panel-header-bg);
  border-color: var(--panel-header-color);
}

.server-color-scheme_test .st-filter__item.st-filter__item_active,
.server-color-scheme_prod .st-filter__item.st-filter__item_active {
  color: var(--panel-header-bg);

  background-color: var(--panel-header-color);
  border-color: var(--panel-header-color);
  cursor: default;
}

.st-filter__item-icon {
  width: 1.25rem;
  height: 1.25rem;

  color: inherit;

  pointer-events: none;
}

.stack-panel__header {
  flex: 0 0 auto;
  padding: 0.5rem 0.5rem 1rem 0.5rem;
}

.stack-panel__nav {
  flex: 0 0 auto;
}

.stack-panel__content {
  flex: 1 1 100%;
  padding-top: 0.625rem;
  overflow-x: hidden;
  overflow-y: auto;

  scrollbar-width: thin;
}

.stack-panel__bottom-controls-wrap {
  position: absolute;
  bottom: 1rem;

  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.5rem;

  background-color: #eeeeee;
}

.stack-panel__commands-wrap,
.stack-panel__help-wrap {
  display: inline-flex;
  align-items: center;
}

.st-button {
  display: inline-vlock;
  width: 2rem;
  height: 2rem;
  padding: 0.125rem;

  color: #999999;
  text-decoration: none;

  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 1.625rem;
  border: none;
  box-shadow: none;
  cursor: pointer;

  caret-color: transparent;
}

.stack-panel .btn--clear_stack {
  background-image: url('tais-styles/svg/icon-trash-bin-gray.svg');
}

.stack-panel .btn--save_cmd {
  background-image: url('tais-styles/svg/icon-floppy-gray.svg');
}

.stack-panel__help-toggle {
  margin-right: 0.25rem;

  background-image: url('tais-styles/svg/icon-help-gray.svg');
}

.stack-panel__docs-link {
  background-image: url('tais-styles/svg/icon-book-gray.svg');
}

/* Screens stack navigation */
.st-nav__cnt {
  position: relative;

  height: 3.75rem;
  padding: 1rem 0.5rem;

  transition: all 0.2s ease;
}

.st-nav__cnt::after {
  content: '';
  position: absolute;
  right: 0;
  left: 0;
  z-index: calc(var(--z-navigation) + 10);

  display: block;
  height: 1.5rem;
}

.st-nav__cnt_prev::after {
  top: 100%;

  background: linear-gradient(to bottom, #222222 0%, rgba(34, 34, 34, 0) 100%);
}

.st-nav__cnt_next::after {
  bottom: 100%;

  background: linear-gradient(to top, #222222 0%, rgba(34, 34, 34, 0) 100%);
}

.st-nav__arr {
  /* Screens stack navigation arrows */
  position: absolute;
  left: calc(50% - 1.125rem);
  z-index: calc(var(--z-navigation) + 20);

  width: 2.25rem;
  height: 2.25rem;

  cursor: pointer;
  /* to fix a gap between borders that appears after rotation in FF only */
}

.st-nav__arr_prev {
  top: 1.5rem;

  border-top: 0.1875rem solid #ffffff;
  border-right: 0.1875rem solid #ffffff;
  transform: rotate(-45deg);
  transform-origin: 50% 50% 0;
}

.st-nav__arr_next {
  bottom: 1.5rem;

  border-right: 0.1875rem solid #ffffff;
  border-bottom: 0.1875rem solid #ffffff;
  transform: rotate(45deg);
  transform-origin: 50% 50% 0;
}

html.firefox .st-nav__arr_prev {
  transform: rotate(-45deg) perspective(1000px);
}

html.firefox .st-nav__arr_next {
  transform: rotate(45deg) perspective(1000px);
}

.st-nav__viewport {
  transition: all 0.2s ease;
}

/**
* If there is enough space for all screens, hide navigation
* styles to animate show/hide process
*/
.st-nav_disabled .st-nav__cnt {
  height: 0;
  padding: 0;
  overflow: hidden;
}

.st-nav_disabled .st-nav__viewport {
  padding: 0 0 1rem 0;
}

/* Screens stack */
.stack {
  position: relative;

  width: 100%;
  margin: 0;
  padding: 0.875rem 0 2.5rem;

  list-style: none;
}

.stack__item {
  position: relative;

  display: flex;
  align-items: center;
  min-height: 1.75rem;
  padding: 0.125rem 0.1875rem 0.125rem 0.125rem;

  cursor: pointer;
}

.stack__item_saved {
  margin-left: 1.125rem;
}

.stack__item:hover .st-icon {
  visibility: visible;
}

.stack__item:hover .st-icon_command {
  top: 0;
  right: 0;

  min-height: 100%;

  background-size: 0.5625rem;
  transform: none;
  visibility: visible;
}

.stack__item_expanded {
  align-items: flex-start;
}

/* temporary solution for filtering screens in stack */
.stack__filter_all,
.stack__filter_favorite {
  display: none;
}

/**
* All items shown
* styles for animated filtering
*/
.stack__filter_all:checked ~ .stack .stack__item {
  opacity: 1;
}

/* Favorites filter applied: hide all items */
.stack__filter_favorite:checked ~ .stack .stack__item {
  display: none;
}

/* Favorites filter applied: show only favorite items */
.stack__filter_favorite:checked ~ .stack .stack__item_favorite {
  display: flex;
  max-height: initial;

  opacity: 1;
}

/* One screen in the screens stack */
.st-screen_selected {
  background: var(--brand-highlight);
}

.st-screen_selected .st-screen__req::after {
  background: linear-gradient(to right, transparent, var(--brand-highlight));
}

.st-screen__req {
  position: relative;

  display: inline-flex;
  flex-grow: 1;
  align-items: baseline;
  height: 1.25rem;
  margin-right: 0.125rem;
  overflow: hidden;

  font-size: 0.8125rem;
  font-family: var(--font-family-monospace);
  text-align: left;
  color: var(--brand-secondary);
  text-decoration: none;
  letter-spacing: -0.01em;

  cursor: pointer;
}

.st-screen__req:hover,
.st-screen__req:active .st-screen__req {
  color: var(--brand-highlight);
}

.st-screen__req-more {
  position: absolute;
  right: 0;
  bottom: 0.125rem;

  display: none;

  font-size: 1.1875rem;
  line-height: 1;

  background-color: #eeeeee;
  cursor: pointer;
}

.st-screen__req-more::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0.625rem;
  bottom: 0;

  width: 0.625rem;

  background: linear-gradient(to right, transparent, #eeeeee);
  cursor: default;
}

.st-screen__req_long {
  white-space: nowrap;
}

.st-screen__req_long .st-screen__req-more {
  display: block;
}

.st-screen__req_expand {
  align-items: flex-start;
  height: auto;

  white-space: normal;
}

.st-screen__req_expand::after {
  display: none;
}

.st-screen__req_expand .st-screen__req-name {
  line-height: 1rem;
}

.st-screen__req_expand + .st-icon_command {
  position: absolute;
  top: 38%;
  right: 0;

  padding: 0.75rem 0.625rem 0.1875rem;

  background-color: #eeeeee;
  background-position: 50%;
  background-size: 0.5rem;
  transform: translateY(-50%);
}

.st-screen__req_expand .st-screen__req-more {
  bottom: 0;

  background-color: transparent;
}

.st-screen__req_expand .st-screen__req-params {
  line-height: 1rem;
}

.st-screen__req_expand .st-screen__req-more {
  display: none;
}

.st-screen_selected .st-screen__req {
  color: #ffffff;
}

.st-screen_selected .st-screen__req:hover,
.st-screen_selected .st-screen__req:active .st-screen_selected .st-screen__req {
  color: #ffffff;
}

.st-screen_selected .st-screen__req-more {
  background-color: var(--brand-highlight);
}

.st-screen_selected .st-screen__req-more:hover {
  color: var(--color-disabled);
}

.st-screen_selected .st-screen__req-more::before {
  background: linear-gradient(to right, transparent, var(--brand-highlight));
}

.st-screen__req-name {
  display: inline-block;
  margin-right: 0.3125rem;

  line-height: 1.0625rem;
}

.st-screen__req-params {
  font-style: normal;
  word-break: break-all;
}

.st-icon {
  display: inline-block;
  flex-shrink: 0;
  width: 0.875rem;
  height: 0.875rem;
  padding: 0;

  border: none;
  cursor: pointer;
}

.st-icon_favorite {
  margin-right: 0.1875rem;
  margin-bottom: 0.1875rem;

  color: var(--color-dark);

  background: url('data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' xmlns:xlink=\'http://www.w3.org/1999/xlink\' viewBox=\'0 0 22 22\' xml:space=\'preserve\'%3E%3Cpath style=\'fill:%23cccccc;\' d=\'M11,0c-0.1,0-0.1,0-0.2,0c-0.3,0.1-0.5,0.3-0.6,0.5L7.3,6.7l-6.6,1C0.4,7.8,0.1,8,0,8.4 c-0.1,0.3,0,0.7,0.2,0.9L5,14.1l-1.1,6.8c-0.1,0.3,0.1,0.7,0.3,0.9c0.3,0.2,0.6,0.2,0.9,0.1l5.8-3.2l5.8,3.2 c0.3,0.2,0.7,0.1,0.9-0.1c0.3-0.2,0.4-0.6,0.3-0.9L17,14.1l4.7-4.8C22,9.1,22.1,8.7,22,8.4c-0.1-0.3-0.4-0.6-0.7-0.6l-6.6-1 l-2.9-6.2C11.7,0.2,11.4,0,11,0z M11,3.1L13.3,8c0.1,0.3,0.4,0.5,0.7,0.5l5.2,0.8l-3.8,3.8c-0.2,0.2-0.3,0.5-0.3,0.8l0.9,5.4 l-4.6-2.6c-0.3-0.1-0.6-0.1-0.8,0l-4.6,2.6l0.9-5.4c0-0.3-0.1-0.6-0.3-0.8L2.8,9.3L8,8.5c0.3,0,0.5-0.2,0.7-0.5L11,3.1z\'/%3E%3C/svg%3E') 100% 100% no-repeat;
  background-size: 0.75rem;
  visibility: hidden;
}

.stack__item_favorite .st-icon_favorite {
  visibility: visible;
}

.st-icon_favorite.st-icon_selected {
  background: url('data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' xmlns:xlink=\'http://www.w3.org/1999/xlink\' viewBox=\'0 0 20 20\' xml:space=\'preserve\'%3E%3Cpath style=\'fill:%23666666;\' d=\'M10,0C10,0,9.9,0,9.8,0C9.6,0.1,9.4,0.3,9.3,0.5L6.6,6.1L0.7,7C0.4,7.1,0.1,7.3,0,7.6 c-0.1,0.3,0,0.6,0.2,0.8l4.3,4.4l-1,6.2c-0.1,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.6,0.2,0.8,0.1L10,17l5.3,2.9c0.3,0.1,0.6,0.1,0.8-0.1 c0.2-0.2,0.4-0.5,0.3-0.8l-1-6.2l4.3-4.4C20,8.2,20.1,7.9,20,7.6c-0.1-0.3-0.3-0.5-0.6-0.6l-6-0.9l-2.6-5.6C10.6,0.2,10.3,0,10,0z\' /%3E%3Cpath style=\'fill:%23666666;\' d=\'M10,2.8l2.1,4.5c0.1,0.2,0.3,0.4,0.6,0.4l4.7,0.7L14,11.9c-0.2,0.2-0.3,0.5-0.3,0.7l0.8,4.9l-4.2-2.3 c-0.2-0.1-0.5-0.1-0.8,0l-4.2,2.3l0.8-4.9c0-0.3-0.1-0.6-0.3-0.7L2.5,8.4l4.7-0.7c0.3,0,0.5-0.2,0.6-0.4L10,2.8z\'/%3E%3C/svg%3E%0A') 100% 100% no-repeat;
  background-size: 0.75rem;
}

.st-screen_selected .st-icon_favorite {
  background: url('data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' xmlns:xlink=\'http://www.w3.org/1999/xlink\' viewBox=\'0 0 22 22\' xml:space=\'preserve\'%3E%3Cpath style=\'fill:%23ffffff;\' d=\'M11,0c-0.1,0-0.1,0-0.2,0c-0.3,0.1-0.5,0.3-0.6,0.5L7.3,6.7l-6.6,1C0.4,7.8,0.1,8,0,8.4 c-0.1,0.3,0,0.7,0.2,0.9L5,14.1l-1.1,6.8c-0.1,0.3,0.1,0.7,0.3,0.9c0.3,0.2,0.6,0.2,0.9,0.1l5.8-3.2l5.8,3.2 c0.3,0.2,0.7,0.1,0.9-0.1c0.3-0.2,0.4-0.6,0.3-0.9L17,14.1l4.7-4.8C22,9.1,22.1,8.7,22,8.4c-0.1-0.3-0.4-0.6-0.7-0.6l-6.6-1 l-2.9-6.2C11.7,0.2,11.4,0,11,0z M11,3.1L13.3,8c0.1,0.3,0.4,0.5,0.7,0.5l5.2,0.8l-3.8,3.8c-0.2,0.2-0.3,0.5-0.3,0.8l0.9,5.4 l-4.6-2.6c-0.3-0.1-0.6-0.1-0.8,0l-4.6,2.6l0.9-5.4c0-0.3-0.1-0.6-0.3-0.8L2.8,9.3L8,8.5c0.3,0,0.5-0.2,0.7-0.5L11,3.1z\'/%3E%3C/svg%3E') 100% 100% no-repeat;
  background-size: 0.75rem;
}

.st-screen_selected .st-icon_favorite.st-icon_selected {
  background: url('data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' xmlns:xlink=\'http://www.w3.org/1999/xlink\' viewBox=\'0 0 20 20\' xml:space=\'preserve\'%3E%3Cpath style=\'fill:%23ffffff;\' d=\'M10,0C10,0,9.9,0,9.8,0C9.6,0.1,9.4,0.3,9.3,0.5L6.6,6.1L0.7,7C0.4,7.1,0.1,7.3,0,7.6 c-0.1,0.3,0,0.6,0.2,0.8l4.3,4.4l-1,6.2c-0.1,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.6,0.2,0.8,0.1L10,17l5.3,2.9c0.3,0.1,0.6,0.1,0.8-0.1 c0.2-0.2,0.4-0.5,0.3-0.8l-1-6.2l4.3-4.4C20,8.2,20.1,7.9,20,7.6c-0.1-0.3-0.3-0.5-0.6-0.6l-6-0.9l-2.6-5.6C10.6,0.2,10.3,0,10,0z\' /%3E%3Cpath style=\'fill:%23ffffff;\' d=\'M10,2.8l2.1,4.5c0.1,0.2,0.3,0.4,0.6,0.4l4.7,0.7L14,11.9c-0.2,0.2-0.3,0.5-0.3,0.7l0.8,4.9l-4.2-2.3 c-0.2-0.1-0.5-0.1-0.8,0l-4.2,2.3l0.8-4.9c0-0.3-0.1-0.6-0.3-0.7L2.5,8.4l4.7-0.7c0.3,0,0.5-0.2,0.6-0.4L10,2.8z\'/%3E%3C/svg%3E%0A') 100% 100% no-repeat;
  background-size: 0.75rem;
}

.st-icon_command {
  width: 0.5625rem;
  height: 1rem;

  background: url('data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' xmlns:xlink=\'http://www.w3.org/1999/xlink\' x=\'0px\' y=\'0px\' viewBox=\'0 0 10 18\' xml:space=\'preserve\'%3E%3Cpath style=\'fill:%23999999;\' d=\'M1.5,17.5l-1-1L7.6,9L0.5,1.5l1-1L9.5,9L1.5,17.5z M0,16.5L7.1,9L0,1.5L1.5,0L10,9l-8.5,9L0,16.5z M8.1,9L1,16.5L1.5,17L9,9L1.5,1L1,1.5L8.1,9z\'/%3E%3C/svg%3E') no-repeat;
  visibility: hidden;
}

.st-screen_selected .st-icon_command {
  background: var(--brand-highlight) url('data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' xmlns:xlink=\'http://www.w3.org/1999/xlink\' x=\'0px\' y=\'0px\' viewBox=\'0 0 10 18\' xml:space=\'preserve\'%3E%3Cpath style=\'fill:%23ffffff;\' d=\'M1.5,17.5l-1-1L7.6,9L0.5,1.5l1-1L9.5,9L1.5,17.5z M0,16.5L7.1,9L0,1.5L1.5,0L10,9l-8.5,9L0,16.5z M8.1,9L1,16.5L1.5,17L9,9L1.5,1L1,1.5L8.1,9z\'/%3E%3C/svg%3E') 50% 50% no-repeat;
  background-size: 0.5rem;
}

.st-icon:focus {
  outline: none;
}

/* toggle ------------------------------------------------------------------- */
.stack-panel:not(.stack-panel_open) {
  width: 0.5rem;
}

.stack-panel:not(.stack-panel_open) .stack-panel__toggle::after {
  content: '';
  position: absolute;
  top: -0.125rem;
  bottom: -0.125rem;
  left: 100%;

  display: block;
  width: calc( 0.5rem - 0.125rem );
  /* closed stack panel width - border width */

  background-color: inherit;
}

.stack-panel:not(.stack-panel_open) .stack-panel__toggle-indicator::before {
  transform: rotate(37deg);
}

.stack-panel:not(.stack-panel_open) .stack-panel__toggle-indicator::after {
  transform: rotate(-37deg);
}

.stack-panel:not(.stack-panel_open) > *:not(.stack-panel__toggle) {
  display: none;
}

::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

::-webkit-scrollbar-track {
  background-color: #ffffff;
}

::-webkit-scrollbar-thumb {
  background-color: #cccccc;

  transition: background-color 0.2s;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #999999;
}
