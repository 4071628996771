/*
* --------------------------------------------------
* This file contains all styles for select component.
* --------------------------------------------------
*/
/*doc
  name: Select
  description: A drop-down list
  code: |
    <label class="select">
      <div class="select__box">
        <select class="select__control">
          <option>Option 1</option>
          <option>Option 2</option>
          <option>Option 3</option>
        </select>
        <span class="select__tick"></span>
      </div>
      <span class="select__text">Select label</span>
    </label>
*/

.select {
  position: relative;
  display: inline-block;
  text-align: left;

  &_width_available {
    width: 100%;
  }

  &__box {
    position: relative;
    display: block;
    cursor: pointer;
  }

  &__control {
    position: relative;
    z-index: calc( var(--z-main-content) + 1 );

    display: inline-block;
    width: 100%;
    height: 1.5rem;
    padding: 0.125rem 1.2rem 0.125rem 0;

    line-height: 1;

    appearance: none;

    font-weight: bold;
    font-size: var(--font-size-md);
    font-family: inherit;

    color: #000;

    background: transparent;

    border: solid var(--color-disabled);
    border-width: 0 0 1px;
    border-radius: 0;

    outline: none;

    &:not(select) {
      padding-left: 0.125rem;
    }

    &:not(:empty) {
      cursor: pointer;
    }

    &_disabled {
      color: var(--color-medium);
    }
  }

  &__tick {
    position: absolute;
    z-index: var(--z-main-content);
    top: 50%;
    right: 0.1875rem;

    display: block;
    width: 0.5rem;
    height: 0.5rem;
    margin-top: -0.25rem;

    border: solid var(--color-light);
    border-width: 0 1px 1px 0;

    transform-origin: 50% 50% 0;
    transform: rotate(45deg);

    transition: transform 0.1s ease-out 0s;

    @media print {
      display: none;
    }
  }

  &_focus &__control + &__tick,
  &__control:focus + &__tick,
  &.dropdown_active &__tick {
    transform: rotate(-135deg);
  }

  &__control:not(:empty) ~ &__tick {
    cursor: pointer;
  }

  &__control:empty ~ &__tick {
    display: none;
  }

  &__text {
    display: block;
    line-height: 1;
    font-size: var(--font-size-xs);

    color: var(--color-light-extra);
  }

  /* fake select (dropdown) elements */
  &__options {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__option-block {
    display: inline-block;
    vertical-align: top;
  }

  &__option-title {
    font-size: var(--font-size-lg);
    font-weight: bold;
    padding: 0;
    margin-bottom: 0.325rem;
  }

  &__option {
    display: block;
    min-height: 1.25rem;
    padding: 0.25rem 0.625rem;
    line-height: 1;
    border-radius: 10px;
    transition: background-color .1s, color .1s;

    & + & {
      margin-top: 0.125rem;
    }

    &_selected {
      cursor: default;

      color: #fff;
      background-color: var(--brand-highlight);
    }

    &_disabled {
      color: var(--color-disabled);
    }

    &:not(&_disabled) {
      cursor: pointer;
    }

    &:not(&_disabled):hover {
      color: #fff;
      background-color: var(--brand-highlight);
    }
  }

  /* size */
  &_xs {
    font-size: var(--font-size-xxs);
    line-height: 1;

    & .select__control,
    & .select__box,
    & .select__text {
      font-size: inherit;
      line-height: inherit;
    }

    & .select__control {
      height: 0.875rem;
      padding-top: 0;
      padding-right: 1rem;
      padding-bottom: 0.125rem;;
    }

    & .select__tick {
      right: 0.125rem;
      width: 0.375rem;
      height: 0.375rem;
      margin-top: -0.1875rem;
    }
  }

  &_s {
    font-size: var(--font-size-base);
    line-height: 1.5;

    & .select__control,
    & .select__box,
    & .select__text {
      font-size: inherit;
      line-height: inherit;
    }

    & .select__control {
      height: 1.3125rem;
      padding-top: 0.125rem;
      padding-right: 1rem;
      padding-bottom: 0.125rem;
    }

    & .select__tick {
      right: 0.125rem;
      width: 0.375rem;
      height: 0.375rem;
      margin-top: -0.1875rem;
    }
  }

  /* color */
  &_primary {
    & .select__control {
      color: var(--brand-highlight);
      border-bottom-color: var(--brand-highlight);
    }
  }

  &_secondary {
    & .select__control {
      color: var(--brand-highlight-sec);
    }
  }

  &_noborder {
    & .select__control {
      color: var(--brand-primary);
      border-bottom-color: rgba(0, 0, 0, 0);
    }
  }

  &_error {
    & .select__control {
      color: var(--brand-warning);
      border-bottom-color: var(--brand-warning);
    }
    & .select__text {
      color: var(--brand-warning);
    }
  }

  &_disabled {
    pointer-events: none;
  }

  .dropdown_select.dropdown_active &__tick {
    border-right-color: var(--brand-secondary);
    border-bottom-color: var(--brand-secondary);
  }
}
