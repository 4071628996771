/* smartsearch buttons */

.ss__button {
  display: inline-block;
  height: 1.75rem;
  padding: 0 0.75rem;

  cursor: pointer;

  font-size: 0.75rem;
  font-weight: bold;
  line-height: 1.5rem;
  text-align: center;
  white-space: nowrap;
  text-transform: uppercase;

  color: var(--brand-highlight);

  border: 0.125rem solid currentColor;
  border-radius: 1rem;

  &_disabled,
  &.disabled {
    cursor: default;
    opacity: 0.5;
    pointer-events: none;
  }

  &:not(.disabled):hover {
    color: #0983ff;
  }

  &_inverse {
    color: #fff;

    &:not(.disabled):hover {
      color: #e5e5e5;
    }
  }

  &_link {
    padding-left: 0;
    padding-right: 0;
    font-size: 0.9rem;
    font-weight: normal;
    text-transform: none;
    border-color: transparent;
    border-radius: 0;
  }
}

.ss__button-back {
  width: 1.625rem;
  background: url('smartsearch-ui/css/images/back-light.svg') 50% 50% no-repeat;
  background-size: contain;
}
