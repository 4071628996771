/*
* --------------------------------------------------
* This file contains all styles for radio component.
* --------------------------------------------------
*/

.radio {
  position: relative;
  display: inline-block;
  text-align: left;

  &__control {
    position: relative;

    display: inline-block;
  }

  &__text { }

  /* Radio button control presented as a button */
  &_type_button {
    & .radio__control {
      position: absolute;
      z-index: -1;
      visibility: hidden;
      opacity: 0;
    }

    & .radio__text {
      display: block;
      height: 1.125rem;
      padding: 0 0.75em;

      cursor: pointer;

      font-weight: bold;
      font-size: var(--font-size-xs);
      font-family: var(--font-family-sans-serif);
      line-height: 1rem;

      text-align: center;
      white-space: nowrap;
      text-decoration: none;
      text-transform: uppercase;

      color: var(--brand-highlight);
      background: #fff;

      border: 1px solid var(--brand-highlight);

      &:hover,
      &:active,
      &_selected,
      .radio__control:checked ~ & {
        color: #fff;
        background: var(--brand-highlight);
        border-color: var(--brand-highlight);
      }
    }

    & + & .radio__text {
      border-left: none;
    }

    & .radio__control:checked ~ .radio__text {
      color: #fff;
      background: var(--brand-highlight);
      border-color: var(--brand-highlight);
    }

  }

  &_type_button-switch {
    & .radio__text {
      height: 1.25rem;
      padding: 0.0625rem 0.125em;
    }
  }

  &_type_button-tag {
    & .radio__text {
      padding: 0 0.3em;
    }
  }

  &_type_tab-control {
    margin: 0 1.5rem 0.3125rem 0;

    & .radio__control {
      position: absolute;
      z-index: -1;
      visibility: hidden;
      opacity: 0;
    }

    & .radio__text {
      display: block;
      height: 1.25rem;
      padding: 0 0.5em;

      cursor: pointer;

      font-weight: bold;
      font-size: var(--font-size-base);
      font-family: var(--font-family-sans-serif-arial);
      line-height: 1rem;

      text-align: center;
      white-space: nowrap;
      text-decoration: none;
      text-transform: uppercase;

      color: var(--color-light);
      background: #fff;

      border: .125rem solid currentColor;
      border-radius: var(--border-radius-tag);

      &:hover,
      &:active,
      &_selected,
      .radio__control:checked ~ & {
        color: #fff;
        background: var(--brand-highlight);
        border-color: var(--brand-highlight);
      }
    }

    & .radio__control:checked ~ .radio__text {
      color: #fff;
      background: var(--brand-highlight);
      border-color: var(--brand-highlight);
    }
  }

}

.radio-group {
  display: inline-table;

  & > * {
    display: table-cell;
  }

  &_2els > * {
    width: 50%;
  }
  &_3els > * {
    width: 33.33%;
  }

  & .radio_type_button:first-child .radio__text {
    border-radius: var(--border-radius-tag) 0 0 var(--border-radius-tag);
  }
  & .radio_type_button:last-child .radio__text {
    border-radius: 0 var(--border-radius-tag) var(--border-radius-tag) 0;
  }

  & + & {
    margin-left: 0.3125rem;
  }
}
