.branding-kk {
  & .side-panel {
    background-color: #E30916;

  & img.logo {
      box-sizing: border-box;
      background: url(tais-styles/images/kk/logo.png) no-repeat;
      background-size: contain;
      width: 120px; /* Width of new image */
      height: 100px; /* Height of new image */
      padding-left: 120px; /* Equal to width of new image */
    }
  }
}
